[data-grit-accordion] {
}

[data-grit-accordion-trigger] {
    cursor:pointer;

    & > * {
        pointer-events:none;
    }
}

[data-grit-accordion-item] {
    overflow:hidden;

    &:not(.is-active) {
        display:none;
    }
}

[data-grit-accordion-toggle-status="open"] {

    & > span:first-child {
        display:none;
    }
}

[data-grit-accordion-toggle-status="close"] {

    & > span:last-child {
        display:none;
    }
}
