.two-cols-text {

    &--heading {

        &:not(:last-child) {
            padding-bottom:27px;

            @media screen and (max-width:768px) {
                padding-bottom:22px;
            }
        }
    }

    &--text {

        @media screen and (min-width:769px) {
            column-count:2;
            column-gap:48px;
        }
    }
}
