.pb-block--testimonial {

    @media screen and (max-width:768px) {
        padding-left:0 !important; padding-right:0 !important;
    }
}

.pb-block--testimonial-v2 {

    @media screen and (max-width:768px) {
        padding-left:0 !important; padding-right:0 !important;
    }

    .testimonial--text {

        @media screen and (min-width:1025px) {
            width:60%;
        }
    }
}
