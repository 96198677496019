.wrapper {
    @include flex-col;
    flex-shrink:0;
    margin-top:map-get($header-heights, 'desktop');
    min-height:calc(100vh - #{map-get($header-heights, 'desktop')});
    overflow:hidden;

    @media screen and (max-width:768px) {
        margin-top:map-get($header-heights, 'mobile');
        min-height:calc(100vh - #{map-get($header-heights, 'mobile')});
    }

    & > .main {
        flex:1 1 auto;
    }
}

/*
 * Main
 */

.main {

    &--arrows {
        @include fixed(-1);
        top:0; left:0; bottom:0;
        width:48px;
        mask-image:url('../images/main-arrows.svg');
        mask-size:48px;

        @media screen and (max-width:1220px) and (min-width:1025px) {
            width:24px;
            mask-size:24px;
        }
        @media screen and (max-width:1024px) {
            display:none;
        }
    }
}

/*
 * WP Admin Bar
 */

html[lang="en"] {

    @media screen and (max-width:1024px) {
        margin-top:0 !important;
    }
}

body.customize-support {

    @media screen and (min-width:1025px) {
        .nav-top {
            top:32px;
        }

        .header {
            top:78px;
        }

        &.sticky-down {

            .header {
                top:32px;
            }
        }
    }
}

#wpadminbar {

    @media screen and (max-width:1024px) {
        display:none !important;
    }
}