.g-google-map {

    @media screen and (min-width:769px) {
        @include aspect-ratio(2, 1);
    }
    @media screen and (max-width:768px) {
        @include aspect-ratio(3, 4);
    }

    &--marker {

        &--label {
            @include relative;
            left:0; bottom:6px;
            font-weight:700;
        }
    }
}
