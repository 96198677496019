.tpl-post {

    .page-hero--text h1,
    .page-heading--text h1 {

        @media screen and (max-width:768px) {
            font-size:24px;
            line-height:map-get($line-heights, 'small');
        }
    }

    &--footer {

        &--content {
            @include flex-row;
            justify-content:space-between;
            align-items:center;
            column-gap:24px;

            @media screen and (max-width:768px) {
                column-gap:12px;
            }
        }

        &--prev,
        &--next {

            @media screen and (max-width:768px) {
                width:calc(50% - 6px); min-width:0 !important;
            }
        }

        &--next {

            &:only-child {
                margin-left:auto;
            }
        }
    }
}