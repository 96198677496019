/*
 * Colours
 */

$colors: (
    'white': #FFFFFF, // White

    '01-01': #1C1C1C, // Black
    '01-02': #707070, // Grey
    '01-03': #EFEDED, // Light grey

    '02-01-p': #2F1432, // Purple - Primary
    '02-01-a': #46274A, // Purple - Accent
    '02-02-p': #314D41, // Green - Primary
    '02-02-a': #446055, // Green - Accent
    '02-03-p': #CE6447, // Orange - Primary
    '02-03-a': #E67E62, // Orange - Accent
    '02-04-p': #F2C359, // Yellow - Primary
    '02-04-a': #FFDC8E, // Yellow - Accent
    '02-05-p': #AFCFD0, // Sky - Primary
    '02-05-a': #D6EBEB, // Sky - Accent
    '02-06-p': #3779B9, // Blue - Primary
    '02-06-a': #4085CB, // Blue - Accent
    '02-07-p': #822F35, // Red - Primary
    '02-07-a': #964046, // Red - Accent
    '02-08-p': #F2CCC9, // Pink - Primary
    '02-08-a': #F8DFDD, // Pink - Accent

    '03-01': #19D41C, // Norwich Solar System - Green
    '03-02': #00382E, // Norwich Solar System - Dark Green
    '03-03': #F0F6E6, // Norwich Solar System - Light Green

    'error': #D91E18,
    'alert': #F89406,
    'valid': #00B16A,
    'update': #1E8BC3,
);

/*
 * Sizes
 */

// Widths
$widths: (
    'wide': 1440px,
    'default': 1220px,
    'medium': 980px,
    'small': 680px,
    'xsmall': 460px,
);

// Header heights
$header-heights: (
    'desktop': 116px,
    'mobile': 121px,
);

/*
 * Typography
 */

// Line heights
$line-heights: (
    'h1': 1,
    'h2': 1.1,
    'h3': 1.2,
    'h4': 1.3,
    'h5': 1.4,
    'default': 1.5,
    'medium': 1.4,
    'small': 1.2,
);

// Font sizes
$font-sizes: (
    'h1': 48px,
    'h2': 32px,
    'h3': 24px,
    'h4': 22px,
    'h5': 20px,
    'default': 18px,
    'medium': 16px,
    'small': 14px,
);

// Font families
$font-families: (
    '01': ('Larsseit', sans-serif),
);

/*
 * Font Face
 */

// Light
@font-face {
    font-family:'Larsseit';
    font-style:normal;
    font-weight:300;
    font-display:swap;
    src:url(../../assets/fonts/larsseit/300.woff2) format('woff2');
}

// Regular
@font-face {
    font-family:'Larsseit';
    font-style:normal;
    font-weight:400;
    font-display:swap;
    src:url(../../assets/fonts/larsseit/400.woff2) format('woff2');
}

// Medium
@font-face {
    font-family:'Larsseit';
    font-style:normal;
    font-weight:500;
    font-display:swap;
    src:url(../../assets/fonts/larsseit/500.woff2) format('woff2');
}

// Bold
@font-face {
    font-family:'Larsseit';
    font-style:normal;
    font-weight:700;
    font-display:swap;
    src:url(../../assets/fonts/larsseit/700.woff2) format('woff2');
}

/*
 * Keyframes
 */

// Spin
@keyframes spin {
    0% { transform:rotate(0deg); }
    100% { transform:rotate(360deg); }
}
