.tpl-contact {

    // Contacts
    &--contacts {
        text-decoration:underline;

        @media screen and (max-width:768px) {
            flex-direction:column !important;
        }

        .btn--icon {
            width:24px; height:24px;
        }
    }

    // Form
    &--form {
        padding-top:96px;

        @media screen and (max-width:1220px) {
            padding-top:48px;
        }
    }

    // RHC Image
    &--pic {
        @include flex-col;
        flex:1 1 auto;
    }

    &--img {
        flex:1 1 auto;
        object-fit:cover;
    }

    // Socials
    &--socials {
        @include flex-row;
        @include relative;
        align-items:center;
        column-gap:48px;
        padding:48px;
        overflow:hidden;

        @media screen and (max-width:768px) {
            flex-direction:column;
            row-gap:24px;
            padding:32px 24px;
        }

        &--lhc {

            @media screen and (min-width:769px) {
                width:calc(70% - 24px);
            }
        }
        &--rhc {

            @media screen and (min-width:769px) {
                width:calc(30% - 24px);
            }
        }

        &--text {

            @media screen and (min-width:769px) {
                padding-right:25%;
            }
        }

        &--list {
            @include flex-col;
            row-gap:24px;

            @media screen and (max-width:768px) {
                row-gap:12px;
            }
        }

        &--link {
            @include animate((color));
            @include flex-row;
            align-items:center;
            column-gap:18px;
            color:map-get($colors, '01-01');
            font-size:22px;
            font-weight:500;
            text-decoration:underline;

            @media screen and (max-width:768px) {
                column-gap:12px;
                font-size:map-get($font-sizes, 'default');
            }

            span {
                @include animate((background));
                @include flex-col;
                justify-content:center;
                align-items:center;
                width:48px; height:48px;
                background:#FFF;
                border:2px solid map-get($colors, '01-01');
                border-radius:100%;

                @media screen and (max-width:768px) {
                    width:32px; height:32px;
                }

                svg {
                    @include animate((fill));
                    width:22px; height:22px;
                    fill:map-get($colors, '01-01');

                    @media screen and (max-width:768px) {
                        width:16px; height:16px;
                    }
                }
            }

            @include hocus {

                span {
                    background:none;
                }
            }

            &.style-light {
                color:#FFF;

                span {
                    background:none;
                    border-color:#FFF;

                    svg {
                        fill:#FFF;
                    }
                }
            }
        }

        &--shape {
            @include absolute(-1);
            top:0; left:70%; right:0; bottom:0;

            @media screen and (min-width:769px) {

                transform:skewX(45deg);
                transform-origin:bottom;
            }
            @media screen and (max-width:768px) {
                left:0;
                clip-path:polygon(0% 80%, 70% 15%, 100% 50%, 100% 100%, 0% 100%);
            }
        }

        &--bg-rhc {
            @include absolute(-1);
            top:0; left:70%; right:0; bottom:0;
        }
    }
}