.g-modal {
    @include animate((opacity, visibility));
    position:relative !important;
    z-index:999 !important;

    &:not(.is-open) {
        opacity:0;
        visibility:hidden;
        pointer-events:none !important;
    }

    &--wrapper {
        @include flex-col;
        @include fixed(999);
        top:0; left:0; right:0; bottom:0;
        padding:0 96px;
        background:rgba(#000, .88);
        overflow:auto;

        @media screen and (max-width:1220px) and (min-width:769px) {
            padding:0 48px;
        }
        @media screen and (max-width:768px) {
            padding:0 24px;
        }
    }

    &--container {
        @include flex-col;
        justify-content:center;
        align-items:center;
        flex:1 1 auto;

        &:before,
        &:after {
            content:"";
            display:block;
            flex-shrink:0;
            width:100%; height:96px;

            @media screen and (max-width:1220px) {
                height:48px;
            }
        }
    }

    &--content {
        @include relative;
        padding:32px;
        background:#FFF;
        box-sizing:border-box;
    }

    &--controls {

        @media screen and (max-width:768px) {
            @include flex-row;
            @include fixed;
            column-gap:12px;
            left:50%; bottom:12px;
            transform:translateX(-50%);
        }
    }

    &--close {
        @include absolute;
        top:-24px; right:-24px;

        @media screen and (max-width:768px) {
            position:fixed;
            top:12px; right:12px;
        }
    }

    // Style gallery
    &.style-gallery {

        .g-modal--container {
            height:100%;
        }

        .g-modal--content {
            padding:0;
            max-width:none; height:calc(100% - 192px);
            background:none;
        }

        .g-modal--close {

            @media screen and (min-width:769px) {
                @include fixed;
                top:24px; right:84px;
            }
            @media screen and (max-width:768px) {
                position:static;
            }
        }
    }
}
