.event-accordion {
    padding:32px;
    border:1px solid map-get($colors, '01-01');

    @media screen and (max-width:768px) {
        padding:24px;
    }

    &--item {

        &:not(:last-child) {
            margin-bottom:24px; padding-bottom:24px;
            border-bottom:1px solid map-get($colors, '01-01');
        }
    }

    &--content,
    &--text,
    &--btns-wrapper {
        padding-top:24px;
    }

    &--content {
        @include flex-row;
        flex-wrap:wrap;
        column-gap:48px;

        @media screen and (max-width:768px) {
            flex-direction:column;
        }

        &.has-image > .event-accordion--lhc {

            @media screen and (min-width:769px) {
                width:calc(70% - 48px);
            }
        }
    }

    &--lhc,
    &--btns-wrapper {
        width:100%;
    }
    &--rhc {

        @media screen and (min-width:769px) {
            width:30%;
        }
        @media screen and (max-width:768px) {
            order:-1;
            padding-bottom:24px;
            width:100%;
        }
    }

    &--heading {
        @include flex-row;
        justify-content:space-between;
        align-items:center;
        column-gap:24px;
        width:100%;
        font-weight:700;

        svg {
            @include animate((transform));
            display:block;
            width:18px; height:18px;
            fill:currentColor;
            transform:rotate(90deg);
        }

        &.is-active {

            svg {
                transform:rotate(-90deg);
            }
        }
    }

    &--datetime,
    &--address {
        font-weight:500;
    }

    &--address {
        padding-top:9px;
    }

    &--text {

        @media screen and (max-width:768px) {
            padding-top:12px;
        }
    }

    &--pic {
        @include aspect-ratio-media(1, 1);

        @media screen and (max-width:768px) {
            @include aspect-ratio-media(16, 9);
        }
    }
}