.breadcrumbs {
    line-height:map-get($line-heights, 'small');
    font-size:map-get($font-sizes, 'medium');

    &--list,
    &--item {
        @include flex-row;
        align-items:center;
        column-gap:9px;
    }

    &--link {
        @include animate((color));
        color:map-get($colors, '01-02');

        @include hocus {
            color:map-get($colors, '01-01');
        }

        &.is-current {
            color:map-get($colors, '01-01');
        }
    }

    &--home {
        margin-top:-5px; padding-right:3px;
        width:18px; height:18px;

        svg {
            @include animate((fill));
            display:block;
            width:18px; height:18px;
            fill:map-get($colors, '01-02');
        }

        @include hocus {

            svg {
                fill:map-get($colors, '01-01');
            }
        }
    }

    &--chevron {
        width:9px; height:9px;

        svg {
            display:block;
            width:9px; height:9px;
            fill:map-get($colors, '01-02');
        }
    }
}
