.header {
    @include animate((top));
    @include fixed(99);
    top:46px; left:0; right:0;
    background:#FFF;

    @media screen and (min-width:1221px) {
        padding:0 96px;
    }
    @media screen and (max-width:1220px) and (min-width:1025px) {
        padding:0 48px;
    }
    @media screen and (max-width:1024px) {
        top:41px;
        padding:0 24px;
    }

    &:after {
        @include absolute(-1);
        content:"";
        bottom:-6px; left:0; right:0;
        height:6px;
        background:linear-gradient(to bottom, rgba(#000, .12) 0%, rgba(#000, 0) 100%);
    }

    &--content {
        @include flex-row;
        justify-content:space-between;
        align-items:flex-start;
        column-gap:48px;
        padding:24px 0;

        @media screen and (max-width:1024px) {
            align-items:center;
            padding:16px 0;
        }
    }

    &--lhc {
        @include relative;
    }

    // Logo
    .logo {

        @media screen and (min-width:1025px) {
            @include absolute;
            top:0; left:0;
        }

        svg {

            @media screen and (max-width:1024px) {
                height:48px;
            }
        }
    }
}

/*
 * Sticky Header Behaviour
 */

body.sticky-down {

    .header {
        top:0;
    }
}