/*
 * Palette
 */

@each $key, $color in $colors {

    // Colors
    .color-#{$key} {
        color:#{$color} !important;
    }

    // Backgrounds
    .bg-#{$key} {
        background:#{$color} !important;
    }

    // Borders
    .border-#{$key} {
        border-color:#{$color} !important;
    }

    // Fills
    .fill-#{$key} {
        fill:#{$color} !important;
    }
}

/*
 * Flex
 */

.flex-row { @include flex-row; }
.flex-col { @include flex-col; }

/*
 * Widths
 */

@each $key, $width in $widths {

    .w-#{$key} {
        max-width:$width;
    }
}

/*
 * Resets
 */

// Margins
.reset-margin { margin:0 !important; }
.reset-margin-top { margin-top:0 !important; }
.reset-margin-right { margin-right:0 !important; }
.reset-margin-bottom { margin-bottom:0 !important; }
.reset-margin-left { margin-left:0 !important; }

// Paddings
.reset-padding { padding:0 !important; }
.reset-padding-top { padding-top:0 !important; }
.reset-padding-right { padding-right:0 !important; }
.reset-padding-bottom { padding-bottom:0 !important; }
.reset-padding-left { padding-left:0 !important; }

// Borders
.reset-border { border:0 solid !important; }
.reset-border-top { border-top:0 solid !important; }
.reset-border-right { border-right:0 solid !important; }
.reset-border-bottom { border-bottom:0 solid !important; }
.reset-border-left { border-left:0 solid !important; }

/*
 * Position
 */

.absolute { @include absolute; }
.fixed { @include fixed; }
.relative { @include relative; }

/*
 * Typography
 */

// Text alignment
.text-left { @include text-left; }
.text-right { @include text-right; }
.text-center { @include text-center; }

// Text transform
.uppercase { @include uppercase; }

/*
 * Full
 */

// Full size
.full-size { @include full-size; }

// Full width
.full-width { @include full-width; }

/*
 * Object
 */

// Fit
.obj-fit-cover {
    object-fit:cover !important;
}
.obj-fit-contain {
    object-fit:contain !important;
}

// Position
.obj-pos-center-center {
    object-position:center center !important;
}
.obj-pos-center-top {
    object-position:center top !important;
}
.obj-pos-center-bottom {
    object-position:center bottom !important;
}

.obj-pos-left-center {
    object-position:left center !important;
}
.obj-pos-left-top {
    object-position:left top !important;
}
.obj-pos-left-bottom {
    object-position:left bottom !important;
}

.obj-pos-right-center {
    object-position:right center !important;
}
.obj-pos-right-top {
    object-position:right top !important;
}
.obj-pos-right-bottom {
    object-position:right bottom !important;
}

/*
 * Responsiveness
 */

.hide-all {
    display:none !important;
}

.hide-desktop {

    @media screen and (min-width:769px) {
        display:none !important;
    }
}

.hide-mobile {

    @media screen and (max-width:768px) {
        display:none !important;
    }
}

// Screen readers only
.sr-only {
    @include absolute;
    width:1px; height:1px;
    margin:-1px; padding:0;
    border:0;
    overflow:hidden;
    clip:rect(0,0,0,0);
}
