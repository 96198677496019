.event-locations {

    &--slide {

        @media screen and (min-width:769px) {
            padding:32px;
        }
    }

    &--content {
        @include flex-row;

        @media screen and (min-width:769px) {
            flex-wrap:wrap;
            column-gap:48px;
        }
        @media screen and (max-width:768px) {
            flex-direction:column;
        }

        &.has-image > .event-locations--rhc {

            @media screen and (min-width:769px) {
                width:calc(70% - 48px);
            }
        }
    }

    &--lhc {

        @media screen and (min-width:769px) {
            width:30%;
        }
    }
    &--rhc {

        @media screen and (min-width:769px) {
            @include flex-col;
            flex:1 1 auto;
            width:100%;
        }
        @media screen and (max-width:768px) {
            padding:24px;
        }
    }

    &--pic {
        @include aspect-ratio-media(1, 1);

        @media screen and (max-width:768px) {
            @include aspect-ratio-media(16, 9);
        }
    }

    &--heading {
        @include flex-row;
        justify-content:space-between;
        align-items:center;
        column-gap:24px;
        font-weight:700;
        text-align:center;
    }

    &--arrow {
        width:24px; height:24px;

        @media screen and (max-width:768px) {
            width:18px; height:18px;
        }

        &:first-child svg {
            transform:rotate(180deg);
        }

        &:disabled {
            opacity:.24;
            cursor:default;
        }

        svg {
            fill:currentColor;
        }
    }

    &--text {
        padding-top:48px;
        font-style:italic;

        @media screen and (max-width:768px) {
            padding-top:18px;
        }
    }

    &--address,
    &--meta {
        padding-top:24px;
        font-weight:500;

        @media screen and (max-width:768px) {
            padding-top:18px;
        }
    }

    &--meta {
        @include flex-row;

        @media screen and (min-width:769px) {
            justify-content:space-between;
            align-items:center;
            column-gap:48px;
            margin-top:auto;
        }
        @media screen and (max-width:768px) {
            flex-direction:column;
            row-gap:18px;
            padding-top:12px;
        }
    }
}