/*
 * Headings
 */

h1, h2, h3, h4, h5, h6 {
    font-weight:500;
}

h1, .h1 {
    line-height:map-get($line-heights, 'h1');
    font-size:map-get($font-sizes, 'h1');

    @media screen and (max-width:768px) {
        line-height:map-get($line-heights, 'h2');
        font-size:map-get($font-sizes, 'h2');
    }
}

h2, .h2 {
    line-height:map-get($line-heights, 'h2');
    font-size:map-get($font-sizes, 'h2');

    @media screen and (max-width:768px) {
        line-height:map-get($line-heights, 'h3');
        font-size:map-get($font-sizes, 'h3');
    }
}

h3, .h3 {
    line-height:map-get($line-heights, 'h3');
    font-size:map-get($font-sizes, 'h3');

    @media screen and (max-width:768px) {
        line-height:map-get($line-heights, 'h4');
        font-size:map-get($font-sizes, 'h4');
    }
}

h4, .h4 {
    line-height:map-get($line-heights, 'h4');
    font-size:map-get($font-sizes, 'h4');

    @media screen and (max-width:768px) {
        line-height:map-get($line-heights, 'h5');
        font-size:map-get($font-sizes, 'h5');
    }
}

h5, .h5 {
    line-height:map-get($line-heights, 'h5');
    font-size:map-get($font-sizes, 'h5');
}

h6, .h6 {
    font-size:map-get($font-sizes, 'default');

    @media screen and (max-width:768px) {
        font-size:map-get($font-sizes, 'medium');
    }
}

/*
 * Horizonal line
 */

hr {
    height:1px;
    border:none;
    overflow:visible;
    box-sizing:content-box;
}

/*
 * Anchor
 */

a {
    color:inherit;
    text-decoration:none;
    text-decoration-skip:objects;
    background-color:transparent;

    &:active,
    &:hover {
        outline-width:0;
    }

    &[href^="mailto:"] {
        word-break:break-word;
    }
}

/*
 * Bold / strong
 */

b,
strong {
    font-weight:inherit;
    font-weight:600;
}

/*
 * Italic
 */

i,
em,
dfn {
    font-style:italic;
}

/*
 * Mark
 */

mark {
    color:#333;
    background:none;
}

/*
 * Small
 */

small,
.small {
    font-size:map-get($font-sizes, 'medium');

    @media screen and (max-width:768px) {
        font-size:map-get($font-sizes, 'small');
    }
}

/*
 * Sub / Sup
 */

sub,
sup {
    position:relative;
    line-height:0;
    font-size:75%;
    vertical-align:baseline;
}
sub {
    bottom:-0.25em;
}
sup {
    top:-0.5em;
}

/*
 * List
 */

.list-bare {
    list-style:none;
}
