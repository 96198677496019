.pb-block--media-text {

    .g-grid {

        &:not(.grid--reverse) {

            .col-media {

                @media screen and (max-width:768px) {
                    padding-top:24px;
                }
            }
        }

        &.grid--reverse {

            .col-media {

                @media screen and (max-width:768px) {
                    padding-top:24px;
                }
            }
        }
    }
}
