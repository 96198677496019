.g-downloads {
    margin-top:-24px;

    @media screen and (max-width:768px) {
        margin-top:-12px;
    }

    &--heading {

        @media screen and (max-width:768px) {
            padding-bottom:24px !important;
        }
    }

    &--item {
        @include flex-col;
        @include relative;
        flex:1 1 auto;
        margin-top:24px; padding:24px;
        text-align:center;
        justify-content: center;

        @media screen and (max-width:768px) {
            margin-top:12px;
        }

        &--link {
            @include flex-col;
            align-items:center;
            text-align:center;
            line-height:map-get($line-heights, 'medium');
            font-size:map-get($font-sizes, 'medium');

            svg {
                @include animate((fill));
                display:block;
                width:48px; height:48px;
                fill:currentColor;
                transform:rotate(90deg);
            }
        }
    }

    &--btn-more {
        margin:24px 0 0 auto;
    }
}
