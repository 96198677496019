$-padding: (
    'default': 96px,
    'medium': 48px,
    'small': 24px,
);

.page-builder {

    // Header height modifier
    &:first-child .pb-block:first-child {

        @media screen and (min-width:769px) {
            padding-top:calc(map-get($-padding, 'default') + 42px);
        }
        @media screen and (max-width:1220px) and (min-width:769px) {
            padding-top:calc(map-get($-padding, 'medium') + 42px);
        }
    }

    &:last-child > .pb-block.pb-block--has-bg:last-child {
        margin-bottom:calc(map-get($-padding, 'default') * -1);

        @media screen and (max-width:1220px) and (min-width:769px) {
            margin-bottom:calc(map-get($-padding, 'medium') * -1);
        }
        @media screen and (max-width:768px) {
            margin-bottom:calc(map-get($-padding, 'medium') * -1);
        }
    }
}

/*
 * Block
 */

.pb-block {
    padding:map-get($-padding, 'default');

    @media screen and (max-width:1220px) and (min-width:1025px) {
        padding:map-get($-padding, 'medium') calc(map-get($-padding, 'medium') + 24px);
    }
    @media screen and (max-width:1024px) and (min-width:769px) {
        padding:map-get($-padding, 'medium');
    }
    @media screen and (max-width:768px) {
        padding:map-get($-padding, 'medium') map-get($-padding, 'small');
    }

    // Background
    &.pb-block--has-bg {
        @include relative;
    }

    // No Background
    &:not(.pb-block--has-bg) {

        & + .pb-block:not(.pb-block--has-bg):not(.pb-block-divider) {
            margin-top:calc(map-get($-padding, 'default') * -1) !important;

            @media screen and (max-width:1220px) and (min-width:769px) {
                margin-top:calc(map-get($-padding, 'medium') * -1) !important;
            }
            @media screen and (max-width:768px) {
                margin-top:calc(map-get($-padding, 'medium') * -1) !important;
            }
        }

        &:last-child {
            padding-bottom:0;
        }
    }

    // Push Bottom
    &--push-bottom {
        padding-bottom:144px;

        @media screen and (max-width:1220px) {
            padding-bottom:96px;
        }
    }
}

/*
 * Intro
 */

.pb-block--intro {
    padding-bottom:48px;
}

/*
 * Footer
 */

.pb-block--footer {
    @include flex-row;
    justify-content:center;
    padding-top:48px;
}

/*
 * Background
 */

.pb-block--bg {
    @include full-size(-1);
    overflow:hidden;

    // Image
    &--pic {
        @include full-size(-1);
        display:block;
    }
    &--img {
        @include full-size(-1);
        width:100%; height:100% !important;
        object-fit:cover;

        &.pos-left {
            object-position:left;
        }
        &.pos-right {
            object-position:right;
        }
    }

    // Video
    &--video {
        @include full-size(-1);
        display:block;
        width:100%; height:100%;
        background-size:cover;
        object-fit:cover;
        opacity:1;
    }

    // Shape
    &--shape {
        @include absolute;
        top:0; left:50%; right:0; bottom:0;
        transform:skewX(-45deg);
        transform-origin:bottom;

        @media screen and (max-width:768px) {
            left:60%;
        }

        &.reverse {

            @media screen and (min-width:769px) {
                transform:skewX(45deg);
                transform-origin:top;
            }
        }
    }
}
