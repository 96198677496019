.team {
    margin-top:-48px;

    &--item {
        margin-top:48px;

        &--thumb {
            @include relative;
            display:block;
            width:100%;
            overflow:hidden;

            @include hocus {

                .team--item--bio-wrapper {
                    opacity:1;
                }
            }
        }

        &--pic {
            @include aspect-ratio-media(4, 3);
            overflow:hidden;
        }

        &--bio-wrapper {
            @include animate((opacity));
            @include flex-col;
            @include full-size(2);
            // justify-content:center;
            // align-items:center;
            background:rgba(#FFF, .64);
            opacity:0;
            backdrop-filter:blur(12px);
            overflow:auto;
        }

        &--bio {
            align-self:center;
            padding:24px;
            line-height:map-get($line-heights, 'medium');
            font-size:map-get($font-sizes, 'medium');
            text-align:center;
        }

        &--meta {
            padding-top:18px;
            line-height:map-get($line-heights, 'medium');
        }

        &--name {
            font-weight:700;
        }

        &--role,
        &--email {
            font-size:map-get($font-sizes, 'medium');
            font-weight:500;
        }

        &--email {
            padding-top:9px;
            text-decoration:underline;
        }
    }
}
