$-height: (
    'default': 96px,
    'medium': 48px,
    'small': 48px,
);

.pb-block--divider {

    &.h-default {
        height:map-get($-height, 'default');
    }
    &.h-medium {
        height:map-get($-height, 'medium');
    }
    &.h-small {
        height:map-get($-height, 'small');
    }

    @media screen and (max-width:1220px) and (min-width:769px) {
        &.h-default {
            height:map-get($-height, 'medium');
        }
    }
    @media screen and (max-width:768px) {
        &.h-default,
        &.h-medium {
            height:map-get($-height, 'small');
        }
    }
}
