.btn {
    @include flex-col;
    width:fit-content;
    font-family:map-get($font-families, '01');
    font-size:map-get($font-sizes, 'default');
    box-sizing:border-box;

    &:not(.btn--has-icon) {
        justify-content:center;
    }

    @media screen and (max-width:768px) {
        line-height:map-get($line-heights, 'medium');
        font-size:map-get($font-sizes, 'medium');
    }

    &:disabled {
        cursor:default;
        opacity:.24;
    }

    // Label
    &--label {

        @media screen and (max-width:768px) {
            flex:1 1 auto;
        }
    }

    // Icon
    &--icon {
        flex-shrink:0;

        svg {
            display:block;
            flex-shrink:0;
            width:100%; height:100%;
        }
    }

    // Rounded
    &--rounded {
        border-radius:6px;
    }
}

/*
 * Btn (Bare)
 */

.btn-bare {

    &:disabled {
        opacity:.48;
    }

    // Icon
    .btn--icon {
        width:18px; height:18px;
        transform:translateY(-2px);
    }

    // Black --> Red
    &.btn-style--01-01--02-07 {
        @include btn-bare(
            map-get($colors, '01-01'), map-get($colors, '02-07-p'),
        );
    }
}

/*
 * Btn (01)
 */

.btn-01 {
    @include relative;
    padding:12px 24px;
    font-weight:500;
    overflow:hidden;

    // Sizes
    &.btn-small {
        padding:6px 24px;
    }

    // Arrow
    &.btn-arrow {
        justify-content:space-between;
        min-width:200px;
        mask-repeat:no-repeat;
        mask-composite:exclude;

        &:not(.icon-right) {
            padding-left:16px;
            mask-image:url('../images/triangle-mask-left.svg'), linear-gradient(#fff 0 0);
            mask-position:-1px;
        }
        &.icon-right {
            padding-right:16px;
            mask-image:url('../images/triangle-mask-right.svg'), linear-gradient(#fff 0 0);
            mask-position:calc(100% + 1px);
        }
    }

    // Icon
    .btn--icon {
        width:18px; height:18px;

        &.icon--chevron-bid {
            width:24px; height:24px;
        }
    }

    // Purple
    &.btn-style--02-01 {
        @include btn-01(
            map-get($colors, '02-01-p'), map-get($colors, '02-01-a'),
            #FFF, #FFF,
        );
    }

    // Purple (Accent)
    &.btn-style--02-01-alt {
        @include btn-01(
            map-get($colors, '02-01-a'), map-get($colors, '02-01-p'),
            #FFF, #FFF,
        );
    }

    // Green
    &.btn-style--02-02 {
        @include btn-01(
            map-get($colors, '02-02-p'), map-get($colors, '02-02-a'),
            #FFF, #FFF,
        );
    }

    // Green (Accent)
    &.btn-style--02-02-alt {
        @include btn-01(
            map-get($colors, '02-02-a'), map-get($colors, '02-02-p'),
            #FFF, #FFF,
        );
    }

    // Orange
    &.btn-style--02-03 {
        @include btn-01(
            map-get($colors, '02-03-p'), map-get($colors, '02-03-a'),
            #FFF, #FFF,
        );
    }

    // Orange (Accent)
    &.btn-style--02-03-alt {
        @include btn-01(
            map-get($colors, '02-03-a'), map-get($colors, '02-03-p'),
            #FFF, #FFF,
        );
    }

    // Yellow
    &.btn-style--02-04 {
        @include btn-01(
            map-get($colors, '02-04-p'), map-get($colors, '02-04-a'),
            map-get($colors, '01-01'), map-get($colors, '01-01'),
        );
    }

    // Yellow (Accent)
    &.btn-style--02-04-alt {
        @include btn-01(
            map-get($colors, '02-04-a'), map-get($colors, '02-04-p'),
            map-get($colors, '01-01'), map-get($colors, '01-01'),
        );
    }

    // Sky
    &.btn-style--02-05 {
        @include btn-01(
            map-get($colors, '02-05-p'), map-get($colors, '02-05-a'),
            map-get($colors, '01-01'), map-get($colors, '01-01'),
        );
    }

    // Sky (Accent)
    &.btn-style--02-05-alt {
        @include btn-01(
            map-get($colors, '02-05-a'), map-get($colors, '02-05-p'),
            map-get($colors, '01-01'), map-get($colors, '01-01'),
        );
    }

    // Blue
    &.btn-style--02-06 {
        @include btn-01(
            map-get($colors, '02-06-p'), map-get($colors, '02-06-a'),
            #FFF, #FFF,
        );
    }

    // Blue (Accent)
    &.btn-style--02-06-alt {
        @include btn-01(
            map-get($colors, '02-06-a'), map-get($colors, '02-06-p'),
            #FFF, #FFF,
        );
    }

    // Red
    &.btn-style--02-07 {
        @include btn-01(
            map-get($colors, '02-07-p'), map-get($colors, '02-07-a'),
            #FFF, #FFF,
        );
    }

    // Red (Accent)
    &.btn-style--02-07-alt {
        @include btn-01(
            map-get($colors, '02-07-a'), map-get($colors, '02-07-p'),
            #FFF, #FFF,
        );
    }

    // Pink
    &.btn-style--02-08 {
        @include btn-01(
            map-get($colors, '02-08-p'), map-get($colors, '02-08-a'),
            map-get($colors, '01-01'), map-get($colors, '01-01'),
        );
    }

    // Pink (Accent)
    &.btn-style--02-08-alt {
        @include btn-01(
            map-get($colors, '02-08-a'), map-get($colors, '02-08-p'),
            map-get($colors, '01-01'), map-get($colors, '01-01'),
        );
    }

    // NSS - Green
    &.btn-style--03-01 {
        @include btn-01(
            map-get($colors, '03-01'), darken(map-get($colors, '03-01'), 6%),
            map-get($colors, '03-02'), map-get($colors, '03-02'),
        );
    }

    // NSS - Dark Green
    &.btn-style--03-02 {
        @include btn-01(
            map-get($colors, '03-02'), darken(map-get($colors, '03-02'), 6%),
            map-get($colors, '03-01'), map-get($colors, '03-01'),
        );
    }

    // NSS - Light Green
    &.btn-style--03-03 {
        @include btn-01(
            map-get($colors, '03-03'), darken(map-get($colors, '03-03'), 6%),
            map-get($colors, '03-02'), map-get($colors, '03-02'),
        );
    }
}

/*
 * Btn (Circle)
 */

 .btn-circle {
    @include flex-col;
    justify-content:center;
    align-items:center;
    width:48px; height:48px;
    border:2px solid;
    border-radius:100%;

    .btn--icon svg {
        width:18px; height:18px;
    }

    // Purple
    &.btn-style--02-01 {
        @include btn-circle(
            map-get($colors, '02-01-p'), map-get($colors, '02-01-a'),
            map-get($colors, '02-01-p'), map-get($colors, '02-01-a'),
        );
    }

    // Green
    &.btn-style--02-02 {
        @include btn-circle(
            map-get($colors, '02-02-p'), map-get($colors, '02-02-a'),
            map-get($colors, '02-02-p'), map-get($colors, '02-02-a'),
        );
    }

    // Orange
    &.btn-style--02-03 {
        @include btn-circle(
            map-get($colors, '02-03-p'), map-get($colors, '02-03-a'),
            map-get($colors, '02-03-p'), map-get($colors, '02-03-a'),
        );
    }

    // Yellow
    &.btn-style--02-04 {
        @include btn-circle(
            map-get($colors, '02-04-p'), map-get($colors, '02-04-a'),
            map-get($colors, '02-04-p'), map-get($colors, '02-04-a'),
        );
    }

    // Sky
    &.btn-style--02-05 {
        @include btn-circle(
            map-get($colors, '02-05-p'), map-get($colors, '02-05-a'),
            map-get($colors, '02-05-p'), map-get($colors, '02-05-a'),
        );
    }

    // Blue
    &.btn-style--02-06 {
        @include btn-circle(
            map-get($colors, '02-06-p'), map-get($colors, '02-06-a'),
            map-get($colors, '02-06-p'), map-get($colors, '02-06-a'),
        );
    }

    // Red
    &.btn-style--02-07 {
        @include btn-circle(
            map-get($colors, '02-07-p'), map-get($colors, '02-07-a'),
            map-get($colors, '02-07-p'), map-get($colors, '02-07-a'),
        );
    }

    // Pink
    &.btn-style--02-08 {
        @include btn-circle(
            map-get($colors, '02-08-p'), map-get($colors, '02-08-a'),
            map-get($colors, '02-08-p'), map-get($colors, '02-08-a'),
        );
    }

    // Black --> Red
    &.btn-style--01-01--02-07 {
        @include btn-circle(
            map-get($colors, '01-01'), map-get($colors, '02-07-p'),
            map-get($colors, '01-01'), map-get($colors, '02-07-p'),
        );
    }
}

/*
 * Btn (Has Icon)
 */

.btn--has-icon {
    @include flex-row;
    align-items:center;

    // Icon (Left)
    &:not(.icon-right) .btn--icon {
        margin-right:12px;

        @media screen and (max-width:768px) {
            margin-right:9px;
        }

        &.icon--chevron-bid {
            transform:scale(-1);
        }
    }

    // Icon (Right)
    &.icon-right .btn--icon {
        order:2;
        margin-left:12px;

        @media screen and (max-width:768px) {
            margin-left:9px;
        }
    }

    .btn--icon.icon-flip {
        transform:scale(-1);
    }
    .btn--icon.icon-90deg {
        transform:rotate(90deg);
    }

    // Icon - Chevron BID
    & > .icon--chevron-bid {
        background:currentColor;
        mask-image:url('../icons/chevron-bid.svg');
        mask-repeat:no-repeat;
    }
}
