.g-form {
}

/*
 * Field
 */

.g-fields {
    margin-top:-24px; padding-top:24px;

    &--heading {
        padding-bottom:12px;
    }

    // Checkbox modifier
    .g-input-checkbox {
        // margin-top:-12px;
    }
}

.g-field {

    // Wrapper
    &--wrapper {
        @include flex-col;
        padding-top:24px;
    }

    // Label
    &--label {
        @include flex-row;
        justify-content:space-between;
        padding-bottom:12px;
        width:fit-content;
        color:map-get($colors, '01-01');

        & > em,
        & > strong {
            padding-left:3px;
        }
    }

    // Feedback
    &--feedback > p {
        padding-top:6px;
        line-height:map-get($line-heights, 'small');

        &.error {
            color:map-get($colors, 'error');
        }

        &:empty {
            display:none;
        }
    }
}

/*
 * Submit
 */

.g-submit {
    @include relative;

    &:after {
        @include animate((opacity, visibility));
        @include absolute;
        content:"";
        top:50%; left:24px;
        margin-top:-8px;
        width:16px; height:16px;
        border:2px solid currentColor; border-top:2px solid transparent; border-radius:50%;
        box-sizing:border-box;
        opacity:0;
        visibility:hidden;
        animation:spin 600ms linear infinite;
    }
}

[data-form-state="loading"] .g-submit {
    padding-left:52px !important;

    &:after {
        opacity:1;
        visibility:visible;
    }
}

/*
 * AJAX
 */

.ajax-loading {

    &.is-loading {

        &:before,
        &:after {
            opacity:1;
            visibility:visible;
        }
    }

    &:before {
        @include animate((opacity, visibility));
        @include fixed(999);
        content:"";
        top:0; left:0; right:0; bottom:0;
        background:rgba(#FFF, .88);
        opacity:0;
        visibility:hidden;
    }

    &:after {
        @include animate((opacity, visibility));
        @include fixed(9999);
        content:"";
        top:50vh; left:50%;
        width:48px; height:48px;
        margin-top:-24px; margin-left:-24px;
        border:2px solid map-get($colors, '02-02'); border-top:2px solid transparent; border-radius:50%;
        box-sizing:border-box;
        opacity:0;
        visibility:hidden;
        animation:spin 600ms linear infinite;
    }
}

/*
 * reCAPTCHA
 */

[google-recaptcha],
.grecaptcha-badge {
    display:none !important;
    opacity:0 !important;
    visibility:hidden !important;
    pointer-events:none !important;
}
