/*
 * Colours
 */
/*
 * Sizes
 */
/*
 * Typography
 */
/*
 * Font Face
 */
@font-face {
  font-family: "Larsseit";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../assets/fonts/larsseit/300.woff2) format("woff2");
}
@font-face {
  font-family: "Larsseit";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../assets/fonts/larsseit/400.woff2) format("woff2");
}
@font-face {
  font-family: "Larsseit";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../../assets/fonts/larsseit/500.woff2) format("woff2");
}
@font-face {
  font-family: "Larsseit";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../assets/fonts/larsseit/700.woff2) format("woff2");
}
/*
 * Keyframes
 */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*
 * Animate
 */
/*
 * Flex
 */
/*
 * Position
 */
/*
 * Hocus
 */
/*
 * Typography
 */
/*
 * Full
 */
/*
 * Aspect ratio
 */
/*
 * Buttons
 */
/*
 * Document
 */
* {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  outline: none;
}

html {
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  background-color: #FFFFFF;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  color: #1C1C1C;
  font-family: "Larsseit", sans-serif;
  font-size: 18px;
  font-weight: 400;
  cursor: default;
  overflow-x: auto;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
  body {
    line-height: 1.4;
    font-size: 16px;
  }
}

/*
 * Sections
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

/*
 * Selections
 */
/*
 * Focus
 */
:focus {
  outline: none;
}

/*
 * Figure
 */
figure {
  display: block;
  margin: 0;
}
figure figcaption {
  display: block;
}

/*
 * Image
 */
img {
  width: auto\9 ;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

/*
 * SVG
 */
svg:not(:root) {
  overflow: hidden;
  pointer-events: none !important;
}

/*
 * Palette
 */
.color-white {
  color: #FFFFFF !important;
}

.bg-white {
  background: #FFFFFF !important;
}

.border-white {
  border-color: #FFFFFF !important;
}

.fill-white {
  fill: #FFFFFF !important;
}

.color-01-01 {
  color: #1C1C1C !important;
}

.bg-01-01 {
  background: #1C1C1C !important;
}

.border-01-01 {
  border-color: #1C1C1C !important;
}

.fill-01-01 {
  fill: #1C1C1C !important;
}

.color-01-02 {
  color: #707070 !important;
}

.bg-01-02 {
  background: #707070 !important;
}

.border-01-02 {
  border-color: #707070 !important;
}

.fill-01-02 {
  fill: #707070 !important;
}

.color-01-03 {
  color: #EFEDED !important;
}

.bg-01-03 {
  background: #EFEDED !important;
}

.border-01-03 {
  border-color: #EFEDED !important;
}

.fill-01-03 {
  fill: #EFEDED !important;
}

.color-02-01-p {
  color: #2F1432 !important;
}

.bg-02-01-p {
  background: #2F1432 !important;
}

.border-02-01-p {
  border-color: #2F1432 !important;
}

.fill-02-01-p {
  fill: #2F1432 !important;
}

.color-02-01-a {
  color: #46274A !important;
}

.bg-02-01-a {
  background: #46274A !important;
}

.border-02-01-a {
  border-color: #46274A !important;
}

.fill-02-01-a {
  fill: #46274A !important;
}

.color-02-02-p {
  color: #314D41 !important;
}

.bg-02-02-p {
  background: #314D41 !important;
}

.border-02-02-p {
  border-color: #314D41 !important;
}

.fill-02-02-p {
  fill: #314D41 !important;
}

.color-02-02-a {
  color: #446055 !important;
}

.bg-02-02-a {
  background: #446055 !important;
}

.border-02-02-a {
  border-color: #446055 !important;
}

.fill-02-02-a {
  fill: #446055 !important;
}

.color-02-03-p {
  color: #CE6447 !important;
}

.bg-02-03-p {
  background: #CE6447 !important;
}

.border-02-03-p {
  border-color: #CE6447 !important;
}

.fill-02-03-p {
  fill: #CE6447 !important;
}

.color-02-03-a {
  color: #E67E62 !important;
}

.bg-02-03-a {
  background: #E67E62 !important;
}

.border-02-03-a {
  border-color: #E67E62 !important;
}

.fill-02-03-a {
  fill: #E67E62 !important;
}

.color-02-04-p {
  color: #F2C359 !important;
}

.bg-02-04-p {
  background: #F2C359 !important;
}

.border-02-04-p {
  border-color: #F2C359 !important;
}

.fill-02-04-p {
  fill: #F2C359 !important;
}

.color-02-04-a {
  color: #FFDC8E !important;
}

.bg-02-04-a {
  background: #FFDC8E !important;
}

.border-02-04-a {
  border-color: #FFDC8E !important;
}

.fill-02-04-a {
  fill: #FFDC8E !important;
}

.color-02-05-p {
  color: #AFCFD0 !important;
}

.bg-02-05-p {
  background: #AFCFD0 !important;
}

.border-02-05-p {
  border-color: #AFCFD0 !important;
}

.fill-02-05-p {
  fill: #AFCFD0 !important;
}

.color-02-05-a {
  color: #D6EBEB !important;
}

.bg-02-05-a {
  background: #D6EBEB !important;
}

.border-02-05-a {
  border-color: #D6EBEB !important;
}

.fill-02-05-a {
  fill: #D6EBEB !important;
}

.color-02-06-p {
  color: #3779B9 !important;
}

.bg-02-06-p {
  background: #3779B9 !important;
}

.border-02-06-p {
  border-color: #3779B9 !important;
}

.fill-02-06-p {
  fill: #3779B9 !important;
}

.color-02-06-a {
  color: #4085CB !important;
}

.bg-02-06-a {
  background: #4085CB !important;
}

.border-02-06-a {
  border-color: #4085CB !important;
}

.fill-02-06-a {
  fill: #4085CB !important;
}

.color-02-07-p {
  color: #822F35 !important;
}

.bg-02-07-p {
  background: #822F35 !important;
}

.border-02-07-p {
  border-color: #822F35 !important;
}

.fill-02-07-p {
  fill: #822F35 !important;
}

.color-02-07-a {
  color: #964046 !important;
}

.bg-02-07-a {
  background: #964046 !important;
}

.border-02-07-a {
  border-color: #964046 !important;
}

.fill-02-07-a {
  fill: #964046 !important;
}

.color-02-08-p {
  color: #F2CCC9 !important;
}

.bg-02-08-p {
  background: #F2CCC9 !important;
}

.border-02-08-p {
  border-color: #F2CCC9 !important;
}

.fill-02-08-p {
  fill: #F2CCC9 !important;
}

.color-02-08-a {
  color: #F8DFDD !important;
}

.bg-02-08-a {
  background: #F8DFDD !important;
}

.border-02-08-a {
  border-color: #F8DFDD !important;
}

.fill-02-08-a {
  fill: #F8DFDD !important;
}

.color-03-01 {
  color: #19D41C !important;
}

.bg-03-01 {
  background: #19D41C !important;
}

.border-03-01 {
  border-color: #19D41C !important;
}

.fill-03-01 {
  fill: #19D41C !important;
}

.color-03-02 {
  color: #00382E !important;
}

.bg-03-02 {
  background: #00382E !important;
}

.border-03-02 {
  border-color: #00382E !important;
}

.fill-03-02 {
  fill: #00382E !important;
}

.color-03-03 {
  color: #F0F6E6 !important;
}

.bg-03-03 {
  background: #F0F6E6 !important;
}

.border-03-03 {
  border-color: #F0F6E6 !important;
}

.fill-03-03 {
  fill: #F0F6E6 !important;
}

.color-error {
  color: #D91E18 !important;
}

.bg-error {
  background: #D91E18 !important;
}

.border-error {
  border-color: #D91E18 !important;
}

.fill-error {
  fill: #D91E18 !important;
}

.color-alert {
  color: #F89406 !important;
}

.bg-alert {
  background: #F89406 !important;
}

.border-alert {
  border-color: #F89406 !important;
}

.fill-alert {
  fill: #F89406 !important;
}

.color-valid {
  color: #00B16A !important;
}

.bg-valid {
  background: #00B16A !important;
}

.border-valid {
  border-color: #00B16A !important;
}

.fill-valid {
  fill: #00B16A !important;
}

.color-update {
  color: #1E8BC3 !important;
}

.bg-update {
  background: #1E8BC3 !important;
}

.border-update {
  border-color: #1E8BC3 !important;
}

.fill-update {
  fill: #1E8BC3 !important;
}

/*
 * Flex
 */
.flex-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

/*
 * Widths
 */
.w-wide {
  max-width: 1440px;
}

.w-default {
  max-width: 1220px;
}

.w-medium {
  max-width: 980px;
}

.w-small {
  max-width: 680px;
}

.w-xsmall {
  max-width: 460px;
}

/*
 * Resets
 */
.reset-margin {
  margin: 0 !important;
}

.reset-margin-top {
  margin-top: 0 !important;
}

.reset-margin-right {
  margin-right: 0 !important;
}

.reset-margin-bottom {
  margin-bottom: 0 !important;
}

.reset-margin-left {
  margin-left: 0 !important;
}

.reset-padding {
  padding: 0 !important;
}

.reset-padding-top {
  padding-top: 0 !important;
}

.reset-padding-right {
  padding-right: 0 !important;
}

.reset-padding-bottom {
  padding-bottom: 0 !important;
}

.reset-padding-left {
  padding-left: 0 !important;
}

.reset-border {
  border: 0 solid !important;
}

.reset-border-top {
  border-top: 0 solid !important;
}

.reset-border-right {
  border-right: 0 solid !important;
}

.reset-border-bottom {
  border-bottom: 0 solid !important;
}

.reset-border-left {
  border-left: 0 solid !important;
}

/*
 * Position
 */
.absolute {
  position: absolute;
  z-index: 1;
}

.fixed {
  position: fixed;
  z-index: 1;
}

.relative {
  position: relative;
  z-index: 1;
}

/*
 * Typography
 */
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

/*
 * Full
 */
.full-size {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.full-width {
  display: block;
  width: 100%;
  height: auto;
}

/*
 * Object
 */
.obj-fit-cover {
  object-fit: cover !important;
}

.obj-fit-contain {
  object-fit: contain !important;
}

.obj-pos-center-center {
  object-position: center center !important;
}

.obj-pos-center-top {
  object-position: center top !important;
}

.obj-pos-center-bottom {
  object-position: center bottom !important;
}

.obj-pos-left-center {
  object-position: left center !important;
}

.obj-pos-left-top {
  object-position: left top !important;
}

.obj-pos-left-bottom {
  object-position: left bottom !important;
}

.obj-pos-right-center {
  object-position: right center !important;
}

.obj-pos-right-top {
  object-position: right top !important;
}

.obj-pos-right-bottom {
  object-position: right bottom !important;
}

/*
 * Responsiveness
 */
.hide-all {
  display: none !important;
}

@media screen and (min-width: 769px) {
  .hide-desktop {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .hide-mobile {
    display: none !important;
  }
}

.sr-only {
  position: absolute;
  z-index: 1;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  line-height: 1.5;
  font-family: "Larsseit", sans-serif;
  font-size: 100%;
  border: 0 solid;
  border-radius: 0;
  appearance: none;
}

[type=tel],
[type=text],
[type=email],
[type=password] {
  display: block;
  width: 100%;
  background: transparent;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  appearance: none;
  background-color: none;
  background: none;
  cursor: pointer;
}

[type=submit] {
  cursor: pointer;
  overflow: visible;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button > * {
  pointer-events: none !important;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  display: block;
  width: 100%;
  background: transparent;
  resize: none;
  overflow: auto;
  box-sizing: border-box;
}

[type=checkbox],
[type=radio] {
  padding: 0;
  box-sizing: border-box;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  appearance: textfield;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

[hidden] {
  display: none;
}

select::-ms-expand {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
}

select option {
  color: initial !important;
}

/*
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

h1, .h1 {
  line-height: 1;
  font-size: 48px;
}
@media screen and (max-width: 768px) {
  h1, .h1 {
    line-height: 1.1;
    font-size: 32px;
  }
}

h2, .h2 {
  line-height: 1.1;
  font-size: 32px;
}
@media screen and (max-width: 768px) {
  h2, .h2 {
    line-height: 1.2;
    font-size: 24px;
  }
}

h3, .h3 {
  line-height: 1.2;
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  h3, .h3 {
    line-height: 1.3;
    font-size: 22px;
  }
}

h4, .h4 {
  line-height: 1.3;
  font-size: 22px;
}
@media screen and (max-width: 768px) {
  h4, .h4 {
    line-height: 1.4;
    font-size: 20px;
  }
}

h5, .h5 {
  line-height: 1.4;
  font-size: 20px;
}

h6, .h6 {
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  h6, .h6 {
    font-size: 16px;
  }
}

/*
 * Horizonal line
 */
hr {
  height: 1px;
  border: none;
  overflow: visible;
  box-sizing: content-box;
}

/*
 * Anchor
 */
a {
  color: inherit;
  text-decoration: none;
  text-decoration-skip: objects;
  background-color: transparent;
}
a:active, a:hover {
  outline-width: 0;
}
a[href^="mailto:"] {
  word-break: break-word;
}

/*
 * Bold / strong
 */
b,
strong {
  font-weight: inherit;
  font-weight: 600;
}

/*
 * Italic
 */
i,
em,
dfn {
  font-style: italic;
}

/*
 * Mark
 */
mark {
  color: #333;
  background: none;
}

/*
 * Small
 */
small,
.small {
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  small,
  .small {
    font-size: 14px;
  }
}

/*
 * Sub / Sup
 */
sub,
sup {
  position: relative;
  line-height: 0;
  font-size: 75%;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
 * List
 */
.list-bare {
  list-style: none;
}

.lazyload-wrapper img {
  display: block;
  width: 100%;
  height: auto;
  pointer-events: none !important;
}

.ays-survey-section-header {
  margin: 0 !important;
  padding: 0 !important;
  padding-bottom: 48px !important;
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.ays-survey-question {
  border: 1px solid #EFEDED !important;
  border-radius: 0 !important;
}

.ays-survey-section-button-container {
  box-shadow: none !important;
}
.ays-survey-section-button-container:last-child {
  margin-right: 0 !important;
}

/*
 * Field
 */
.g-fields {
  margin-top: -24px;
  padding-top: 24px;
}
.g-fields--heading {
  padding-bottom: 12px;
}
.g-field--wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-top: 24px;
}
.g-field--label {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;
  width: fit-content;
  color: #1C1C1C;
}
.g-field--label > em, .g-field--label > strong {
  padding-left: 3px;
}
.g-field--feedback > p {
  padding-top: 6px;
  line-height: 1.2;
}
.g-field--feedback > p.error {
  color: #D91E18;
}
.g-field--feedback > p:empty {
  display: none;
}

/*
 * Submit
 */
.g-submit {
  position: relative;
  z-index: 1;
}
.g-submit:after {
  transition-property: opacity, visibility;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: 1;
  content: "";
  top: 50%;
  left: 24px;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  border: 2px solid currentColor;
  border-top: 2px solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  animation: spin 600ms linear infinite;
}

[data-form-state=loading] .g-submit {
  padding-left: 52px !important;
}
[data-form-state=loading] .g-submit:after {
  opacity: 1;
  visibility: visible;
}

/*
 * AJAX
 */
.ajax-loading.is-loading:before, .ajax-loading.is-loading:after {
  opacity: 1;
  visibility: visible;
}
.ajax-loading:before {
  transition-property: opacity, visibility;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: fixed;
  z-index: 999;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.88);
  opacity: 0;
  visibility: hidden;
}
.ajax-loading:after {
  transition-property: opacity, visibility;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: fixed;
  z-index: 9999;
  content: "";
  top: 50vh;
  left: 50%;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  margin-left: -24px;
  border: 2px solid;
  border-top: 2px solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  animation: spin 600ms linear infinite;
}

/*
 * reCAPTCHA
 */
[google-recaptcha],
.grecaptcha-badge {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
}

.g-input-checkbox {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  /*
   * Input
   */
  /*
   * Checkbox
   */
  /*
   * Label
   */
  /*
   * Checkbox modifier
   */
  /*
   * Radio modifier
   */
}
.g-input-checkbox input {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  padding: 0;
  box-sizing: border-box;
  background: none;
  border: 0 solid;
  border-radius: 0;
  opacity: 0;
  cursor: pointer;
  appearance: none;
}
.g-input-checkbox input:disabled {
  cursor: default;
}
.g-input-checkbox input:disabled + span + label {
  cursor: default;
}
.g-input-checkbox span {
  transition-property: background, border;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
}
.g-input-checkbox span > svg {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  opacity: 0;
}
.g-input-checkbox label {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  padding-left: 12px;
  line-height: 1.2;
  text-align: left;
  cursor: pointer;
}
.g-input-checkbox input[type=checkbox]:checked:not(:disabled) + span > svg {
  opacity: 1;
}
.g-input-checkbox input[type=checkbox]:disabled:checked + span > svg {
  opacity: 1;
}
.g-input-checkbox input[type=radio] + span {
  border-radius: 100%;
}
.g-input-checkbox input[type=radio] + span:before {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  content: "";
  display: block;
  flex-shrink: 0;
  border-radius: 100%;
  opacity: 0;
}
.g-input-checkbox input[type=radio]:checked:not(:disabled) + span:before {
  opacity: 1;
}
.g-input-checkbox input[type=radio]:disabled:checked + span:before {
  opacity: 1;
}
.g-input-checkbox.style-default {
  /*
   * Input
   */
  /*
   * Checkbox
   */
  /*
   * Label
   */
  /*
   * Checkbox modifier
   */
  /*
   * Radio modifier
   */
}
.g-input-checkbox.style-default input {
  margin: 0;
  margin-top: -12px;
  width: 24px;
  height: 24px;
}
.g-input-checkbox.style-default input:checked:not(:disabled) + span {
  background: #FFF;
  border-color: #1C1C1C;
}
.g-input-checkbox.style-default input:disabled + span {
  background: #FFF;
}
.g-input-checkbox.style-default input:disabled + span + label {
  color: rgba(28, 28, 28, 0.24);
}
@media (hover: hover) {
  .g-input-checkbox.style-default input:not(:disabled):hover + span {
    background: #FFF;
    border-color: #1C1C1C;
  }
  .g-input-checkbox.style-default input:not(:disabled):hover + span + label {
    color: #1C1C1C;
  }
}
.g-input-checkbox.style-default input:not(:disabled):focus + span {
  background: #FFF;
  border-color: #1C1C1C;
}
.g-input-checkbox.style-default input:not(:disabled):focus + span + label {
  color: #1C1C1C;
}
.g-input-checkbox.style-default span {
  width: 24px;
  height: 24px;
  background: #FFF;
  border: 2px solid #0d0d0d;
  border-radius: 0;
}
.g-input-checkbox.style-default span > svg {
  width: 12px;
  height: 12px;
  fill: #1C1C1C;
}
.g-input-checkbox.style-default label {
  color: #1C1C1C;
  font-size: 18px;
}
.g-input-checkbox.style-default input[type=checkbox]:disabled:checked + span > svg {
  fill: rgba(28, 28, 28, 0.24);
}
@media (hover: hover) {
  .g-input-checkbox.style-default input[type=checkbox]:not(:disabled):hover + span > svg {
    fill: #1C1C1C;
  }
}
.g-input-checkbox.style-default input[type=checkbox]:not(:disabled):focus + span > svg {
  fill: #1C1C1C;
}
.g-input-checkbox.style-default input[type=radio] + span:before {
  width: 16px;
  height: 16px;
  background: #1C1C1C;
}
.g-input-checkbox.style-default input[type=radio]:disabled:checked + span:before {
  background: rgba(28, 28, 28, 0.24);
}
@media (hover: hover) {
  .g-input-checkbox.style-default input[type=radio]:not(:disabled):hover + span:before {
    background: #1C1C1C;
  }
}
.g-input-checkbox.style-default input[type=radio]:not(:disabled):focus + span:before {
  background: #1C1C1C;
}

.g-input-text {
  position: relative;
  z-index: 1;
  /*
   * Input
   */
  /*
   * Label
   */
}
.g-input-text input {
  transition-property: background, border;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  line-height: 1.5;
  font-family: "Larsseit", sans-serif;
  box-shadow: 0 0 0 0 transparent inset !important;
  box-sizing: border-box;
  appearance: none;
}
.g-input-text input:disabled {
  cursor: default;
}
.g-input-text input:not([value=""]) + label, .g-input-text input:focus + label {
  z-index: 1;
  font-size: 8px;
}
.g-input-text label {
  transition-property: padding, color, font-size;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  line-height: 1.5;
  font-family: "Larsseit", sans-serif;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: none;
}
.g-input-text.style-default {
  /*
   * Input
   */
  /*
   * Label
   */
}
.g-input-text.style-default input {
  padding: 10px 24px;
  color: #1C1C1C;
  font-size: 100%;
  font-size: 18px;
  background: #FFF;
  border: 2px solid #0d0d0d;
  border-radius: 0;
}
@media (hover: hover) {
  .g-input-text.style-default input:not(:disabled):hover {
    background: #FFF;
    border-color: #1C1C1C;
  }
}
.g-input-text.style-default input:not(:disabled):focus {
  background: #FFF;
  border-color: #1C1C1C;
}
.g-input-text.style-default input:disabled {
  color: rgba(28, 28, 28, 0.24);
  background: #FFF;
  border-color: rgba(28, 28, 28, 0.48);
}
.g-input-text.style-default input:not([value=""]) + label, .g-input-text.style-default input:focus + label {
  padding: 4px 24px;
}
.g-input-text.style-default label {
  padding: 10px 24px;
  color: #1C1C1C;
  font-size: 18px;
}
.g-input-text.style-light {
  /*
   * Input
   */
  /*
   * Label
   */
}
.g-input-text.style-light input {
  padding: 10px 24px;
  color: #1C1C1C;
  font-size: 100%;
  font-size: 18px;
  background: #FFF;
  border: 2px solid #FFF;
  border-radius: 0;
}
@media (hover: hover) {
  .g-input-text.style-light input:not(:disabled):hover {
    background: #FFF;
    border-color: #FFF;
  }
}
.g-input-text.style-light input:not(:disabled):focus {
  background: #FFF;
  border-color: #FFF;
}
.g-input-text.style-light input:disabled {
  color: rgba(28, 28, 28, 0.24);
  background: #FFF;
  border-color: #FFF;
}
.g-input-text.style-light input:not([value=""]) + label, .g-input-text.style-light input:focus + label {
  padding: 4px 24px;
}
.g-input-text.style-light label {
  padding: 10px 24px;
  color: #1C1C1C;
  font-size: 18px;
}

.g-input-true-false {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-direction: row-reverse;
  align-items: center;
  /*
   * Input
   */
  /*
   * Toggle
   */
  /*
   * Label
   */
}
.g-input-true-false input {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;
  padding: 0;
  box-sizing: border-box;
  background: none;
  border: 0 solid;
  border-radius: 0;
  opacity: 0;
  cursor: pointer;
  appearance: none;
}
.g-input-true-false input:disabled {
  cursor: default;
}
.g-input-true-false input:disabled + span + label {
  cursor: default;
}
.g-input-true-false span {
  transition-property: background, border;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 999px;
  box-sizing: border-box;
}
.g-input-true-false span:before {
  transition-property: margin-left, background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  content: "";
  margin: 2px;
  border-radius: 100%;
}
.g-input-true-false label {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  padding-right: 12px;
  line-height: 1.2;
  text-align: left;
  cursor: pointer;
}
.g-input-true-false.style-default {
  /*
   * Input
   */
  /*
   * Toggle
   */
  /*
   * Label
   */
}
.g-input-true-false.style-default input {
  margin: 0;
  margin-top: -14px;
  width: 56px;
  height: 28px;
}
.g-input-true-false.style-default input:checked:not(:disabled) + span {
  background: #1C1C1C;
  border-color: #1C1C1C;
}
.g-input-true-false.style-default input:checked:not(:disabled) + span:before {
  margin-left: calc(100% - 22px);
  background: #FFF;
}
.g-input-true-false.style-default input:disabled + span {
  background: #FFF;
}
.g-input-true-false.style-default input:disabled + span:before {
  background: rgba(28, 28, 28, 0.24);
}
.g-input-true-false.style-default input:disabled + span + label {
  color: rgba(28, 28, 28, 0.24);
}
@media (hover: hover) {
  .g-input-true-false.style-default input:not(:disabled):hover:checked:not(:disabled) + span {
    background: #1C1C1C;
    border-color: #1C1C1C;
  }
  .g-input-true-false.style-default input:not(:disabled):hover:checked:not(:disabled) + span:before {
    background: #FFF;
  }
  .g-input-true-false.style-default input:not(:disabled):hover:checked:not(:disabled) + span + label {
    color: #1C1C1C;
  }
  .g-input-true-false.style-default input:not(:disabled):hover:not(:checked):not(:disabled) + span {
    background: #FFF;
    border-color: #1C1C1C;
  }
  .g-input-true-false.style-default input:not(:disabled):hover:not(:checked):not(:disabled) + span:before {
    background: #1C1C1C;
  }
  .g-input-true-false.style-default input:not(:disabled):hover:not(:checked):not(:disabled) + span + label {
    color: #1C1C1C;
  }
}
.g-input-true-false.style-default input:not(:disabled):focus:checked:not(:disabled) + span {
  background: #1C1C1C;
  border-color: #1C1C1C;
}
.g-input-true-false.style-default input:not(:disabled):focus:checked:not(:disabled) + span:before {
  background: #FFF;
}
.g-input-true-false.style-default input:not(:disabled):focus:checked:not(:disabled) + span + label {
  color: #1C1C1C;
}
.g-input-true-false.style-default input:not(:disabled):focus:not(:checked):not(:disabled) + span {
  background: #FFF;
  border-color: #1C1C1C;
}
.g-input-true-false.style-default input:not(:disabled):focus:not(:checked):not(:disabled) + span:before {
  background: #1C1C1C;
}
.g-input-true-false.style-default input:not(:disabled):focus:not(:checked):not(:disabled) + span + label {
  color: #1C1C1C;
}
.g-input-true-false.style-default span {
  width: 56px;
  height: 28px;
  background: #FFF;
  border: 2px solid #0d0d0d;
}
.g-input-true-false.style-default span:before {
  width: 20px;
  height: 20px;
  background: #1C1C1C;
}
.g-input-true-false.style-default label {
  color: #1C1C1C;
  font-size: 18px;
}

.g-select {
  position: relative;
  z-index: 1;
  /*
   * Select
   */
  /*
   * Chevron
   */
}
.g-select select {
  transition-property: background, border;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  line-height: 1.5;
  font-family: "Larsseit", sans-serif;
  box-shadow: 0 0 0 0 transparent inset !important;
  box-sizing: border-box;
  appearance: none;
  cursor: pointer;
}
.g-select select:disabled {
  cursor: default;
}
.g-select svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: 1;
  display: block;
  top: 50%;
  transform: rotate(90deg);
}
.g-select.style-default {
  /*
   * Select
   */
  /*
   * Chevron
   */
}
.g-select.style-default select {
  padding: 9px 50px 9px 18px;
  color: #1C1C1C;
  font-size: 100%;
  font-size: 18px;
  background: #FFF;
  border: 2px solid #0d0d0d;
  border-radius: 0;
}
@media (hover: hover) {
  .g-select.style-default select:not(:disabled):hover {
    background: #FFF;
    border-color: #1C1C1C;
  }
}
.g-select.style-default select:not(:disabled):focus {
  background: #FFF;
  border-color: #1C1C1C;
}
.g-select.style-default select:disabled {
  color: rgba(28, 28, 28, 0.24);
  background: #FFF;
  border-color: rgba(28, 28, 28, 0.48);
}
.g-select.style-default svg {
  right: 18px;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  fill: #1C1C1C;
}
.g-select.style-light {
  /*
   * Select
   */
  /*
   * Chevron
   */
}
.g-select.style-light select {
  padding: 9px 50px 9px 18px;
  color: #FFF;
  font-size: 100%;
  font-size: 18px;
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.12);
  border-radius: 0;
}
@media (hover: hover) {
  .g-select.style-light select:not(:disabled):hover {
    background: none;
    border-color: rgba(255, 255, 255, 0.24);
  }
}
.g-select.style-light select:not(:disabled):focus {
  background: none;
  border-color: rgba(255, 255, 255, 0.24);
}
.g-select.style-light select:disabled {
  color: rgba(255, 255, 255, 0.24);
  background: none;
  border-color: rgba(255, 255, 255, 0.12);
}
.g-select.style-light svg {
  right: 18px;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  fill: #FFF;
}

.g-textarea {
  position: relative;
  z-index: 1;
  /*
   * Textarea
   */
  /*
   * Label
   */
}
.g-textarea textarea {
  transition-property: background, border;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  line-height: 1.5;
  font-family: "Larsseit", sans-serif;
  box-shadow: 0 0 0 0 transparent inset !important;
  box-sizing: border-box;
  appearance: none;
}
.g-textarea textarea:disabled {
  cursor: default;
}
.g-textarea textarea:not(:empty) + label, .g-textarea textarea:focus + label {
  z-index: 1;
  font-size: 8px;
}
.g-textarea label {
  transition-property: padding, color, font-size;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  line-height: 1.5;
  font-family: "Larsseit", sans-serif;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: none;
}
.g-textarea.style-default {
  /*
   * Textarea
   */
  /*
   * Label
   */
}
.g-textarea.style-default textarea {
  padding: 16px 24px;
  color: #1C1C1C;
  font-size: 100%;
  font-size: 18px;
  background: #FFF;
  border: 2px solid #0d0d0d;
  border-radius: 0;
}
@media (hover: hover) {
  .g-textarea.style-default textarea:not(:disabled):hover {
    background: #FFF;
    border-color: #1C1C1C;
  }
}
.g-textarea.style-default textarea:not(:disabled):focus {
  background: #FFF;
  border-color: #1C1C1C;
}
.g-textarea.style-default textarea:disabled {
  color: rgba(28, 28, 28, 0.24);
  background: #FFF;
  border-color: rgba(28, 28, 28, 0.48);
}
.g-textarea.style-default textarea:not(:empty) + label, .g-textarea.style-default textarea:focus + label {
  padding: 4px 24px;
}
.g-textarea.style-default label {
  padding: 16px 24px;
  color: #1C1C1C;
  font-size: 18px;
}

[data-grit-accordion-trigger] {
  cursor: pointer;
}
[data-grit-accordion-trigger] > * {
  pointer-events: none;
}

[data-grit-accordion-item] {
  overflow: hidden;
}
[data-grit-accordion-item]:not(.is-active) {
  display: none;
}

[data-grit-accordion-toggle-status=open] > span:first-child {
  display: none;
}

[data-grit-accordion-toggle-status=close] > span:last-child {
  display: none;
}

/*
 * Flash message
 */
.flash-messages {
  transition-property: opacity, visibility;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: fixed;
  z-index: 999;
  left: 12px;
  bottom: 12px;
}
@media screen and (max-width: 768px) {
  .flash-messages {
    right: 12px;
  }
}
.flash-messages:has(.flash-message:not(.is-active)) {
  opacity: 0;
  visibility: hidden;
}
.flash-messages.polyfill-has {
  opacity: 0;
  visibility: hidden;
}

.flash-message {
  transition-property: opacity, visibility;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
}
.flash-message:not(.is-inline) {
  max-width: 400px;
}
.flash-message:not(.is-active) {
  opacity: 0;
  visibility: hidden;
}
.flash-message.is-success {
  background: #00B16A;
}
.flash-message.is-error {
  background: #D91E18;
}
.flash-message--content {
  padding: 24px 32px;
  line-height: 1.4;
  color: #FFF;
  font-size: 16px;
}
.flash-message--close {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  background: rgba(0, 0, 0, 0.12) !important;
}
.flash-message--close svg {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  width: 14px;
  height: 14px;
  fill: #FFF;
  opacity: 0.48;
}
@media (hover: hover) {
  .flash-message--close:not(:disabled):hover svg {
    opacity: 1;
  }
}
.flash-message--close:not(:disabled):focus svg {
  opacity: 1;
}

.box {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
}
.box--content {
  width: 100%;
}

.breadcrumbs {
  line-height: 1.2;
  font-size: 16px;
}
.breadcrumbs--list, .breadcrumbs--item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  column-gap: 9px;
}
.breadcrumbs--link {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #707070;
}
@media (hover: hover) {
  .breadcrumbs--link:not(:disabled):hover {
    color: #1C1C1C;
  }
}
.breadcrumbs--link:not(:disabled):focus {
  color: #1C1C1C;
}
.breadcrumbs--link.is-current {
  color: #1C1C1C;
}
.breadcrumbs--home {
  margin-top: -5px;
  padding-right: 3px;
  width: 18px;
  height: 18px;
}
.breadcrumbs--home svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  width: 18px;
  height: 18px;
  fill: #707070;
}
@media (hover: hover) {
  .breadcrumbs--home:not(:disabled):hover svg {
    fill: #1C1C1C;
  }
}
.breadcrumbs--home:not(:disabled):focus svg {
  fill: #1C1C1C;
}
.breadcrumbs--chevron {
  width: 9px;
  height: 9px;
}
.breadcrumbs--chevron svg {
  display: block;
  width: 9px;
  height: 9px;
  fill: #707070;
}

.btn {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: fit-content;
  font-family: "Larsseit", sans-serif;
  font-size: 18px;
  box-sizing: border-box;
}
.btn:not(.btn--has-icon) {
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .btn {
    line-height: 1.4;
    font-size: 16px;
  }
}
.btn:disabled {
  cursor: default;
  opacity: 0.24;
}
@media screen and (max-width: 768px) {
  .btn--label {
    flex: 1 1 auto;
  }
}
.btn--icon {
  flex-shrink: 0;
}
.btn--icon svg {
  display: block;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}
.btn--rounded {
  border-radius: 6px;
}

/*
 * Btn (Bare)
 */
.btn-bare:disabled {
  opacity: 0.48;
}
.btn-bare .btn--icon {
  width: 18px;
  height: 18px;
  transform: translateY(-2px);
}
.btn-bare.btn-style--01-01--02-07 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #1C1C1C !important;
}
.btn-bare.btn-style--01-01--02-07 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #1C1C1C !important;
}
@media (hover: hover) {
  .btn-bare.btn-style--01-01--02-07:not(:disabled):hover {
    color: #822F35 !important;
  }
  .btn-bare.btn-style--01-01--02-07:not(:disabled):hover .btn--icon svg {
    fill: #822F35 !important;
  }
}
.btn-bare.btn-style--01-01--02-07:not(:disabled):focus {
  color: #822F35 !important;
}
.btn-bare.btn-style--01-01--02-07:not(:disabled):focus .btn--icon svg {
  fill: #822F35 !important;
}

/*
 * Btn (01)
 */
.btn-01 {
  position: relative;
  z-index: 1;
  padding: 12px 24px;
  font-weight: 500;
  overflow: hidden;
}
.btn-01.btn-small {
  padding: 6px 24px;
}
.btn-01.btn-arrow {
  justify-content: space-between;
  min-width: 200px;
  mask-repeat: no-repeat;
  mask-composite: exclude;
}
.btn-01.btn-arrow:not(.icon-right) {
  padding-left: 16px;
  mask-image: url("../images/triangle-mask-left.svg"), linear-gradient(#fff 0 0);
  mask-position: -1px;
}
.btn-01.btn-arrow.icon-right {
  padding-right: 16px;
  mask-image: url("../images/triangle-mask-right.svg"), linear-gradient(#fff 0 0);
  mask-position: calc(100% + 1px);
}
.btn-01 .btn--icon {
  width: 18px;
  height: 18px;
}
.btn-01 .btn--icon.icon--chevron-bid {
  width: 24px;
  height: 24px;
}
.btn-01.btn-style--02-01 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF !important;
}
.btn-01.btn-style--02-01:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2F1432 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-01 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #FFF !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-01:not(:disabled):hover {
    color: #FFF !important;
  }
  .btn-01.btn-style--02-01:not(:disabled):hover:before {
    background: #46274A !important;
  }
  .btn-01.btn-style--02-01:not(:disabled):hover .btn--icon svg {
    fill: #FFF !important;
  }
}
.btn-01.btn-style--02-01:not(:disabled):focus {
  color: #FFF !important;
}
.btn-01.btn-style--02-01:not(:disabled):focus:before {
  background: #46274A !important;
}
.btn-01.btn-style--02-01:not(:disabled):focus .btn--icon svg {
  fill: #FFF !important;
}
.btn-01.btn-style--02-01-alt {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF !important;
}
.btn-01.btn-style--02-01-alt:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #46274A !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-01-alt .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #FFF !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-01-alt:not(:disabled):hover {
    color: #FFF !important;
  }
  .btn-01.btn-style--02-01-alt:not(:disabled):hover:before {
    background: #2F1432 !important;
  }
  .btn-01.btn-style--02-01-alt:not(:disabled):hover .btn--icon svg {
    fill: #FFF !important;
  }
}
.btn-01.btn-style--02-01-alt:not(:disabled):focus {
  color: #FFF !important;
}
.btn-01.btn-style--02-01-alt:not(:disabled):focus:before {
  background: #2F1432 !important;
}
.btn-01.btn-style--02-01-alt:not(:disabled):focus .btn--icon svg {
  fill: #FFF !important;
}
.btn-01.btn-style--02-02 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF !important;
}
.btn-01.btn-style--02-02:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #314D41 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-02 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #FFF !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-02:not(:disabled):hover {
    color: #FFF !important;
  }
  .btn-01.btn-style--02-02:not(:disabled):hover:before {
    background: #446055 !important;
  }
  .btn-01.btn-style--02-02:not(:disabled):hover .btn--icon svg {
    fill: #FFF !important;
  }
}
.btn-01.btn-style--02-02:not(:disabled):focus {
  color: #FFF !important;
}
.btn-01.btn-style--02-02:not(:disabled):focus:before {
  background: #446055 !important;
}
.btn-01.btn-style--02-02:not(:disabled):focus .btn--icon svg {
  fill: #FFF !important;
}
.btn-01.btn-style--02-02-alt {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF !important;
}
.btn-01.btn-style--02-02-alt:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #446055 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-02-alt .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #FFF !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-02-alt:not(:disabled):hover {
    color: #FFF !important;
  }
  .btn-01.btn-style--02-02-alt:not(:disabled):hover:before {
    background: #314D41 !important;
  }
  .btn-01.btn-style--02-02-alt:not(:disabled):hover .btn--icon svg {
    fill: #FFF !important;
  }
}
.btn-01.btn-style--02-02-alt:not(:disabled):focus {
  color: #FFF !important;
}
.btn-01.btn-style--02-02-alt:not(:disabled):focus:before {
  background: #314D41 !important;
}
.btn-01.btn-style--02-02-alt:not(:disabled):focus .btn--icon svg {
  fill: #FFF !important;
}
.btn-01.btn-style--02-03 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF !important;
}
.btn-01.btn-style--02-03:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #CE6447 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-03 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #FFF !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-03:not(:disabled):hover {
    color: #FFF !important;
  }
  .btn-01.btn-style--02-03:not(:disabled):hover:before {
    background: #E67E62 !important;
  }
  .btn-01.btn-style--02-03:not(:disabled):hover .btn--icon svg {
    fill: #FFF !important;
  }
}
.btn-01.btn-style--02-03:not(:disabled):focus {
  color: #FFF !important;
}
.btn-01.btn-style--02-03:not(:disabled):focus:before {
  background: #E67E62 !important;
}
.btn-01.btn-style--02-03:not(:disabled):focus .btn--icon svg {
  fill: #FFF !important;
}
.btn-01.btn-style--02-03-alt {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF !important;
}
.btn-01.btn-style--02-03-alt:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #E67E62 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-03-alt .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #FFF !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-03-alt:not(:disabled):hover {
    color: #FFF !important;
  }
  .btn-01.btn-style--02-03-alt:not(:disabled):hover:before {
    background: #CE6447 !important;
  }
  .btn-01.btn-style--02-03-alt:not(:disabled):hover .btn--icon svg {
    fill: #FFF !important;
  }
}
.btn-01.btn-style--02-03-alt:not(:disabled):focus {
  color: #FFF !important;
}
.btn-01.btn-style--02-03-alt:not(:disabled):focus:before {
  background: #CE6447 !important;
}
.btn-01.btn-style--02-03-alt:not(:disabled):focus .btn--icon svg {
  fill: #FFF !important;
}
.btn-01.btn-style--02-04 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-04:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F2C359 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-04 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #1C1C1C !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-04:not(:disabled):hover {
    color: #1C1C1C !important;
  }
  .btn-01.btn-style--02-04:not(:disabled):hover:before {
    background: #FFDC8E !important;
  }
  .btn-01.btn-style--02-04:not(:disabled):hover .btn--icon svg {
    fill: #1C1C1C !important;
  }
}
.btn-01.btn-style--02-04:not(:disabled):focus {
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-04:not(:disabled):focus:before {
  background: #FFDC8E !important;
}
.btn-01.btn-style--02-04:not(:disabled):focus .btn--icon svg {
  fill: #1C1C1C !important;
}
.btn-01.btn-style--02-04-alt {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-04-alt:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFDC8E !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-04-alt .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #1C1C1C !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-04-alt:not(:disabled):hover {
    color: #1C1C1C !important;
  }
  .btn-01.btn-style--02-04-alt:not(:disabled):hover:before {
    background: #F2C359 !important;
  }
  .btn-01.btn-style--02-04-alt:not(:disabled):hover .btn--icon svg {
    fill: #1C1C1C !important;
  }
}
.btn-01.btn-style--02-04-alt:not(:disabled):focus {
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-04-alt:not(:disabled):focus:before {
  background: #F2C359 !important;
}
.btn-01.btn-style--02-04-alt:not(:disabled):focus .btn--icon svg {
  fill: #1C1C1C !important;
}
.btn-01.btn-style--02-05 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-05:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #AFCFD0 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-05 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #1C1C1C !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-05:not(:disabled):hover {
    color: #1C1C1C !important;
  }
  .btn-01.btn-style--02-05:not(:disabled):hover:before {
    background: #D6EBEB !important;
  }
  .btn-01.btn-style--02-05:not(:disabled):hover .btn--icon svg {
    fill: #1C1C1C !important;
  }
}
.btn-01.btn-style--02-05:not(:disabled):focus {
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-05:not(:disabled):focus:before {
  background: #D6EBEB !important;
}
.btn-01.btn-style--02-05:not(:disabled):focus .btn--icon svg {
  fill: #1C1C1C !important;
}
.btn-01.btn-style--02-05-alt {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-05-alt:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #D6EBEB !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-05-alt .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #1C1C1C !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-05-alt:not(:disabled):hover {
    color: #1C1C1C !important;
  }
  .btn-01.btn-style--02-05-alt:not(:disabled):hover:before {
    background: #AFCFD0 !important;
  }
  .btn-01.btn-style--02-05-alt:not(:disabled):hover .btn--icon svg {
    fill: #1C1C1C !important;
  }
}
.btn-01.btn-style--02-05-alt:not(:disabled):focus {
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-05-alt:not(:disabled):focus:before {
  background: #AFCFD0 !important;
}
.btn-01.btn-style--02-05-alt:not(:disabled):focus .btn--icon svg {
  fill: #1C1C1C !important;
}
.btn-01.btn-style--02-06 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF !important;
}
.btn-01.btn-style--02-06:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3779B9 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-06 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #FFF !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-06:not(:disabled):hover {
    color: #FFF !important;
  }
  .btn-01.btn-style--02-06:not(:disabled):hover:before {
    background: #4085CB !important;
  }
  .btn-01.btn-style--02-06:not(:disabled):hover .btn--icon svg {
    fill: #FFF !important;
  }
}
.btn-01.btn-style--02-06:not(:disabled):focus {
  color: #FFF !important;
}
.btn-01.btn-style--02-06:not(:disabled):focus:before {
  background: #4085CB !important;
}
.btn-01.btn-style--02-06:not(:disabled):focus .btn--icon svg {
  fill: #FFF !important;
}
.btn-01.btn-style--02-06-alt {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF !important;
}
.btn-01.btn-style--02-06-alt:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #4085CB !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-06-alt .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #FFF !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-06-alt:not(:disabled):hover {
    color: #FFF !important;
  }
  .btn-01.btn-style--02-06-alt:not(:disabled):hover:before {
    background: #3779B9 !important;
  }
  .btn-01.btn-style--02-06-alt:not(:disabled):hover .btn--icon svg {
    fill: #FFF !important;
  }
}
.btn-01.btn-style--02-06-alt:not(:disabled):focus {
  color: #FFF !important;
}
.btn-01.btn-style--02-06-alt:not(:disabled):focus:before {
  background: #3779B9 !important;
}
.btn-01.btn-style--02-06-alt:not(:disabled):focus .btn--icon svg {
  fill: #FFF !important;
}
.btn-01.btn-style--02-07 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF !important;
}
.btn-01.btn-style--02-07:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #822F35 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-07 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #FFF !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-07:not(:disabled):hover {
    color: #FFF !important;
  }
  .btn-01.btn-style--02-07:not(:disabled):hover:before {
    background: #964046 !important;
  }
  .btn-01.btn-style--02-07:not(:disabled):hover .btn--icon svg {
    fill: #FFF !important;
  }
}
.btn-01.btn-style--02-07:not(:disabled):focus {
  color: #FFF !important;
}
.btn-01.btn-style--02-07:not(:disabled):focus:before {
  background: #964046 !important;
}
.btn-01.btn-style--02-07:not(:disabled):focus .btn--icon svg {
  fill: #FFF !important;
}
.btn-01.btn-style--02-07-alt {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF !important;
}
.btn-01.btn-style--02-07-alt:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #964046 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-07-alt .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #FFF !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-07-alt:not(:disabled):hover {
    color: #FFF !important;
  }
  .btn-01.btn-style--02-07-alt:not(:disabled):hover:before {
    background: #822F35 !important;
  }
  .btn-01.btn-style--02-07-alt:not(:disabled):hover .btn--icon svg {
    fill: #FFF !important;
  }
}
.btn-01.btn-style--02-07-alt:not(:disabled):focus {
  color: #FFF !important;
}
.btn-01.btn-style--02-07-alt:not(:disabled):focus:before {
  background: #822F35 !important;
}
.btn-01.btn-style--02-07-alt:not(:disabled):focus .btn--icon svg {
  fill: #FFF !important;
}
.btn-01.btn-style--02-08 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-08:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F2CCC9 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-08 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #1C1C1C !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-08:not(:disabled):hover {
    color: #1C1C1C !important;
  }
  .btn-01.btn-style--02-08:not(:disabled):hover:before {
    background: #F8DFDD !important;
  }
  .btn-01.btn-style--02-08:not(:disabled):hover .btn--icon svg {
    fill: #1C1C1C !important;
  }
}
.btn-01.btn-style--02-08:not(:disabled):focus {
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-08:not(:disabled):focus:before {
  background: #F8DFDD !important;
}
.btn-01.btn-style--02-08:not(:disabled):focus .btn--icon svg {
  fill: #1C1C1C !important;
}
.btn-01.btn-style--02-08-alt {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-08-alt:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F8DFDD !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--02-08-alt .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #1C1C1C !important;
}
@media (hover: hover) {
  .btn-01.btn-style--02-08-alt:not(:disabled):hover {
    color: #1C1C1C !important;
  }
  .btn-01.btn-style--02-08-alt:not(:disabled):hover:before {
    background: #F2CCC9 !important;
  }
  .btn-01.btn-style--02-08-alt:not(:disabled):hover .btn--icon svg {
    fill: #1C1C1C !important;
  }
}
.btn-01.btn-style--02-08-alt:not(:disabled):focus {
  color: #1C1C1C !important;
}
.btn-01.btn-style--02-08-alt:not(:disabled):focus:before {
  background: #F2CCC9 !important;
}
.btn-01.btn-style--02-08-alt:not(:disabled):focus .btn--icon svg {
  fill: #1C1C1C !important;
}
.btn-01.btn-style--03-01 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #00382E !important;
}
.btn-01.btn-style--03-01:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #19D41C !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--03-01 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #00382E !important;
}
@media (hover: hover) {
  .btn-01.btn-style--03-01:not(:disabled):hover {
    color: #00382E !important;
  }
  .btn-01.btn-style--03-01:not(:disabled):hover:before {
    background: #16b918 !important;
  }
  .btn-01.btn-style--03-01:not(:disabled):hover .btn--icon svg {
    fill: #00382E !important;
  }
}
.btn-01.btn-style--03-01:not(:disabled):focus {
  color: #00382E !important;
}
.btn-01.btn-style--03-01:not(:disabled):focus:before {
  background: #16b918 !important;
}
.btn-01.btn-style--03-01:not(:disabled):focus .btn--icon svg {
  fill: #00382E !important;
}
.btn-01.btn-style--03-02 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #19D41C !important;
}
.btn-01.btn-style--03-02:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00382E !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--03-02 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #19D41C !important;
}
@media (hover: hover) {
  .btn-01.btn-style--03-02:not(:disabled):hover {
    color: #19D41C !important;
  }
  .btn-01.btn-style--03-02:not(:disabled):hover:before {
    background: #001915 !important;
  }
  .btn-01.btn-style--03-02:not(:disabled):hover .btn--icon svg {
    fill: #19D41C !important;
  }
}
.btn-01.btn-style--03-02:not(:disabled):focus {
  color: #19D41C !important;
}
.btn-01.btn-style--03-02:not(:disabled):focus:before {
  background: #001915 !important;
}
.btn-01.btn-style--03-02:not(:disabled):focus .btn--icon svg {
  fill: #19D41C !important;
}
.btn-01.btn-style--03-03 {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #00382E !important;
}
.btn-01.btn-style--03-03:before {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F0F6E6 !important;
  transform: translateY(-1px);
}
.btn-01.btn-style--03-03 .btn--icon svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #00382E !important;
}
@media (hover: hover) {
  .btn-01.btn-style--03-03:not(:disabled):hover {
    color: #00382E !important;
  }
  .btn-01.btn-style--03-03:not(:disabled):hover:before {
    background: #e3eed0 !important;
  }
  .btn-01.btn-style--03-03:not(:disabled):hover .btn--icon svg {
    fill: #00382E !important;
  }
}
.btn-01.btn-style--03-03:not(:disabled):focus {
  color: #00382E !important;
}
.btn-01.btn-style--03-03:not(:disabled):focus:before {
  background: #e3eed0 !important;
}
.btn-01.btn-style--03-03:not(:disabled):focus .btn--icon svg {
  fill: #00382E !important;
}

/*
 * Btn (Circle)
 */
.btn-circle {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 2px solid;
  border-radius: 100%;
}
.btn-circle .btn--icon svg {
  width: 18px;
  height: 18px;
}
.btn-circle.btn-style--02-01 {
  transition-property: border, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  border-color: #2F1432 !important;
}
.btn-circle.btn-style--02-01 svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #2F1432 !important;
}
@media (hover: hover) {
  .btn-circle.btn-style--02-01:not(.disabled):not(:disabled):hover, .btn-circle.btn-style--02-01:not(.disabled):not(:disabled):focus {
    border-color: #46274A !important;
  }
  .btn-circle.btn-style--02-01:not(.disabled):not(:disabled):hover svg, .btn-circle.btn-style--02-01:not(.disabled):not(:disabled):focus svg {
    fill: #46274A !important;
  }
}
.btn-circle.btn-style--02-02 {
  transition-property: border, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  border-color: #314D41 !important;
}
.btn-circle.btn-style--02-02 svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #314D41 !important;
}
@media (hover: hover) {
  .btn-circle.btn-style--02-02:not(.disabled):not(:disabled):hover, .btn-circle.btn-style--02-02:not(.disabled):not(:disabled):focus {
    border-color: #446055 !important;
  }
  .btn-circle.btn-style--02-02:not(.disabled):not(:disabled):hover svg, .btn-circle.btn-style--02-02:not(.disabled):not(:disabled):focus svg {
    fill: #446055 !important;
  }
}
.btn-circle.btn-style--02-03 {
  transition-property: border, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  border-color: #CE6447 !important;
}
.btn-circle.btn-style--02-03 svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #CE6447 !important;
}
@media (hover: hover) {
  .btn-circle.btn-style--02-03:not(.disabled):not(:disabled):hover, .btn-circle.btn-style--02-03:not(.disabled):not(:disabled):focus {
    border-color: #E67E62 !important;
  }
  .btn-circle.btn-style--02-03:not(.disabled):not(:disabled):hover svg, .btn-circle.btn-style--02-03:not(.disabled):not(:disabled):focus svg {
    fill: #E67E62 !important;
  }
}
.btn-circle.btn-style--02-04 {
  transition-property: border, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  border-color: #F2C359 !important;
}
.btn-circle.btn-style--02-04 svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #F2C359 !important;
}
@media (hover: hover) {
  .btn-circle.btn-style--02-04:not(.disabled):not(:disabled):hover, .btn-circle.btn-style--02-04:not(.disabled):not(:disabled):focus {
    border-color: #FFDC8E !important;
  }
  .btn-circle.btn-style--02-04:not(.disabled):not(:disabled):hover svg, .btn-circle.btn-style--02-04:not(.disabled):not(:disabled):focus svg {
    fill: #FFDC8E !important;
  }
}
.btn-circle.btn-style--02-05 {
  transition-property: border, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  border-color: #AFCFD0 !important;
}
.btn-circle.btn-style--02-05 svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #AFCFD0 !important;
}
@media (hover: hover) {
  .btn-circle.btn-style--02-05:not(.disabled):not(:disabled):hover, .btn-circle.btn-style--02-05:not(.disabled):not(:disabled):focus {
    border-color: #D6EBEB !important;
  }
  .btn-circle.btn-style--02-05:not(.disabled):not(:disabled):hover svg, .btn-circle.btn-style--02-05:not(.disabled):not(:disabled):focus svg {
    fill: #D6EBEB !important;
  }
}
.btn-circle.btn-style--02-06 {
  transition-property: border, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  border-color: #3779B9 !important;
}
.btn-circle.btn-style--02-06 svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #3779B9 !important;
}
@media (hover: hover) {
  .btn-circle.btn-style--02-06:not(.disabled):not(:disabled):hover, .btn-circle.btn-style--02-06:not(.disabled):not(:disabled):focus {
    border-color: #4085CB !important;
  }
  .btn-circle.btn-style--02-06:not(.disabled):not(:disabled):hover svg, .btn-circle.btn-style--02-06:not(.disabled):not(:disabled):focus svg {
    fill: #4085CB !important;
  }
}
.btn-circle.btn-style--02-07 {
  transition-property: border, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  border-color: #822F35 !important;
}
.btn-circle.btn-style--02-07 svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #822F35 !important;
}
@media (hover: hover) {
  .btn-circle.btn-style--02-07:not(.disabled):not(:disabled):hover, .btn-circle.btn-style--02-07:not(.disabled):not(:disabled):focus {
    border-color: #964046 !important;
  }
  .btn-circle.btn-style--02-07:not(.disabled):not(:disabled):hover svg, .btn-circle.btn-style--02-07:not(.disabled):not(:disabled):focus svg {
    fill: #964046 !important;
  }
}
.btn-circle.btn-style--02-08 {
  transition-property: border, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  border-color: #F2CCC9 !important;
}
.btn-circle.btn-style--02-08 svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #F2CCC9 !important;
}
@media (hover: hover) {
  .btn-circle.btn-style--02-08:not(.disabled):not(:disabled):hover, .btn-circle.btn-style--02-08:not(.disabled):not(:disabled):focus {
    border-color: #F8DFDD !important;
  }
  .btn-circle.btn-style--02-08:not(.disabled):not(:disabled):hover svg, .btn-circle.btn-style--02-08:not(.disabled):not(:disabled):focus svg {
    fill: #F8DFDD !important;
  }
}
.btn-circle.btn-style--01-01--02-07 {
  transition-property: border, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  border-color: #1C1C1C !important;
}
.btn-circle.btn-style--01-01--02-07 svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  fill: #1C1C1C !important;
}
@media (hover: hover) {
  .btn-circle.btn-style--01-01--02-07:not(.disabled):not(:disabled):hover, .btn-circle.btn-style--01-01--02-07:not(.disabled):not(:disabled):focus {
    border-color: #822F35 !important;
  }
  .btn-circle.btn-style--01-01--02-07:not(.disabled):not(:disabled):hover svg, .btn-circle.btn-style--01-01--02-07:not(.disabled):not(:disabled):focus svg {
    fill: #822F35 !important;
  }
}

/*
 * Btn (Has Icon)
 */
.btn--has-icon {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}
.btn--has-icon:not(.icon-right) .btn--icon {
  margin-right: 12px;
}
@media screen and (max-width: 768px) {
  .btn--has-icon:not(.icon-right) .btn--icon {
    margin-right: 9px;
  }
}
.btn--has-icon:not(.icon-right) .btn--icon.icon--chevron-bid {
  transform: scale(-1);
}
.btn--has-icon.icon-right .btn--icon {
  order: 2;
  margin-left: 12px;
}
@media screen and (max-width: 768px) {
  .btn--has-icon.icon-right .btn--icon {
    margin-left: 9px;
  }
}
.btn--has-icon .btn--icon.icon-flip {
  transform: scale(-1);
}
.btn--has-icon .btn--icon.icon-90deg {
  transform: rotate(90deg);
}
.btn--has-icon > .icon--chevron-bid {
  background: currentColor;
  mask-image: url("../icons/chevron-bid.svg");
  mask-repeat: no-repeat;
}

.card {
  position: relative;
  z-index: 1;
}
.card--link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.card--thumb {
  position: relative;
  z-index: 1;
}
.card--pic {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  overflow: hidden;
}
.card--pic:before {
  content: "";
  display: block;
  padding-top: calc((3 / 4) * 100%);
  width: 100%;
  height: 0;
}
.card--pic img,
.card--pic video,
.card--pic iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.card--pic img {
  object-fit: cover;
}
.card--pic iframe {
  width: 100%;
}
.card--img {
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}
.card--status {
  position: absolute;
  z-index: 2;
  top: 12px;
  left: 0;
  padding: 6px 12px;
  line-height: 1;
  color: #FFF;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.88);
  border-radius: 0 3px 3px 0;
}
.card--content {
  padding-top: 18px;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .card--content {
    padding-top: 12px;
  }
}
.card--date {
  padding-bottom: 12px;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .card--date {
    padding-bottom: 6px;
    font-size: 14px;
  }
}
.card--heading {
  line-height: 1.2;
}
.card--excerpt {
  padding-top: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card--view-more {
  padding-top: 12px;
}
@media (hover: hover) {
  .card:not(:disabled):hover .card--img {
    transform: scale(1.1);
  }
}
.card:not(:disabled):focus .card--img {
  transform: scale(1.1);
}

/*
 * Project
 */
.card-project--heading {
  padding-top: 24px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .card-project--heading {
    padding-top: 18px;
  }
}

/*
 * Event (Full)
 */
.card-event-full {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .card-event-full {
    flex-direction: column-reverse;
  }
}
.card-event-full--content {
  flex: 1 1 auto;
  align-self: center;
  padding: 32px;
  max-width: 680px;
}
@media screen and (max-width: 768px) {
  .card-event-full--content {
    padding: 24px;
  }
}
.card-event-full--excerpt {
  padding-top: 24px;
}
@media screen and (max-width: 768px) {
  .card-event-full--excerpt {
    padding-top: 12px;
  }
}
.card-event-full--thumb {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex-shrink: 0;
}
@media screen and (min-width: 769px) {
  .card-event-full--thumb {
    width: 25%;
    min-width: 312px;
  }
}
.card-event-full--pic {
  flex: 1 1 auto;
}

.g-downloads {
  margin-top: -24px;
}
@media screen and (max-width: 768px) {
  .g-downloads {
    margin-top: -12px;
  }
}
@media screen and (max-width: 768px) {
  .g-downloads--heading {
    padding-bottom: 24px !important;
  }
}
.g-downloads--item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  margin-top: 24px;
  padding: 24px;
  text-align: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .g-downloads--item {
    margin-top: 12px;
  }
}
.g-downloads--item--link {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.4;
  font-size: 16px;
}
.g-downloads--item--link svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  width: 48px;
  height: 48px;
  fill: currentColor;
  transform: rotate(90deg);
}
.g-downloads--btn-more {
  margin: 24px 0 0 auto;
}

.event-accordion {
  padding: 32px;
  border: 1px solid #1C1C1C;
}
@media screen and (max-width: 768px) {
  .event-accordion {
    padding: 24px;
  }
}
.event-accordion--item:not(:last-child) {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #1C1C1C;
}
.event-accordion--content, .event-accordion--text, .event-accordion--btns-wrapper {
  padding-top: 24px;
}
.event-accordion--content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 48px;
}
@media screen and (max-width: 768px) {
  .event-accordion--content {
    flex-direction: column;
  }
}
@media screen and (min-width: 769px) {
  .event-accordion--content.has-image > .event-accordion--lhc {
    width: calc(70% - 48px);
  }
}
.event-accordion--lhc, .event-accordion--btns-wrapper {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .event-accordion--rhc {
    width: 30%;
  }
}
@media screen and (max-width: 768px) {
  .event-accordion--rhc {
    order: -1;
    padding-bottom: 24px;
    width: 100%;
  }
}
.event-accordion--heading {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
  width: 100%;
  font-weight: 700;
}
.event-accordion--heading svg {
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  width: 18px;
  height: 18px;
  fill: currentColor;
  transform: rotate(90deg);
}
.event-accordion--heading.is-active svg {
  transform: rotate(-90deg);
}
.event-accordion--datetime, .event-accordion--address {
  font-weight: 500;
}
.event-accordion--address {
  padding-top: 9px;
}
@media screen and (max-width: 768px) {
  .event-accordion--text {
    padding-top: 12px;
  }
}
.event-accordion--pic {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
}
.event-accordion--pic:before {
  content: "";
  display: block;
  padding-top: calc((1 / 1) * 100%);
  width: 100%;
  height: 0;
}
.event-accordion--pic img,
.event-accordion--pic video,
.event-accordion--pic iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.event-accordion--pic img {
  object-fit: cover;
}
.event-accordion--pic iframe {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .event-accordion--pic {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
  }
  .event-accordion--pic:before {
    content: "";
    display: block;
    padding-top: calc((9 / 16) * 100%);
    width: 100%;
    height: 0;
  }
  .event-accordion--pic img,
  .event-accordion--pic video,
  .event-accordion--pic iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    height: 100%;
  }
  .event-accordion--pic img {
    object-fit: cover;
  }
  .event-accordion--pic iframe {
    width: 100%;
  }
}

.event-feat {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row !important;
  justify-content: center;
  overflow: hidden;
}
.event-feat:before {
  content: "";
  display: block;
  padding-top: calc((320 / 1920) * 100%);
}
@media screen and (min-width: 769px) {
  .event-feat--text-wrapper {
    width: 50%;
    max-width: 680px;
  }
}
@media screen and (min-width: 769px) {
  .event-feat--text {
    width: 100%;
    max-width: 460px;
  }
}
@media screen and (min-width: 769px) {
  .event-feat--btn {
    width: 50%;
  }
}
.event-feat--thumb {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
}
.event-feat--thumb:before {
  content: "";
  display: block;
  padding-top: calc((3 / 4) * 100%);
  width: 100%;
  height: 0;
}
.event-feat--thumb img,
.event-feat--thumb video,
.event-feat--thumb iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.event-feat--thumb img {
  object-fit: cover;
}
.event-feat--thumb iframe {
  width: 100%;
}
.event-feat--pic {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 40%;
  right: 0;
  bottom: 0;
  transform: skewX(-45deg);
  transform-origin: bottom;
  overflow: hidden;
}
.event-feat--pic.reverse {
  left: 50%;
  transform: skewX(45deg);
  transform-origin: top;
}
.event-feat--pic.reverse > .event-feat--img {
  transform: skewX(-45deg);
  transform-origin: top;
}
.event-feat--img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  transform: skewX(45deg);
  transform-origin: bottom;
}

@media screen and (min-width: 769px) {
  .event-locations--slide {
    padding: 32px;
  }
}
.event-locations--content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
@media screen and (min-width: 769px) {
  .event-locations--content {
    flex-wrap: wrap;
    column-gap: 48px;
  }
}
@media screen and (max-width: 768px) {
  .event-locations--content {
    flex-direction: column;
  }
}
@media screen and (min-width: 769px) {
  .event-locations--content.has-image > .event-locations--rhc {
    width: calc(70% - 48px);
  }
}
@media screen and (min-width: 769px) {
  .event-locations--lhc {
    width: 30%;
  }
}
@media screen and (min-width: 769px) {
  .event-locations--rhc {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .event-locations--rhc {
    padding: 24px;
  }
}
.event-locations--pic {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
}
.event-locations--pic:before {
  content: "";
  display: block;
  padding-top: calc((1 / 1) * 100%);
  width: 100%;
  height: 0;
}
.event-locations--pic img,
.event-locations--pic video,
.event-locations--pic iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.event-locations--pic img {
  object-fit: cover;
}
.event-locations--pic iframe {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .event-locations--pic {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
  }
  .event-locations--pic:before {
    content: "";
    display: block;
    padding-top: calc((9 / 16) * 100%);
    width: 100%;
    height: 0;
  }
  .event-locations--pic img,
  .event-locations--pic video,
  .event-locations--pic iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    height: 100%;
  }
  .event-locations--pic img {
    object-fit: cover;
  }
  .event-locations--pic iframe {
    width: 100%;
  }
}
.event-locations--heading {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
  font-weight: 700;
  text-align: center;
}
.event-locations--arrow {
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 768px) {
  .event-locations--arrow {
    width: 18px;
    height: 18px;
  }
}
.event-locations--arrow:first-child svg {
  transform: rotate(180deg);
}
.event-locations--arrow:disabled {
  opacity: 0.24;
  cursor: default;
}
.event-locations--arrow svg {
  fill: currentColor;
}
.event-locations--text {
  padding-top: 48px;
  font-style: italic;
}
@media screen and (max-width: 768px) {
  .event-locations--text {
    padding-top: 18px;
  }
}
.event-locations--address, .event-locations--meta {
  padding-top: 24px;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .event-locations--address, .event-locations--meta {
    padding-top: 18px;
  }
}
.event-locations--meta {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
@media screen and (min-width: 769px) {
  .event-locations--meta {
    justify-content: space-between;
    align-items: center;
    column-gap: 48px;
    margin-top: auto;
  }
}
@media screen and (max-width: 768px) {
  .event-locations--meta {
    flex-direction: column;
    row-gap: 18px;
    padding-top: 12px;
  }
}

.g-fastlinks {
  margin-top: -48px;
}
@media screen and (max-width: 768px) {
  .g-fastlinks {
    margin-top: -24px;
  }
}
.g-fastlinks--item {
  position: relative;
  z-index: 1;
  margin-top: 48px;
}
@media screen and (max-width: 768px) {
  .g-fastlinks--item {
    margin-top: 24px;
  }
}
.g-fastlinks--item--link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.g-fastlinks--item--pic {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  overflow: hidden;
}
.g-fastlinks--item--pic:before {
  content: "";
  display: block;
  padding-top: calc((3 / 4) * 100%);
  width: 100%;
  height: 0;
}
.g-fastlinks--item--pic img,
.g-fastlinks--item--pic video,
.g-fastlinks--item--pic iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.g-fastlinks--item--pic img {
  object-fit: cover;
}
.g-fastlinks--item--pic iframe {
  width: 100%;
}
.g-fastlinks--item--img {
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}
.g-fastlinks--item--heading {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  padding-top: 24px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .g-fastlinks--item--heading {
    padding-top: 18px;
  }
}
.g-fastlinks--item--heading svg {
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  width: 32px;
  height: 32px;
  transform: translateY(-2px);
}
@media (hover: hover) {
  .g-fastlinks--item:not(:disabled):hover .g-fastlinks--item--img {
    transform: scale(1.1);
  }
  .g-fastlinks--item:not(:disabled):hover .g-fastlinks--item--heading svg {
    transform: translate(6px, -2px);
  }
}
.g-fastlinks--item:not(:disabled):focus .g-fastlinks--item--img {
  transform: scale(1.1);
}
.g-fastlinks--item:not(:disabled):focus .g-fastlinks--item--heading svg {
  transform: translate(6px, -2px);
}

/*
 * Gallery Carousel
 */
.gallery-carousel--item {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  display: block;
  width: 100%;
  overflow: hidden;
}
.gallery-carousel--item:before {
  content: "";
  display: block;
  padding-top: calc((3 / 4) * 100%);
  width: 100%;
  height: 0;
}
.gallery-carousel--item img,
.gallery-carousel--item video,
.gallery-carousel--item iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.gallery-carousel--item img {
  object-fit: cover;
}
.gallery-carousel--item iframe {
  width: 100%;
}
@media (hover: hover) {
  .gallery-carousel--item:not(:disabled):hover .gallery-carousel--img {
    transform: scale(1.1);
  }
}
.gallery-carousel--item:not(:disabled):focus .gallery-carousel--img {
  transform: scale(1.1);
}
.gallery-carousel--img {
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}
.gallery-carousel.style-light .splide__pagination__page {
  background: rgba(255, 255, 255, 0.24);
}
.gallery-carousel.style-light .splide__pagination__page.is-active {
  background: #FFF;
}
.gallery-carousel.style-light .gallery-carousel-slider--arrow-prev svg,
.gallery-carousel.style-light .gallery-carousel-slider--arrow-next svg {
  fill: #FFF;
}

/*
 * Slider
 */
.gallery-carousel-slider {
  position: relative;
  z-index: 1;
  width: 100%;
}
.gallery-carousel-slider .splide__track {
  overflow: visible;
}
.gallery-carousel-slider .splide__slide {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}
.gallery-carousel-slider .splide__slide:not(.is-visible) {
  opacity: 0.24;
}
.gallery-carousel-slider .splide__pagination {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  position: absolute;
  z-index: 1;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  left: 50%;
  bottom: -54px;
  transform: translateX(-50%);
}
@media screen and (max-width: 1220px) {
  .gallery-carousel-slider .splide__pagination {
    bottom: -30px;
  }
}
.gallery-carousel-slider .splide__pagination__page {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #FFF;
}
.gallery-carousel-slider .splide__pagination__page.is-active {
  background: #1C1C1C;
}
.gallery-carousel-slider--arrow-prev, .gallery-carousel-slider--arrow-next {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 48px;
  height: 48px;
  transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .gallery-carousel-slider--arrow-prev, .gallery-carousel-slider--arrow-next {
    width: 24px;
    height: 24px;
  }
}
.gallery-carousel-slider--arrow-prev svg, .gallery-carousel-slider--arrow-next svg {
  display: block;
  width: 48px;
  height: 48px;
  fill: #1C1C1C;
}
@media screen and (max-width: 768px) {
  .gallery-carousel-slider--arrow-prev svg, .gallery-carousel-slider--arrow-next svg {
    width: 24px;
    height: 24px;
  }
}
.gallery-carousel-slider--arrow-prev:disabled, .gallery-carousel-slider--arrow-next:disabled {
  opacity: 0.24;
}
.gallery-carousel-slider--arrow-prev {
  left: 48px;
}
@media screen and (max-width: 1220px) {
  .gallery-carousel-slider--arrow-prev {
    left: 12px;
  }
}
.gallery-carousel-slider--arrow-prev svg {
  transform: rotate(180deg);
}
.gallery-carousel-slider--arrow-next {
  right: 48px;
}
@media screen and (max-width: 1220px) {
  .gallery-carousel-slider--arrow-next {
    right: 12px;
  }
}

/*
 * Modal
 */
.gallery-modal,
.gallery-modal--splide {
  height: 100%;
}
.gallery-modal .splide__track,
.gallery-modal .splide__list,
.gallery-modal .splide__slide,
.gallery-modal--splide .splide__track,
.gallery-modal--splide .splide__list,
.gallery-modal--splide .splide__slide {
  height: 100%;
}

.gallery-modal--img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100% !important;
  object-fit: contain;
}
@media screen and (min-width: 769px) {
  .gallery-modal--arrow-prev, .gallery-modal--arrow-next {
    position: fixed;
    z-index: 1;
    top: 24px;
  }
}
@media screen and (min-width: 769px) {
  .gallery-modal--arrow-prev {
    right: 144px;
  }
}
.gallery-modal--arrow-prev svg {
  transform: rotate(180deg);
}
@media screen and (min-width: 769px) {
  .gallery-modal--arrow-next {
    right: 24px;
  }
}

@media screen and (min-width: 769px) {
  .g-google-map {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row !important;
  }
  .g-google-map:before {
    content: "";
    display: block;
    padding-top: calc((1 / 2) * 100%);
  }
}
@media screen and (max-width: 768px) {
  .g-google-map {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row !important;
  }
  .g-google-map:before {
    content: "";
    display: block;
    padding-top: calc((4 / 3) * 100%);
  }
}
.g-google-map--marker--label {
  position: relative;
  z-index: 1;
  left: 0;
  bottom: 6px;
  font-weight: 700;
}

.grid-icons {
  margin-top: -48px;
}
@media screen and (max-width: 768px) {
  .grid-icons > .g-grid > .g-col {
    width: 50%;
  }
}
.grid-icons--item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  position: relative;
  z-index: 1;
  align-items: center;
  row-gap: 24px;
  margin-top: 48px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .grid-icons--item {
    row-gap: 18px;
    margin-top: 32px;
  }
}
@media (hover: hover) {
  .grid-icons--item:not(:disabled):hover .grid-icons--icon span:last-child {
    opacity: 1;
  }
}
.grid-icons--item:not(:disabled):focus .grid-icons--icon span:last-child {
  opacity: 1;
}
.grid-icons--link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.grid-icons--icon {
  position: relative;
  z-index: 1;
}
.grid-icons--icon span {
  display: block;
  width: 96px;
  height: 96px;
}
@media screen and (max-width: 768px) {
  .grid-icons--icon span {
    width: 48px;
    height: 48px;
  }
}
.grid-icons--icon span:last-child {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
}
.grid-icons--icon span svg {
  display: block;
  width: 96px;
  height: 96px;
  object-fit: contain;
  object-position: bottom;
}
@media screen and (max-width: 768px) {
  .grid-icons--icon span svg {
    width: 48px;
    height: 48px;
  }
}
.grid-icons--text {
  line-height: 1.2;
  font-weight: 500;
}

.g-grid {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-wrap: wrap;
}
.g-grid .g-col {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}
.g-grid .g-col.self-start {
  align-self: flex-start;
}
@media screen and (min-width: 769px) {
  .g-grid .w-1-5 {
    width: calc((1 / 5) * 100%);
  }
  .g-grid .w-2-5 {
    width: calc((2 / 5) * 100%);
  }
  .g-grid .w-3-5 {
    width: calc((3 / 5) * 100%);
  }
  .g-grid .w-4-5 {
    width: calc((4 / 5) * 100%);
  }
  .g-grid .w-5-5 {
    width: calc((5 / 5) * 100%);
  }
  .g-grid .w-1-7 {
    width: calc((1 / 7) * 100%);
  }
  .g-grid .w-2-7 {
    width: calc((2 / 7) * 100%);
  }
  .g-grid .w-3-7 {
    width: calc((3 / 7) * 100%);
  }
  .g-grid .w-4-7 {
    width: calc((4 / 7) * 100%);
  }
  .g-grid .w-5-7 {
    width: calc((5 / 7) * 100%);
  }
  .g-grid .w-6-7 {
    width: calc((6 / 7) * 100%);
  }
  .g-grid .w-7-7 {
    width: calc((7 / 7) * 100%);
  }
  .g-grid .w-1-12 {
    width: calc((1 / 12) * 100%);
  }
  .g-grid .w-2-12 {
    width: calc((2 / 12) * 100%);
  }
  .g-grid .w-3-12 {
    width: calc((3 / 12) * 100%);
  }
  .g-grid .w-4-12 {
    width: calc((4 / 12) * 100%);
  }
  .g-grid .w-5-12 {
    width: calc((5 / 12) * 100%);
  }
  .g-grid .w-6-12 {
    width: calc((6 / 12) * 100%);
  }
  .g-grid .w-7-12 {
    width: calc((7 / 12) * 100%);
  }
  .g-grid .w-8-12 {
    width: calc((8 / 12) * 100%);
  }
  .g-grid .w-9-12 {
    width: calc((9 / 12) * 100%);
  }
  .g-grid .w-10-12 {
    width: calc((10 / 12) * 100%);
  }
  .g-grid .w-11-12 {
    width: calc((11 / 12) * 100%);
  }
  .g-grid .w-12-12 {
    width: calc((12 / 12) * 100%);
  }
}
.g-grid.gutter-2 {
  margin-left: -1px;
  margin-right: -1px;
}
.g-grid.gutter-2 > .g-col {
  padding-left: 1px;
  padding-right: 1px;
}
.g-grid.gutter-12 {
  margin-left: -6px;
  margin-right: -6px;
}
.g-grid.gutter-12 > .g-col {
  padding-left: 6px;
  padding-right: 6px;
}
.g-grid.gutter-24 {
  margin-left: -12px;
  margin-right: -12px;
}
.g-grid.gutter-24 > .g-col {
  padding-left: 12px;
  padding-right: 12px;
}
.g-grid.gutter-48 {
  margin-left: -24px;
  margin-right: -24px;
}
.g-grid.gutter-48 > .g-col {
  padding-left: 24px;
  padding-right: 24px;
}
.g-grid.gutter-96 {
  margin-left: -48px;
  margin-right: -48px;
}
.g-grid.gutter-96 > .g-col {
  padding-left: 48px;
  padding-right: 48px;
}
@media screen and (max-width: 1220px) and (min-width: 769px) {
  .g-grid.gutter-96 {
    margin-top: -48px;
    margin-left: -24px;
    margin-right: -24px;
  }
  .g-grid.gutter-96 > .g-col {
    padding-top: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (max-width: 768px) {
  .g-grid.gutter-96 {
    margin-top: -48px;
  }
  .g-grid.gutter-96 > .g-col {
    padding-top: 48px;
  }
}
.g-grid.grid--reverse {
  flex-direction: row-reverse;
}
@media screen and (max-width: 768px) {
  .g-grid.grid--reverse {
    flex-direction: column-reverse;
  }
}
.g-grid.grid--valign-center {
  justify-content: center;
}
.g-grid.grid--valign-middle {
  align-items: center;
}
.g-grid.grid--valign-bottom {
  align-items: flex-end;
}

.hero {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row !important;
  justify-content: center;
  z-index: 2 !important;
}
.hero:before {
  content: "";
  display: block;
  padding-top: calc((580 / 1920) * 100%);
}
@media screen and (max-width: 768px) {
  .hero {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row !important;
  }
  .hero:before {
    content: "";
    display: block;
    padding-top: calc((4 / 3) * 100%);
  }
}
@media screen and (max-width: 768px) {
  .hero--content {
    align-self: flex-end;
  }
}
.hero h1 {
  font-size: 64px;
}
@media screen and (max-width: 768px) {
  .hero h1 {
    font-size: 48px;
  }
}
.hero--chevron {
  position: absolute;
  z-index: 1;
  right: 96px;
  bottom: -48px;
  width: 192px;
}
.hero--chevron svg {
  display: block;
  width: 192px;
}
.hero.has-overlay .pb-block--bg:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  content: "";
  background: #000;
  opacity: 0.64;
}

.logo {
  display: block;
}
.logo svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: block;
  width: auto;
  height: 64px;
  fill: #1C1C1C;
}
@media (hover: hover) {
  .logo:not(:disabled):hover svg {
    fill: #822F35;
  }
}
.logo:not(:disabled):focus svg {
  fill: #822F35;
}
.logo--bg {
  position: absolute;
  z-index: -1;
  top: -24px;
  left: -50vw;
  right: -96px;
  bottom: -24px;
  transform: skewX(-45deg);
  pointer-events: none;
}
@media screen and (max-width: 1024px) {
  .logo--bg {
    display: none;
  }
}
.logo--bg:before {
  position: absolute;
  z-index: 2;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFF;
}
.logo--bg:after {
  position: absolute;
  z-index: 1;
  content: "";
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24);
}

.media--video {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
}
.media--video:before {
  content: "";
  display: block;
  padding-top: calc((9 / 16) * 100%);
  width: 100%;
  height: 0;
}
.media--video img,
.media--video video,
.media--video iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.media--video img {
  object-fit: cover;
}
.media--video iframe {
  width: 100%;
}

.g-modal {
  transition-property: opacity, visibility;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: relative !important;
  z-index: 999 !important;
}
.g-modal:not(.is-open) {
  opacity: 0;
  visibility: hidden;
  pointer-events: none !important;
}
.g-modal--wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 96px;
  background: rgba(0, 0, 0, 0.88);
  overflow: auto;
}
@media screen and (max-width: 1220px) and (min-width: 769px) {
  .g-modal--wrapper {
    padding: 0 48px;
  }
}
@media screen and (max-width: 768px) {
  .g-modal--wrapper {
    padding: 0 24px;
  }
}
.g-modal--container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}
.g-modal--container:before, .g-modal--container:after {
  content: "";
  display: block;
  flex-shrink: 0;
  width: 100%;
  height: 96px;
}
@media screen and (max-width: 1220px) {
  .g-modal--container:before, .g-modal--container:after {
    height: 48px;
  }
}
.g-modal--content {
  position: relative;
  z-index: 1;
  padding: 32px;
  background: #FFF;
  box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  .g-modal--controls {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    position: fixed;
    z-index: 1;
    column-gap: 12px;
    left: 50%;
    bottom: 12px;
    transform: translateX(-50%);
  }
}
.g-modal--close {
  position: absolute;
  z-index: 1;
  top: -24px;
  right: -24px;
}
@media screen and (max-width: 768px) {
  .g-modal--close {
    position: fixed;
    top: 12px;
    right: 12px;
  }
}
.g-modal.style-gallery .g-modal--container {
  height: 100%;
}
.g-modal.style-gallery .g-modal--content {
  padding: 0;
  max-width: none;
  height: calc(100% - 192px);
  background: none;
}
@media screen and (min-width: 769px) {
  .g-modal.style-gallery .g-modal--close {
    position: fixed;
    z-index: 1;
    top: 24px;
    right: 84px;
  }
}
@media screen and (max-width: 768px) {
  .g-modal.style-gallery .g-modal--close {
    position: static;
  }
}

/*
 * Top
 */
.nav-top {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 96px;
  line-height: 1.4;
  font-size: 16px;
  background: #1C1C1C;
}
@media screen and (max-width: 1220px) and (min-width: 1025px) {
  .nav-top {
    padding: 12px 48px;
  }
}
@media screen and (max-width: 1024px) {
  .nav-top {
    padding: 12px 24px;
    line-height: 1.2;
    font-size: 14px;
  }
}
.nav-top--list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 24px;
}
@media screen and (max-width: 1024px) {
  .nav-top--list {
    column-gap: 18px;
  }
}
.nav-top--link {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #FFF;
}
@media (hover: hover) {
  .nav-top--link:not(:disabled):hover {
    color: #F2C359;
  }
}
.nav-top--link:not(:disabled):focus {
  color: #F2C359;
}

/*
 * Primary
 */
.nav-primary {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  column-gap: 32px;
  line-height: 1.2;
}
@media screen and (max-width: 1070px) and (min-width: 1025px) {
  .nav-primary {
    column-gap: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .nav-primary {
    transition-property: top, opacity, visibility;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    position: fixed;
    z-index: 99;
    top: 121px;
    left: 0;
    right: 0;
    padding: 32px 24px;
    background: #FFF;
  }
  .nav-primary:before, .nav-primary:after {
    position: absolute;
    z-index: -1;
    content: "";
    left: 0;
    right: 0;
    height: 6px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 100%);
  }
  .nav-primary:before {
    top: 0;
  }
  .nav-primary:after {
    bottom: -6px;
  }
  .nav-primary:not(.is-open) {
    opacity: 0;
    visibility: hidden;
  }
}
.nav-primary--list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  column-gap: 32px;
}
@media screen and (max-width: 1070px) and (min-width: 1025px) {
  .nav-primary--list {
    column-gap: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .nav-primary--list {
    flex-direction: column;
    align-items: center;
    row-gap: 18px;
    width: 100%;
  }
}
.nav-primary--item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  position: relative;
  z-index: 1;
  align-items: center;
  flex-shrink: 0;
  column-gap: 9px;
}
@media screen and (max-width: 1024px) {
  .nav-primary--item {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (min-width: 1025px) {
  .nav-primary--item.has-sublist:after {
    position: absolute;
    z-index: 1;
    content: "";
    left: 0;
    right: 0;
    bottom: -24px;
    height: 24px;
  }
}
@media (hover: hover) {
  .nav-primary--item.has-sublist:not(:disabled):hover .nav-primary--sublist {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .nav-primary--item.has-sublist:not(:disabled):hover .nav-primary--link:after {
    opacity: 1;
  }
}
.nav-primary--item.has-sublist:not(:disabled):focus .nav-primary--sublist {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.nav-primary--item.has-sublist:not(:disabled):focus .nav-primary--link:after {
  opacity: 1;
}
.nav-primary--link {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: relative;
  z-index: 1;
  color: #1C1C1C;
  font-weight: 500;
}
@media screen and (max-width: 1024px) {
  .nav-primary--link {
    text-align: center;
  }
}
@media (hover: hover) {
  .nav-primary--link:not(:disabled):hover {
    color: #822F35;
  }
}
.nav-primary--link:not(:disabled):focus {
  color: #822F35;
}
.nav-primary--link.is-current {
  color: #822F35;
}
@media screen and (max-width: 1024px) {
  .nav-primary--link.is-current {
    color: #822F35;
  }
}
.nav-primary--chevron {
  position: relative;
  z-index: 1;
  width: 12px;
  height: 12px;
  background: none;
  transform: translateY(-2px);
}
@media screen and (max-width: 1024px) {
  .nav-primary--chevron {
    width: 16px;
    height: 16px;
  }
}
.nav-primary--chevron:before {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: absolute;
  z-index: 1;
  content: "";
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
}
.nav-primary--chevron svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: relative;
  z-index: 2;
  display: block;
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
  fill: #1C1C1C;
}
@media screen and (max-width: 1024px) {
  .nav-primary--chevron svg {
    width: 16px;
    height: 16px;
  }
}
@media (hover: hover) {
  .nav-primary--chevron:not(:disabled):hover svg {
    fill: #822F35;
  }
}
.nav-primary--chevron:not(:disabled):focus svg {
  fill: #822F35;
}
@media screen and (min-width: 1025px) {
  .nav-primary--sublist {
    transition-property: opacity, visibility;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    position: absolute;
    z-index: 1;
    top: 46px;
    left: -32px;
    right: -32px;
    padding: 32px;
    background: #FFF;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  }
}
@media screen and (max-width: 1024px) {
  .nav-primary--sublist {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .nav-primary--sublist:not(.is-open) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}
@media screen and (max-width: 1024px) {
  .nav-primary--sublist:not(.is-open) {
    display: none;
  }
}
.nav-primary--subitem:not(:first-child) {
  padding-top: 12px;
}
@media screen and (min-width: 1025px) {
  .nav-primary--sublink {
    transition-property: color;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    display: block;
    color: #1C1C1C;
    font-size: 16px;
    font-style: italic;
    text-align: center;
    white-space: nowrap;
  }
}
@media screen and (min-width: 1025px) and (hover: hover) {
  .nav-primary--sublink:not(:disabled):hover {
    color: #822F35;
  }
}
@media screen and (min-width: 1025px) {
  .nav-primary--sublink:not(:disabled):focus {
    color: #822F35;
  }
}
@media screen and (max-width: 1024px) {
  .nav-primary--sublink {
    text-align: center;
  }
}
.nav-primary--sublink.is-current {
  color: #822F35;
}
@media screen and (max-width: 1024px) {
  .nav-primary--sublink.is-current {
    color: #822F35;
  }
}
@media screen and (min-width: 1025px) {
  .nav-primary--trigger, .nav-primary--close {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .nav-primary--trigger, .nav-primary--close {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #FFF !important;
  }
  .nav-primary--trigger svg, .nav-primary--close svg {
    display: block;
    width: 24px;
    height: 24px;
    fill: #1C1C1C;
  }
}
@media screen and (max-width: 1024px) {
  .nav-primary--close {
    transition-property: top;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    position: fixed;
    z-index: 1;
    top: 65px;
    right: 24px;
  }
}
.nav-primary--ctas-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  column-gap: 6px;
}
@media screen and (min-width: 1025px) {
  .nav-primary--ctas-wrapper {
    margin: -12px 0;
  }
}
@media screen and (max-width: 1024px) {
  .nav-primary--ctas-wrapper {
    margin-top: 18px;
  }
}

/*
 * Footer
 */
.nav-footer--item:not(:first-child) {
  padding-top: 6px;
}
.nav-footer--link {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #1C1C1C;
  font-weight: 500;
}
@media (hover: hover) {
  .nav-footer--link:not(:disabled):hover {
    color: #822F35;
  }
}
.nav-footer--link:not(:disabled):focus {
  color: #822F35;
}

/*
 * Sticky Header Behaviour
 */
@media screen and (max-width: 1024px) {
  body.sticky-down .nav-primary {
    top: 80px;
  }
}
@media screen and (max-width: 1024px) {
  body.sticky-down .nav-primary--close {
    top: 24px;
  }
}

.newsletter--form-wrapper {
  padding-top: 12px;
}
@media screen and (max-width: 768px) {
  .newsletter--form-wrapper {
    padding-top: 24px;
  }
}
@media screen and (max-width: 768px) {
  .newsletter--form-wrapper .g-grid {
    row-gap: 6px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .newsletter--form-wrapper .g-col {
    width: 33.3333333333%;
  }
}
.newsletter--form-wrapper .btn {
  text-align: left;
}
@media screen and (min-width: 769px) {
  .newsletter--form-wrapper .btn {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .newsletter--col-4 {
    margin: 12px auto 0;
  }
}
.newsletter--btn {
  position: relative;
  z-index: 1;
}
.newsletter--btn input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
}
.newsletter--response {
  margin-top: 12px;
  padding: 12px 24px;
  color: #FFF;
  line-height: 1.4;
  font-size: 16px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .newsletter--response {
    line-height: 1.2;
    font-size: 14px;
  }
}
.newsletter--response.is-success {
  background: #00B16A;
}
.newsletter--response.is-error {
  background: #D91E18;
}
.newsletter--outro {
  padding-top: 24px;
  line-height: 1.4;
  font-size: 16px;
}
@media screen and (min-width: 769px) {
  .newsletter--outro {
    text-align: center;
  }
}

.page-heading--text {
  width: 50%;
  max-width: 680px;
}
@media screen and (max-width: 768px) {
  .page-heading--text {
    width: 65%;
  }
}
.page-heading--pic {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: skewX(-45deg);
  transform-origin: bottom;
  overflow: hidden;
}
.page-heading--img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  transform: skewX(45deg);
  transform-origin: bottom;
}

.page-hero {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row !important;
  justify-content: center;
  overflow: hidden;
}
.page-hero:before {
  content: "";
  display: block;
  padding-top: calc((320 / 1920) * 100%);
}
@media screen and (min-width: 769px) {
  .page-hero--text {
    width: 50%;
    max-width: 680px;
  }
}
@media screen and (max-width: 768px) {
  .page-hero--text:not(.mobile-full) {
    width: 65%;
  }
}
.page-hero--thumb {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  margin: -32px -24px 24px;
  width: calc(100% + 48px);
}
.page-hero--thumb:before {
  content: "";
  display: block;
  padding-top: calc((9 / 16) * 100%);
  width: 100%;
  height: 0;
}
.page-hero--thumb img,
.page-hero--thumb video,
.page-hero--thumb iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.page-hero--thumb img {
  object-fit: cover;
}
.page-hero--thumb iframe {
  width: 100%;
}
.page-hero--pic {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: skewX(45deg);
  transform-origin: top;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .page-hero--pic {
    left: 60%;
    transform: skewX(-45deg);
    transform-origin: bottom;
  }
}
.page-hero--img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  transform: skewX(-45deg);
  transform-origin: top;
}
@media screen and (max-width: 768px) {
  .page-hero--img {
    transform: skewX(45deg);
    transform-origin: bottom;
  }
}

/*
 * Pagination
 */
.nav-pagination {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  padding: 48px 96px 0 !important;
}
.nav-pagination--list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.nav-pagination--item.is-current {
  opacity: 0.48;
}
.nav-pagination--prev {
  margin-right: 24px;
}
@media screen and (max-width: 768px) {
  .nav-pagination--prev {
    margin-right: 12px;
  }
}
.nav-pagination--next {
  margin-left: 24px;
}
@media screen and (max-width: 768px) {
  .nav-pagination--next {
    margin-left: 12px;
  }
}
.nav-pagination--link {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  color: #1C1C1C !important;
  font-weight: 700;
}
.nav-pagination--link.btn--has-icon > .btn--icon.icon-flip {
  transform: scale(-1) translateY(2px);
}
.nav-pagination--link:not(.btn) {
  display: block;
  padding: 6px 9px;
}

@media (hover: hover) {
  a.nav-pagination--link:not(:disabled):hover {
    color: #822F35 !important;
  }
}
a.nav-pagination--link:not(:disabled):focus {
  color: #822F35 !important;
}

[data-g-show-more=false] {
  display: none !important;
}

.g-stats--item {
  position: relative;
  z-index: 1;
  padding: 48px 96px;
}
@media screen and (max-width: 1220px) and (min-width: 769px) {
  .g-stats--item {
    padding: 48px;
  }
}
@media screen and (max-width: 768px) {
  .g-stats--item {
    padding: 24px;
  }
}
@media screen and (min-width: 769px) {
  .g-stats--item:nth-child(odd) .g-stats--bg {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%);
  }
}
@media screen and (min-width: 769px) {
  .g-stats--item:nth-child(even) .g-stats--bg {
    clip-path: polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
.g-stats--content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
@media screen and (min-width: 769px) {
  .g-stats--content {
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .g-stats--content {
    flex-direction: column;
    text-align: center;
  }
}
@media screen and (min-width: 769px) {
  .g-stats--lhc, .g-stats--rhc {
    width: calc(50% - 96px);
  }
}
@media screen and (min-width: 769px) {
  .g-stats--lhc {
    position: relative;
    z-index: 1;
    padding-right: 72px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 768px) {
  .g-stats--lhc {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .g-stats--rhc {
    position: relative;
    z-index: 1;
    padding: 24px 24px 0;
  }
}
.g-stats--heading {
  font-weight: 700;
}
.g-stats--text {
  padding-top: 6px;
}
.g-stats--chevron {
  width: 48px;
  height: 48px;
}
@media screen and (min-width: 769px) {
  .g-stats--chevron {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 768px) {
  .g-stats--chevron {
    padding: 12px 0 18px;
  }
}
.g-stats--chevron svg {
  display: block;
  width: 48px;
  height: 48px;
}
@media screen and (max-width: 768px) {
  .g-stats--chevron svg {
    transform: rotate(90deg);
  }
}
.g-stats--val {
  line-height: 1;
  color: #FFF;
  text-align: right;
}
.g-stats--val {
  font-size: 48px;
}
@media screen and (min-width: 312px) {
  .g-stats--val {
    font-size: calc(48px + 96 * ((100vw - 312px) / 968));
  }
}
@media screen and (min-width: 1280px) {
  .g-stats--val {
    font-size: 144px;
  }
}
@media screen and (max-width: 768px) {
  .g-stats--val {
    text-align: center;
  }
}
.g-stats--bg {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
}
@media screen and (max-width: 768px) {
  .g-stats--bg {
    left: -24px;
    right: -24px;
    bottom: -24px;
  }
}

.team {
  margin-top: -48px;
}
.team--item {
  margin-top: 48px;
}
.team--item--thumb {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  overflow: hidden;
}
@media (hover: hover) {
  .team--item--thumb:not(:disabled):hover .team--item--bio-wrapper {
    opacity: 1;
  }
}
.team--item--thumb:not(:disabled):focus .team--item--bio-wrapper {
  opacity: 1;
}
.team--item--pic {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  overflow: hidden;
}
.team--item--pic:before {
  content: "";
  display: block;
  padding-top: calc((3 / 4) * 100%);
  width: 100%;
  height: 0;
}
.team--item--pic img,
.team--item--pic video,
.team--item--pic iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.team--item--pic img {
  object-fit: cover;
}
.team--item--pic iframe {
  width: 100%;
}
.team--item--bio-wrapper {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.64);
  opacity: 0;
  backdrop-filter: blur(12px);
  overflow: auto;
}
.team--item--bio {
  align-self: center;
  padding: 24px;
  line-height: 1.4;
  font-size: 16px;
  text-align: center;
}
.team--item--meta {
  padding-top: 18px;
  line-height: 1.4;
}
.team--item--name {
  font-weight: 700;
}
.team--item--role, .team--item--email {
  font-size: 16px;
  font-weight: 500;
}
.team--item--email {
  padding-top: 9px;
  text-decoration: underline;
}

.testimonial {
  position: relative;
  z-index: 1;
  padding: 48px 120px;
}
@media screen and (max-width: 768px) {
  .testimonial {
    padding: 68px 24px 32px;
  }
}
.testimonial--text {
  position: relative;
  z-index: 1;
}
.testimonial--meta {
  line-height: 1.2;
}
.testimonial--quote {
  position: absolute;
  z-index: 1;
  width: 48px;
  height: 48px;
}
@media screen and (max-width: 768px) {
  .testimonial--quote {
    width: 32px;
    height: 32px;
  }
}
.testimonial--quote svg {
  display: block;
  width: 48px;
  height: 48px;
}
@media screen and (max-width: 768px) {
  .testimonial--quote svg {
    width: 32px;
    height: 32px;
  }
}
.testimonial--quote.pos-top {
  top: 0;
  left: -72px;
}
@media screen and (max-width: 768px) {
  .testimonial--quote.pos-top {
    top: -44px;
    left: 0;
  }
}
.testimonial--quote.pos-bot {
  right: -72px;
  bottom: 0;
}
@media screen and (max-width: 768px) {
  .testimonial--quote.pos-bot {
    right: 0;
    bottom: -32px;
  }
}
.testimonial--quote.pos-bot svg {
  transform: scale(-1);
}
.testimonial--bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  clip-path: polygon(60% 0%, 70% 0%, 100% 100%, 30% 100%);
}
@media screen and (max-width: 768px) {
  .testimonial--bg {
    clip-path: polygon(0% 80%, 70% 15%, 100% 50%, 100% 100%, 0% 100%);
  }
}
.testimonial--bg.bg-03-01, .testimonial--bg.bg-03-02 {
  background: #FFF !important;
  opacity: 0.12;
}
.testimonial--bg.bg-03-03 {
  background: #000 !important;
  opacity: 0.12;
}
.testimonial--symbol-rhc {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30%;
  mask-image: url("../images/main-arrows.svg");
  mask-size: 20%;
  mask-position: center center;
}
@media screen and (max-width: 1024px) {
  .testimonial--symbol-rhc {
    width: 60%;
    opacity: 0.12;
  }
}

.two-cols-image {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  position: relative;
  z-index: 1;
  justify-content: space-between;
}
.two-cols-image:after {
  position: absolute;
  z-index: 2;
  content: "";
  top: 0;
  left: calc(80% - 32px);
  bottom: 0;
  width: 64px;
  background: currentColor;
  transform: skewX(45deg);
  transform-origin: bottom;
}
@media screen and (max-width: 768px) {
  .two-cols-image:after {
    left: calc(80% - 16px);
    width: 32px;
  }
}
.two-cols-image--lhc, .two-cols-image--rhc {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  overflow: hidden;
}
.two-cols-image--lhc {
  position: relative;
  z-index: 2;
  width: 80%;
  transform: skewX(45deg);
  transform-origin: bottom;
}
.two-cols-image--lhc > .two-cols-image--pic {
  transform: skewX(-45deg);
  transform-origin: bottom;
}
.two-cols-image--rhc {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 40%;
  right: 0;
  bottom: 0;
}
.two-cols-image--pic {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  flex: 1 1 auto;
}
.two-cols-image--pic:before {
  content: "";
  display: block;
  padding-top: calc((1 / 2) * 100%);
  width: 100%;
  height: 0;
}
.two-cols-image--pic img,
.two-cols-image--pic video,
.two-cols-image--pic iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.two-cols-image--pic img {
  object-fit: cover;
}
.two-cols-image--pic iframe {
  width: 100%;
}

.two-cols-text--heading:not(:last-child) {
  padding-bottom: 27px;
}
@media screen and (max-width: 768px) {
  .two-cols-text--heading:not(:last-child) {
    padding-bottom: 22px;
  }
}
@media screen and (min-width: 769px) {
  .two-cols-text--text {
    column-count: 2;
    column-gap: 48px;
  }
}

.g-typo {
  /*
   * Spacings
   */
  /*
   * Colors
   */
}
.g-typo a:not(.btn):not(.link) {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  text-decoration: underline;
}
.g-typo ul:not([class]) {
  list-style: none;
}
.g-typo ul:not([class]) li {
  position: relative;
  z-index: 1;
  padding-left: 18px;
}
.g-typo ul:not([class]) li:not(:last-child) {
  padding-bottom: 0.5em;
}
.g-typo ul:not([class]) li:before {
  position: absolute;
  z-index: 1;
  content: "";
  top: 0;
  left: 0;
  border-radius: 100%;
}
.g-typo ol {
  list-style: none;
  counter-reset: ol-counter;
}
.g-typo ol li {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  counter-increment: ol-counter;
}
.g-typo ol li:not(:last-child) {
  padding-bottom: 0.5em;
}
.g-typo ol li:before {
  content: counter(ol-counter);
  flex-shrink: 0;
  padding-right: 24px;
  font-weight: 500;
}
.g-typo p > img {
  max-width: 100%;
}
.g-typo p:has(> img) {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 24px;
}
.g-typo p:has(> img)[style="text-align:center"] {
  justify-content: center;
}
.g-typo p.polyfill-has--img {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 24px;
}
.g-typo p.polyfill-has--img[style="text-align:center"] {
  justify-content: center;
}
.g-typo p:has(> .btn) {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
@media screen and (min-width: 769px) {
  .g-typo p:has(> .btn) {
    column-gap: 24px;
  }
}
@media screen and (max-width: 768px) {
  .g-typo p:has(> .btn) {
    flex-wrap: wrap;
    row-gap: 12px;
  }
}
.g-typo p:has(> .btn)[style="text-align:center"], .g-typo p:has(> .btn)[style="text-align:center;"], .g-typo p:has(> .btn)[style="text-align: center"], .g-typo p:has(> .btn)[style="text-align: center;"] {
  justify-content: center;
}
.g-typo p.polyfill-has--btn {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
@media screen and (min-width: 769px) {
  .g-typo p.polyfill-has--btn {
    column-gap: 24px;
  }
}
@media screen and (max-width: 768px) {
  .g-typo p.polyfill-has--btn {
    row-gap: 12px;
  }
}
.g-typo p.polyfill-has--btn[style="text-align:center"], .g-typo p.polyfill-has--btn[style="text-align:center;"], .g-typo p.polyfill-has--btn[style="text-align: center"], .g-typo p.polyfill-has--btn[style="text-align: center;"] {
  justify-content: center;
}
.g-typo table {
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font-size: 16px;
}
.g-typo table tr th:first-child[rowspan],
.g-typo table tr td:first-child[rowspan] {
  background: none !important;
}
.g-typo table caption {
  display: none;
}
.g-typo.spacing-default h1:not(:first-child) {
  padding-top: 1em;
}
.g-typo.spacing-default h2:not(:first-child) {
  padding-top: 1.1em;
}
.g-typo.spacing-default h3:not(:first-child) {
  padding-top: 1.2em;
}
.g-typo.spacing-default h4:not(:first-child) {
  padding-top: 1.3em;
}
.g-typo.spacing-default h5:not(:first-child) {
  padding-top: 1.4em;
}
.g-typo.spacing-default h6:not(:first-child), .g-typo.spacing-default p:not(:first-child), .g-typo.spacing-default ul:not(:first-child), .g-typo.spacing-default ol:not(:first-child) {
  padding-top: 1.5em;
}
.g-typo.spacing-default ul:not([class]) li:before {
  margin-top: 10px;
  width: 7px;
  height: 7px;
}
.g-typo.spacing-default p:has(> .btn):not(:first-child) {
  padding-top: 3em;
}
.g-typo.spacing-default p.polyfill-has--btn {
  padding-top: 3em;
}
.g-typo.spacing-default table th,
.g-typo.spacing-default table td {
  padding: 9px 18px;
}
.g-typo.spacing-default table ul:not([class]) li:before {
  margin-top: 9px;
  width: 5px;
  height: 5px;
}
@media screen and (max-width: 768px) {
  .g-typo.spacing-default h1:not(:first-child) {
    padding-top: 0.8333333333em;
  }
  .g-typo.spacing-default h2:not(:first-child) {
    padding-top: 0.9166666667em;
  }
  .g-typo.spacing-default h3:not(:first-child) {
    padding-top: 1em;
  }
  .g-typo.spacing-default h4:not(:first-child) {
    padding-top: 1.0833333333em;
  }
  .g-typo.spacing-default h5:not(:first-child) {
    padding-top: 1.1666666667em;
  }
  .g-typo.spacing-default h6:not(:first-child), .g-typo.spacing-default p:not(:first-child), .g-typo.spacing-default ul:not(:first-child), .g-typo.spacing-default ol:not(:first-child) {
    padding-top: 1.25em;
  }
  .g-typo.spacing-default ul:not([class]) li:before {
    margin-top: 9px;
    width: 5px;
    height: 5px;
  }
  .g-typo.spacing-default p:has(> .btn):not(:first-child) {
    padding-top: 2.5em;
  }
  .g-typo.spacing-default p.polyfill-has--btn {
    padding-top: 2.5em;
  }
}
.g-typo.spacing-medium h1:not(:first-child) {
  padding-top: 1em;
}
.g-typo.spacing-medium h2:not(:first-child) {
  padding-top: 1.1em;
}
.g-typo.spacing-medium h3:not(:first-child) {
  padding-top: 1.2em;
}
.g-typo.spacing-medium h4:not(:first-child) {
  padding-top: 1.3em;
}
.g-typo.spacing-medium h5:not(:first-child) {
  padding-top: 1.4em;
}
.g-typo.spacing-medium h6:not(:first-child), .g-typo.spacing-medium p:not(:first-child), .g-typo.spacing-medium ul:not(:first-child), .g-typo.spacing-medium ol:not(:first-child) {
  padding-top: 1em;
}
.g-typo.spacing-medium ul:not([class]) li:before {
  margin-top: 10px;
  width: 7px;
  height: 7px;
}
.g-typo.spacing-medium p:has(> .btn):not(:first-child) {
  padding-top: 2em;
}
.g-typo.spacing-medium p.polyfill-has--btn {
  padding-top: 2em;
}
.g-typo.spacing-medium table th,
.g-typo.spacing-medium table td {
  padding: 9px 18px;
}
.g-typo.spacing-medium table ul:not([class]) li:before {
  margin-top: 9px;
  width: 5px;
  height: 5px;
}
@media screen and (max-width: 768px) {
  .g-typo.spacing-medium h1:not(:first-child) {
    padding-top: 0.8333333333em;
  }
  .g-typo.spacing-medium h2:not(:first-child) {
    padding-top: 0.9166666667em;
  }
  .g-typo.spacing-medium h3:not(:first-child) {
    padding-top: 1em;
  }
  .g-typo.spacing-medium h4:not(:first-child) {
    padding-top: 1.0833333333em;
  }
  .g-typo.spacing-medium h5:not(:first-child) {
    padding-top: 1.1666666667em;
  }
  .g-typo.spacing-medium h6:not(:first-child), .g-typo.spacing-medium p:not(:first-child), .g-typo.spacing-medium ul:not(:first-child), .g-typo.spacing-medium ol:not(:first-child) {
    padding-top: 0.8333333333em;
  }
  .g-typo.spacing-medium ul:not([class]) li:before {
    margin-top: 9px;
    width: 5px;
    height: 5px;
  }
  .g-typo.spacing-medium p:has(> .btn):not(:first-child) {
    padding-top: 1.6666666667em;
  }
  .g-typo.spacing-medium p.polyfill-has--btn {
    padding-top: 1.6666666667em;
  }
}
.g-typo.color-default {
  color: #1C1C1C;
}
.g-typo.color-default h1, .g-typo.color-default h2, .g-typo.color-default h3, .g-typo.color-default h4, .g-typo.color-default h5, .g-typo.color-default h6 {
  color: #1C1C1C;
}
.g-typo.color-default a:not([class]) {
  color: #1C1C1C;
}
@media (hover: hover) {
  .g-typo.color-default a:not([class]):not(:disabled):hover {
    color: #1C1C1C;
  }
}
.g-typo.color-default a:not([class]):not(:disabled):focus {
  color: #1C1C1C;
}
.g-typo.color-default ul:not([class]) li:before {
  background: #1C1C1C;
}
.g-typo.color-default ol:not([class]) li:before {
  color: #1C1C1C;
}
.g-typo.color-default table th {
  color: #1C1C1C;
  background: #EFEDED;
  border: 1px solid #707070;
}
.g-typo.color-default table td {
  color: #1C1C1C;
  background: none;
  border: 1px solid #707070;
}
.g-typo.color-default table tr.odd td {
  color: #1C1C1C;
  background: none;
  border: 1px solid #707070;
}
.g-typo.color-light {
  color: #FFF;
}
.g-typo.color-light h1, .g-typo.color-light h2, .g-typo.color-light h3, .g-typo.color-light h4, .g-typo.color-light h5, .g-typo.color-light h6 {
  color: #FFF;
}
.g-typo.color-light a:not([class]) {
  color: #FFF;
}
@media (hover: hover) {
  .g-typo.color-light a:not([class]):not(:disabled):hover {
    color: #FFF;
  }
}
.g-typo.color-light a:not([class]):not(:disabled):focus {
  color: #FFF;
}
.g-typo.color-light ul:not([class]) li:before {
  background: #FFF;
}
.g-typo.color-light ol:not([class]) li:before {
  color: #FFF;
}
.g-typo.color-light table th {
  color: #FFF;
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.24);
}
.g-typo.color-light table td {
  color: #FFF;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.24);
}
.g-typo.color-light table tr.odd td {
  color: #FFF;
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.24);
}

.password-protected__form .post-password-form label {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.password-protected__form .post-password-form label input {
  display: block;
  width: 100%;
  max-width: 400px;
  margin-top: 5px;
  height: 52px;
  padding: 0 20px;
  background: white;
  border: 2px solid #1C1C1C;
  font-size: 18px;
  color: #1C1C1C;
}
.password-protected__form .post-password-form input[type=submit] {
  padding: 10px 20px;
  height: 52px;
  background: #F2C359;
  color: #1C1C1C;
  line-height: 1;
  width: 100%;
  max-width: 200px;
  margin-top: 20px;
  transition: 0.3s all;
  font-weight: 500;
  font-size: 18px;
}
.password-protected__form .post-password-form input[type=submit]:hover {
  background: #FFDC8E;
}

@media screen and (min-width: 769px) {
  .page-builder:first-child .pb-block:first-child {
    padding-top: 138px;
  }
}
@media screen and (max-width: 1220px) and (min-width: 769px) {
  .page-builder:first-child .pb-block:first-child {
    padding-top: 90px;
  }
}
.page-builder:last-child > .pb-block.pb-block--has-bg:last-child {
  margin-bottom: -96px;
}
@media screen and (max-width: 1220px) and (min-width: 769px) {
  .page-builder:last-child > .pb-block.pb-block--has-bg:last-child {
    margin-bottom: -48px;
  }
}
@media screen and (max-width: 768px) {
  .page-builder:last-child > .pb-block.pb-block--has-bg:last-child {
    margin-bottom: -48px;
  }
}

/*
 * Block
 */
.pb-block {
  padding: 96px;
}
@media screen and (max-width: 1220px) and (min-width: 1025px) {
  .pb-block {
    padding: 48px 72px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .pb-block {
    padding: 48px;
  }
}
@media screen and (max-width: 768px) {
  .pb-block {
    padding: 48px 24px;
  }
}
.pb-block.pb-block--has-bg {
  position: relative;
  z-index: 1;
}
.pb-block:not(.pb-block--has-bg) + .pb-block:not(.pb-block--has-bg):not(.pb-block-divider) {
  margin-top: -96px !important;
}
@media screen and (max-width: 1220px) and (min-width: 769px) {
  .pb-block:not(.pb-block--has-bg) + .pb-block:not(.pb-block--has-bg):not(.pb-block-divider) {
    margin-top: -48px !important;
  }
}
@media screen and (max-width: 768px) {
  .pb-block:not(.pb-block--has-bg) + .pb-block:not(.pb-block--has-bg):not(.pb-block-divider) {
    margin-top: -48px !important;
  }
}
.pb-block:not(.pb-block--has-bg):last-child {
  padding-bottom: 0;
}
.pb-block--push-bottom {
  padding-bottom: 144px;
}
@media screen and (max-width: 1220px) {
  .pb-block--push-bottom {
    padding-bottom: 96px;
  }
}

/*
 * Intro
 */
.pb-block--intro {
  padding-bottom: 48px;
}

/*
 * Footer
 */
.pb-block--footer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  padding-top: 48px;
}

/*
 * Background
 */
.pb-block--bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}
.pb-block--bg--pic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: block;
}
.pb-block--bg--img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}
.pb-block--bg--img.pos-left {
  object-position: left;
}
.pb-block--bg--img.pos-right {
  object-position: right;
}
.pb-block--bg--video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  opacity: 1;
}
.pb-block--bg--shape {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: skewX(-45deg);
  transform-origin: bottom;
}
@media screen and (max-width: 768px) {
  .pb-block--bg--shape {
    left: 60%;
  }
}
@media screen and (min-width: 769px) {
  .pb-block--bg--shape.reverse {
    transform: skewX(45deg);
    transform-origin: top;
  }
}

.pb-block--breadcrumbs {
  padding-top: 32px;
}
@media screen and (max-width: 768px) {
  .pb-block--breadcrumbs {
    padding-top: 24px;
  }
}

.pb-block--divider.h-default {
  height: 96px;
}
.pb-block--divider.h-medium {
  height: 48px;
}
.pb-block--divider.h-small {
  height: 48px;
}
@media screen and (max-width: 1220px) and (min-width: 769px) {
  .pb-block--divider.h-default {
    height: 48px;
  }
}
@media screen and (max-width: 768px) {
  .pb-block--divider.h-default, .pb-block--divider.h-medium {
    height: 48px;
  }
}

@media screen and (max-width: 768px) {
  .pb-block--gallery-carousel {
    padding: 48px !important;
  }
}

@media screen and (max-width: 768px) {
  .pb-block--heading-text .g-grid .col-heading {
    margin-bottom: -24px;
  }
}
.pb-block--heading-text .g-grid .col-heading svg {
  fill: currentColor;
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  .pb-block--heading-text .g-grid.grid--reverse .col-heading {
    order: 1;
  }
}
.pb-block--heading-text.v-large .col-heading h2 {
  line-height: 1;
  font-size: 128px;
}
.pb-block--heading-text.v-large .col-heading h2 svg {
  width: 68px;
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .pb-block--heading-text.v-large .col-heading h2 {
    font-size: 96px;
  }
  .pb-block--heading-text.v-large .col-heading h2 svg {
    width: 52px;
  }
}
@media screen and (max-width: 768px) {
  .pb-block--heading-text.v-large .col-heading h2 {
    font-size: 48px;
  }
  .pb-block--heading-text.v-large .col-heading h2 br {
    display: none;
  }
  .pb-block--heading-text.v-large .col-heading h2 svg {
    width: 26px;
  }
}
@media screen and (min-width: 769px) {
  .pb-block--heading-text.v-large .col-text {
    font-size: 24px;
  }
}
.pb-block--heading-text:not(.v-large) .col-heading h2 svg {
  width: 28px;
}
@media screen and (max-width: 768px) {
  .pb-block--heading-text:not(.v-large) .col-heading h2 svg {
    width: 20px;
  }
}

@media screen and (max-width: 768px) {
  .pb-block--media-text .g-grid:not(.grid--reverse) .col-media {
    padding-top: 24px;
  }
}
@media screen and (max-width: 768px) {
  .pb-block--media-text .g-grid.grid--reverse .col-media {
    padding-top: 24px;
  }
}

@media screen and (max-width: 768px) {
  .pb-block--ph {
    padding: 32px 24px !important;
  }
}

@media screen and (max-width: 768px) {
  .pb-block--page-hero {
    padding: 32px 24px !important;
  }
}

@media screen and (max-width: 768px) {
  .pb-block--related-posts .pb-block--intro {
    padding-bottom: 24px;
  }
}
.pb-block--related-posts .pb-block--footer {
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .pb-block--related-posts .pb-block--footer {
    justify-content: flex-start;
  }
}

.pb-block--stats {
  padding: 0 !important;
}

@media screen and (min-width: 769px) {
  .pb-block--table {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.pb-block--table .g-typo {
  overflow-x: auto;
}
.pb-block--table table {
  min-width: 900px;
}

@media screen and (max-width: 768px) {
  .pb-block--testimonial {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .pb-block--testimonial-v2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (min-width: 1025px) {
  .pb-block--testimonial-v2 .testimonial--text {
    width: 60%;
  }
}

.pb-block--two-cols-image {
  padding: 48px 0 !important;
}
@media screen and (max-width: 768px) {
  .pb-block--two-cols-image {
    padding: 24px 0 !important;
  }
}

.tpl-archive {
  margin-top: -48px;
}
@media screen and (max-width: 768px) {
  .tpl-archive {
    margin-top: -24px;
  }
}
.tpl-archive .card {
  margin-top: 48px;
}
@media screen and (max-width: 768px) {
  .tpl-archive .card {
    margin-top: 24px;
  }
}
.tpl-archive.archive-short {
  margin-top: -24px;
}
@media screen and (max-width: 768px) {
  .tpl-archive.archive-short {
    margin-top: -12px;
  }
}
.tpl-archive.archive-short .card {
  margin-top: 24px;
}
@media screen and (max-width: 768px) {
  .tpl-archive.archive-short .card {
    margin-top: 12px;
  }
}
@media screen and (max-width: 768px) {
  .tpl-archive--heading {
    padding-bottom: 24px !important;
  }
}
.tpl-archive--filters--content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
@media screen and (min-width: 769px) {
  .tpl-archive--filters--content {
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 48px;
  }
}
@media screen and (max-width: 768px) {
  .tpl-archive--filters--content {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;
  }
}
.tpl-archive--filters--dropdown {
  min-width: 260px;
  width: fit-content;
}
.tpl-archive--filters--true-false {
  padding: 12px 0 13px !important;
}

.tpl-contact--contacts {
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .tpl-contact--contacts {
    flex-direction: column !important;
  }
}
.tpl-contact--contacts .btn--icon {
  width: 24px;
  height: 24px;
}
.tpl-contact--form {
  padding-top: 96px;
}
@media screen and (max-width: 1220px) {
  .tpl-contact--form {
    padding-top: 48px;
  }
}
.tpl-contact--pic {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex: 1 1 auto;
}
.tpl-contact--img {
  flex: 1 1 auto;
  object-fit: cover;
}
.tpl-contact--socials {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  position: relative;
  z-index: 1;
  align-items: center;
  column-gap: 48px;
  padding: 48px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .tpl-contact--socials {
    flex-direction: column;
    row-gap: 24px;
    padding: 32px 24px;
  }
}
@media screen and (min-width: 769px) {
  .tpl-contact--socials--lhc {
    width: calc(70% - 24px);
  }
}
@media screen and (min-width: 769px) {
  .tpl-contact--socials--rhc {
    width: calc(30% - 24px);
  }
}
@media screen and (min-width: 769px) {
  .tpl-contact--socials--text {
    padding-right: 25%;
  }
}
.tpl-contact--socials--list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  row-gap: 24px;
}
@media screen and (max-width: 768px) {
  .tpl-contact--socials--list {
    row-gap: 12px;
  }
}
.tpl-contact--socials--link {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  column-gap: 18px;
  color: #1C1C1C;
  font-size: 22px;
  font-weight: 500;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .tpl-contact--socials--link {
    column-gap: 12px;
    font-size: 18px;
  }
}
.tpl-contact--socials--link span {
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #FFF;
  border: 2px solid #1C1C1C;
  border-radius: 100%;
}
@media screen and (max-width: 768px) {
  .tpl-contact--socials--link span {
    width: 32px;
    height: 32px;
  }
}
.tpl-contact--socials--link span svg {
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  width: 22px;
  height: 22px;
  fill: #1C1C1C;
}
@media screen and (max-width: 768px) {
  .tpl-contact--socials--link span svg {
    width: 16px;
    height: 16px;
  }
}
@media (hover: hover) {
  .tpl-contact--socials--link:not(:disabled):hover span {
    background: none;
  }
}
.tpl-contact--socials--link:not(:disabled):focus span {
  background: none;
}
.tpl-contact--socials--link.style-light {
  color: #FFF;
}
.tpl-contact--socials--link.style-light span {
  background: none;
  border-color: #FFF;
}
.tpl-contact--socials--link.style-light span svg {
  fill: #FFF;
}
.tpl-contact--socials--shape {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 70%;
  right: 0;
  bottom: 0;
}
@media screen and (min-width: 769px) {
  .tpl-contact--socials--shape {
    transform: skewX(45deg);
    transform-origin: bottom;
  }
}
@media screen and (max-width: 768px) {
  .tpl-contact--socials--shape {
    left: 0;
    clip-path: polygon(0% 80%, 70% 15%, 100% 50%, 100% 100%, 0% 100%);
  }
}
.tpl-contact--socials--bg-rhc {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 70%;
  right: 0;
  bottom: 0;
}

.tpl-nss .main {
  position: relative;
  z-index: 1;
}
.tpl-nss .main--arrows {
  position: absolute;
  mask: none !important;
  background-image: url("../images/main-nss-logos.svg");
  background-size: 100%;
  background-repeat: repeat-y;
}
.tpl-nss .pb-block--ph:has(.pb-block--bg.bg-03-01) h1,
.tpl-nss .pb-block--ph:has(.pb-block--bg.bg-03-03) h1 {
  color: #00382E;
}
.tpl-nss .pb-block--ph:has(.pb-block--bg.bg-03-02) h1 {
  color: #19D41C;
}
.tpl-nss .testimonial--symbol-rhc {
  position: absolute;
  z-index: -1;
  mask-image: url("../images/nss-logo.svg");
  mask-size: 122%;
  mask-position: center left;
  mask-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .tpl-event .page-hero--text h1,
  .tpl-event .page-heading--text h1 {
    font-size: 24px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 768px) {
  .tpl-event--meta .g-grid {
    row-gap: 18px;
  }
}
.tpl-event--meta--col {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  column-gap: 24px;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .tpl-event--meta--col {
    column-gap: 12px;
  }
}
.tpl-event--meta--col svg {
  display: block;
  width: auto;
  height: 48px;
  fill: #1C1C1C;
}
@media screen and (max-width: 768px) {
  .tpl-event--meta--col svg {
    height: 24px;
  }
}
.tpl-event--meta--item {
  line-height: 1.4;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .tpl-event--meta--item {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .tpl-event--meta--item h3 {
    line-height: 1;
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .tpl-event--meta--btn {
    width: 100%;
    text-align: center;
  }
}
.tpl-event--footer--content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
}
@media screen and (max-width: 768px) {
  .tpl-event--footer--content {
    flex-direction: column;
    row-gap: 12px;
  }
}
@media screen and (max-width: 768px) {
  .tpl-event--footer--prev, .tpl-event--footer--next {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .tpl-event--footer--prev {
    padding-left: 24px !important;
    padding-right: 16px !important;
    mask-image: url("../images/triangle-mask-right.svg"), linear-gradient(#fff 0 0) !important;
    mask-position: calc(100% + 1px) !important;
  }
  .tpl-event--footer--prev .btn--icon {
    order: 2 !important;
    margin-right: 0 !important;
    margin-left: 9px !important;
  }
  .tpl-event--footer--prev .icon--chevron-bid {
    transform: scale(1) !important;
  }
}
.tpl-event--footer--next:only-child {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .tpl-post .page-hero--text h1,
  .tpl-post .page-heading--text h1 {
    font-size: 24px;
    line-height: 1.2;
  }
}
.tpl-post--footer--content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
}
@media screen and (max-width: 768px) {
  .tpl-post--footer--content {
    column-gap: 12px;
  }
}
@media screen and (max-width: 768px) {
  .tpl-post--footer--prev, .tpl-post--footer--next {
    width: calc(50% - 6px);
    min-width: 0 !important;
  }
}
.tpl-post--footer--next:only-child {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .tpl-project .page-hero--text h1,
  .tpl-project .page-heading--text h1 {
    font-size: 24px;
    line-height: 1.2;
  }
}

.footer {
  margin-top: 0;
}
.footer--top {
  padding: 48px 96px;
  background: #FFF;
}
@media screen and (max-width: 1220px) {
  .footer--top {
    margin-top: 0;
    padding: 48px;
  }
}
@media screen and (max-width: 768px) {
  .footer--top .g-grid {
    row-gap: 24px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .footer--top .g-col {
    width: 33.3333333333%;
  }
}
@media screen and (max-width: 768px) {
  .footer--top .g-col {
    align-items: center;
    text-align: center;
  }
}
@media (hover: hover) {
  .footer--top .footer--link:not(:disabled):hover {
    color: #822F35;
  }
}
.footer--top .footer--link:not(:disabled):focus {
  color: #822F35;
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .footer--col-4 {
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;
    width: 100% !important;
  }
}
.footer--bottom {
  padding: 18px 96px;
  color: #FFF;
  line-height: 1.4;
  font-size: 16px;
  background: #1C1C1C;
}
@media screen and (max-width: 1220px) and (min-width: 769px) {
  .footer--bottom {
    padding: 18px 48px;
  }
}
@media screen and (max-width: 768px) {
  .footer--bottom {
    padding: 24px;
  }
}
.footer--bottom .footer--content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 48px;
}
@media screen and (max-width: 768px) {
  .footer--bottom .footer--content {
    flex-direction: column;
    row-gap: 12px;
    line-height: 1.2;
    font-size: 14px;
    text-align: center;
  }
}
@media (hover: hover) {
  .footer--bottom .footer--link:not(:disabled):hover {
    color: #F2C359;
  }
}
.footer--bottom .footer--link:not(:disabled):focus {
  color: #F2C359;
}
.footer--link {
  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}
@media screen and (min-width: 769px) {
  .footer .logo svg {
    width: 100%;
    max-width: 240px;
    height: auto;
  }
}
.footer--logos-bid {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  row-gap: 12px;
  padding-top: 48px;
}
@media screen and (max-width: 768px) {
  .footer--logos-bid {
    flex-direction: row;
    justify-content: center;
    column-gap: 24px;
    order: -1;
    margin: -48px -24px 24px;
    padding: 16px 24px;
    width: calc(100% + 48px);
    background: #EFEDED;
  }
}
.footer--logos-bid a {
  display: block;
}
.footer--logos-bid picture {
  display: block;
  height: 38px;
}
@media screen and (max-width: 768px) {
  .footer--logos-bid picture {
    height: 32px;
  }
}
.footer--logos-bid picture img {
  width: auto;
  max-width: 100%;
  height: 38px;
}
@media screen and (max-width: 768px) {
  .footer--logos-bid picture img {
    height: 32px;
  }
}
.footer--contacts {
  font-weight: 500;
}
.footer--socials {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 12px;
}
.footer--logos {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  column-gap: 24px;
  padding-top: 24px;
}
@media screen and (min-width: 1025px) {
  .footer--logos {
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1 1 auto;
    margin-top: auto;
  }
}
.footer--logos a {
  display: block;
}
.footer--logos picture {
  display: block;
  height: 36px;
}
.footer--logos picture img {
  width: auto;
  height: 36px;
}

.header {
  transition-property: top;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  position: fixed;
  z-index: 99;
  top: 46px;
  left: 0;
  right: 0;
  background: #FFF;
}
@media screen and (min-width: 1221px) {
  .header {
    padding: 0 96px;
  }
}
@media screen and (max-width: 1220px) and (min-width: 1025px) {
  .header {
    padding: 0 48px;
  }
}
@media screen and (max-width: 1024px) {
  .header {
    top: 41px;
    padding: 0 24px;
  }
}
.header:after {
  position: absolute;
  z-index: -1;
  content: "";
  bottom: -6px;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 100%);
}
.header--content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 48px;
  padding: 24px 0;
}
@media screen and (max-width: 1024px) {
  .header--content {
    align-items: center;
    padding: 16px 0;
  }
}
.header--lhc {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 1025px) {
  .header .logo {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }
}
@media screen and (max-width: 1024px) {
  .header .logo svg {
    height: 48px;
  }
}

/*
 * Sticky Header Behaviour
 */
body.sticky-down .header {
  top: 0;
}

.wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 116px;
  min-height: calc(100vh - 116px);
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .wrapper {
    margin-top: 121px;
    min-height: calc(100vh - 121px);
  }
}
.wrapper > .main {
  flex: 1 1 auto;
}

/*
 * Main
 */
.main--arrows {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 48px;
  mask-image: url("../images/main-arrows.svg");
  mask-size: 48px;
}
@media screen and (max-width: 1220px) and (min-width: 1025px) {
  .main--arrows {
    width: 24px;
    mask-size: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .main--arrows {
    display: none;
  }
}

/*
 * WP Admin Bar
 */
@media screen and (max-width: 1024px) {
  html[lang=en] {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 1025px) {
  body.customize-support .nav-top {
    top: 32px;
  }
  body.customize-support .header {
    top: 78px;
  }
  body.customize-support.sticky-down .header {
    top: 32px;
  }
}

@media screen and (max-width: 1024px) {
  #wpadminbar {
    display: none !important;
  }
}

@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s infinite linear;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__play {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}