/*
 * Gallery Carousel
 */

.gallery-carousel {

    &--item {
        @include aspect-ratio-media(4, 3);
        display:block;
        width:100%;
        overflow:hidden;

        @include hocus {

            .gallery-carousel--img {
                transform:scale(1.1);
            }
        }
    }

    &--img {
        @include animate((transform));
    }

    // Style Light
    &.style-light {

        .splide__pagination__page {
            background:rgba(#FFF, .24);

            &.is-active {
                background:#FFF;
            }
        }

        .gallery-carousel-slider--arrow-prev,
        .gallery-carousel-slider--arrow-next {

            svg {
                fill:#FFF;
            }
        }
    }
}

/*
 * Slider
 */

.gallery-carousel-slider {
    @include relative;
    width:100%;

    .splide__track {
        overflow:visible;
    }

    .splide__slide {
        @include animate((opacity));

        &:not(.is-visible) {
            opacity:.24;
        }
    }

    .splide__pagination {
        @include flex-row;
        @include absolute;
        justify-content:center;
        align-items:center;
        column-gap:12px;
        left:50%; bottom:-54px;
        transform:translateX(-50%);

        @media screen and (max-width:1220px) {
            bottom:-30px;
        }

        &__page {
            @include animate((background));
            display:block;
            width:12px; height:12px;
            border-radius:100%;
            background:#FFF;

            &.is-active {
                background:map-get($colors, '01-01');
            }
        }
    }

    &--arrow-prev,
    &--arrow-next {
        @include absolute;
        top:50%;
        width:48px; height:48px;
        transform:translateY(-50%);

        @media screen and (max-width:768px) {
            width:24px; height:24px;
        }

        svg {
            display:block;
            width:48px; height:48px;
            fill:map-get($colors, '01-01');

            @media screen and (max-width:768px) {
                width:24px; height:24px;
            }
        }

        &:disabled {
            opacity:.24;
        }
    }

    &--arrow-prev {
        left:48px;

        @media screen and (max-width:1220px) {
            left:12px;
        }

        svg {
            transform:rotate(180deg);
        }
    }
    &--arrow-next {
        right:48px;

        @media screen and (max-width:1220px) {
            right:12px;
        }
    }
}

/*
 * Modal
 */

.gallery-modal,
.gallery-modal--splide {
    height:100%;

    .splide__track,
    .splide__list,
    .splide__slide {
        height:100%;
    }
}

.gallery-modal {

    &--img {
        @include full-size;
        height:100% !important;
        object-fit:contain;
    }

    &--arrow-prev,
    &--arrow-next {

        @media screen and (min-width:769px) {
            @include fixed;
            top:24px;
        }
    }
    &--arrow-prev {

        @media screen and (min-width:769px) {
            right:144px;
        }

        svg {
            transform:rotate(180deg);
        }
    }
    &--arrow-next {

        @media screen and (min-width:769px) {
            right:24px;
        }
    }
}