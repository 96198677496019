.pb-block--related-posts {

    .pb-block--intro {

        @media screen and (max-width:768px) {
            padding-bottom:24px;
        }
    }

    .pb-block--footer {
        justify-content:flex-end;

        @media screen and (max-width:768px) {
            justify-content:flex-start;
        }
    }
}
