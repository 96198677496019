@mixin select(
    $-padding: (
        'default': 9px 50px 9px 18px,
        'focus': 4px 50px 4px 18px,
    ),
    $-chevron: (
        'size': 16px,
        'position': 18px,
    ),
    $-font-size: map-get($font-sizes, 'default'),
    $-text-color: (
        'default': map-get($colors, '01-01'),
        'hover': map-get($colors, '01-01'),
        'disabled': rgba(map-get($colors, '01-01'), .24),
    ),
    $-fill-color: (
        'default': map-get($colors, '01-01'),
        'hover': map-get($colors, '01-01'),
        'checked': map-get($colors, '01-01'),
        'disabled': rgba(map-get($colors, '01-01'), .24),
    ),
    $-bg-color: (
        'default': #FFF,
        'hover': #FFF,
        'disabled': #FFF,
    ),
    $-border: true,
    $-border-size: 2px,
    $-border-color: (
        'default': darken(map-get($colors, '01-01'), 6%),
        'hover': map-get($colors, '01-01'),
        'disabled': rgba(map-get($colors, '01-01'), .48),
    ),
    $-border-radius: 0,
) {

    /*
     * Select
     */

    select {
        padding:map-get($-padding, 'default');
        color:map-get($-text-color, 'default');
        font-size:100%; font-size:$-font-size;
        background:map-get($-bg-color, 'default');

        @if $-border {
            border:$-border-size solid map-get($-border-color, 'default');
            border-radius:$-border-radius;
        }

        // Hocus
        @include hocus {
            background:map-get($-bg-color, 'hover');

            @if $-border {
                border-color:map-get($-border-color, 'hover');
            }
        }

        // Disabled
        &:disabled {
            color:map-get($-text-color, 'disabled');
            background:map-get($-bg-color, 'disabled');

            @if $-border {
                border-color:map-get($-border-color, 'disabled');
            }
        }
    }

    /*
     * Chevron
     */

    svg {
        right:map-get($-chevron, 'position');
        margin-top:calc((map-get($-chevron, 'size') / 2) * -1);
        width:map-get($-chevron, 'size'); height:map-get($-chevron, 'size');
        fill:map-get($-fill-color, 'default');
    }
}

.g-select {
    @include relative;

    /*
     * Select
     */

    select {
        @include animate((background, border));
        display:block;
        margin:0;
        width:100%; height:auto;
        line-height:map-get($line-heights, 'default');
        font-family:map-get($font-families, '01');
        box-shadow:0 0 0 0 transparent inset !important;
        box-sizing:border-box;
        appearance:none;
        cursor:pointer;

        // Disabled
        &:disabled {
            cursor:default;
        }
    }

    /*
     * Chevron
     */

    svg {
        @include animate((fill));
        @include absolute;
        display:block;
        top:50%;
        transform:rotate(90deg);
    }

    // Default style
    &.style-default {
        @include select;
    }

    // Light style
    &.style-light {
        @include select(
            $-text-color: (
                'default': #FFF,
                'hover': #FFF,
                'disabled': rgba(#FFF, .24),
            ),
            $-fill-color: (
                'default': #FFF,
                'hover': #FFF,
                'checked': #FFF,
                'disabled': rgba(#FFF, .24),
            ),
            $-bg-color: (
                'default': none,
                'hover': none,
                'disabled': none,
            ),
            $-border-color: (
                'default': rgba(#FFF, .12),
                'hover': rgba(#FFF, .24),
                'disabled': rgba(#FFF, .12),
            ),
        );
    }
}
