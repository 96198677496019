.newsletter {

    &--intro {
    }

    &--form-wrapper {
        padding-top:12px;

        @media screen and (max-width:768px) {
            padding-top:24px;
        }

        .g-grid {

            @media screen and (max-width:768px) {
                row-gap:6px;
            }
        }

        .g-col {

            @media screen and (max-width:1024px) and (min-width:769px) {
                width:calc((4 / 12) * 100%);
            }
        }

        .btn {
            text-align:left;

            @media screen and (min-width:769px) {
                width:100%;
            }
        }
    }

    &--col-4 {

        @media screen and (max-width:1024px) and (min-width:769px) {
            margin:12px auto 0;
        }
    }

    &--btn {
        @include relative;

        input {
            @include full-size;
            opacity:0;
        }
    }

    &--response {
        margin-top:12px; padding:12px 24px;
        color:#FFF;
        line-height:map-get($line-heights, 'medium');
        font-size:map-get($font-sizes, 'medium');
        text-align:center;

        @media screen and (max-width:768px) {
            line-height:map-get($line-heights, 'small');
            font-size:map-get($font-sizes, 'small');
        }

        &.is-success {
            background:map-get($colors, 'valid');
        }
        &.is-error {
            background:map-get($colors, 'error');
        }
    }

    &--outro {
        padding-top:24px;
        line-height:map-get($line-heights, 'medium');
        font-size:map-get($font-sizes, 'medium');

        @media screen and (min-width:769px) {
            text-align:center;
        }
    }
}
