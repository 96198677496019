.tpl-archive {
    margin-top:-48px;

    @media screen and (max-width:768px) {
        margin-top:-24px;
    }

    .card {
        margin-top:48px;

        @media screen and (max-width:768px) {
            margin-top:24px;
        }
    }

    &.archive-short {
        margin-top:-24px;

        @media screen and (max-width:768px) {
            margin-top:-12px;
        }

        .card {
            margin-top:24px;

            @media screen and (max-width:768px) {
                margin-top:12px;
            }
        }
    }

    // Heading
    &--heading {

        @media screen and (max-width:768px) {
            padding-bottom:24px !important;
        }
    }

    // Filters
    &--filters {

        &--content {
            @include flex-row;

            @media screen and (min-width:769px) {
                justify-content:space-between;
                align-items:flex-end;
                column-gap:48px;
            }
            @media screen and (max-width:768px) {
                flex-direction:column;
                align-items:flex-start;
                row-gap:12px;
            }
        }

        &--dropdown {
            min-width:260px; width:fit-content;
        }

        &--true-false {
            padding:12px 0 13px !important;
        }
    }
}
