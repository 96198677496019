/*
 * Pagination
 */

.nav-pagination {
    @include flex-col;
    align-items:center;
    padding:48px 96px 0 !important;

    &--list {
        @include flex-row;
        justify-content:center;
        align-items:center;
    }

    &--item {

        &.is-current {
            opacity:.48;
        }
    }

    &--prev {
        margin-right:24px;

        @media screen and (max-width:768px) {
            margin-right:12px;
        }
    }

    &--next {
        margin-left:24px;

        @media screen and (max-width:768px) {
            margin-left:12px;
        }
    }

    &--link {
        @include animate((color));
        color:map-get($colors, '01-01') !important;
        font-weight:700;

        &.btn--has-icon > .btn--icon.icon-flip {
            transform:scale(-1) translateY(2px);
        }

        &:not(.btn) {
            display:block;
            padding:6px 9px;
        }
    }
}

a.nav-pagination--link {

    @include hocus {
        color:map-get($colors, '02-07-p') !important;
    }
}