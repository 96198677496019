/*
 * Animate
 */

@mixin animate($attributes, $speed:300ms, $ease:ease-in-out) {
    transition-property:$attributes;
    transition-duration:$speed;
    transition-timing-function:$ease;
}

/*
 * Flex
 */

@mixin flex {
    display:flex;
    flex-wrap:nowrap;
}
@mixin flex-row {
    @include flex;
    flex-direction:row;
}
@mixin flex-col {
    @include flex;
    flex-direction:column;
}

/*
 * Position
 */

@mixin absolute($zindex:1) {
    position:absolute;
    z-index:$zindex;
}
@mixin fixed($zindex:1) {
    position:fixed;
    z-index:$zindex;
}
@mixin relative($zindex:1) {
    position:relative;
    z-index:$zindex;
}

/*
 * Hocus
 */

@mixin hocus {
    @media (hover:hover) {
        &:not(:disabled):hover {
            @content;
        }
    }

    &:not(:disabled):focus {
        @content;
    }
}

/*
 * Typography
 */

@mixin text-left {
    text-align:left;
}
@mixin text-right {
    text-align:right;
}
@mixin text-center {
    text-align:center;
}

@mixin uppercase {
    text-transform:uppercase;
}

// Fluid Text
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1:unit($min-vw);
	$u2:unit($max-vw);
	$u3:unit($min-font-size);
	$u4:unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size:$min-font-size;
			@media screen and (min-width:$min-vw) {
				font-size:calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width:$max-vw) {
				font-size:$max-font-size;
			}
		}
	}
}

/*
 * Full
 */

// Full size
@mixin full-size($zindex:1) {
    position:absolute;
    top:0; left:0; right:0; bottom:0;
    z-index:$zindex;
}

// Full width
@mixin full-width {
    display:block;
    width:100%; height:auto;
}

/*
 * Aspect ratio
 */

@mixin aspect-ratio($width, $height) {
    @include flex;
    flex-direction:row !important;

    &:before {
        content:"";
        display:block;
        padding-top:calc((#{$height} / #{$width}) * 100%);
    }
}

@mixin aspect-ratio-media($width, $height, $object-fit:cover) {
    @include relative;
    display:block;
    width:100%;

    &:before {
        content:"";
        display:block;
        padding-top:calc((#{$height} / #{$width}) * 100%);
        width:100%; height:0;
    }

    img,
    video,
    iframe {
        @include full-size;
        height:100%;
    }
    img {
        object-fit:$object-fit;
    }
    video {
        // width:100%; -- TODO
    }
    iframe {
        width:100%;
    }
}

/*
 * Buttons
 */

// Button Bare
@mixin btn-bare($color, $h_color) {
    @include animate((color));
    color:$color !important;

    .btn--icon {

        svg {
            @include animate((fill));
            fill:$color !important;
        }
    }

    @include hocus {
        color:$h_color !important;

        .btn--icon {

            svg {
                fill:$h_color !important;
            }
        }
    }
}

// Button 01
@mixin btn-01($bg, $h_bg, $color, $h_color) {
    @include animate((color));
    color:$color !important;

    &:before {
        @include animate((background));
        @include absolute(-1);
        content:"";
        top:0; left:0;
        width:100%; height:100%;
        background:$bg !important;
        transform:translateY(-1px);
    }

    .btn--icon {

        svg {
            @include animate((fill));
            fill:$color !important;
        }
    }

    @include hocus {
        color:$h_color !important;

        &:before {
            background:$h_bg !important;
        }

        .btn--icon {

            svg {
                fill:$h_color !important;
            }
        }
    }
}

// Button Circle
@mixin btn-circle($fill, $h_fill, $border, $h_border) {
    @include animate((border, opacity));
    border-color:$border !important;

    svg {
        @include animate((fill));
        fill:$fill !important;
    }

    @media (hover:hover) {
        &:not(.disabled):not(:disabled):hover,
        &:not(.disabled):not(:disabled):focus {
            border-color:$h_border !important;

            svg {
                fill:$h_fill !important;
            }
        }
    }
}
