/*
 * Document
 */

* {
    margin:0; padding:0;
    font-size:100%;
    vertical-align:baseline;
    outline:none;
}

html {
    text-size-adjust:100%;
    -webkit-font-smoothing:antialiased;
    background-color:map-get($colors, 'white');
}

body {
    margin:0; padding:0;
    line-height:map-get($line-heights, 'default');
    color:map-get($colors, '01-01');
    font-family:map-get($font-families, '01');
    font-size:map-get($font-sizes, 'default');
    font-weight:400;
    cursor:default;
    overflow-x:auto; overflow-y:scroll;

    @media screen and (max-width:768px) {
        line-height:map-get($line-heights, 'medium');
        font-size:map-get($font-sizes, 'medium');
    }
}

/*
 * Sections
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display:block;
}

audio, canvas, video {
    display:inline-block;
    *display:inline;
    *zoom:1;
}
audio:not([controls]) {
    display:none;
}

/*
 * Selections
 */

::selection {
    color:map-get($colors, '02-01');
    background-color:map-get($colors, '02-03');
}
::-moz-selection {
    color:map-get($colors, '02-01');
    background-color:map-get($colors, '02-03');
}

/*
 * Focus
 */

:focus {
    outline:none;
}

/*
 * Figure
 */

 figure {
    display:block;
    margin:0;

    figcaption {
        display:block;
    }
}

/*
 * Image
 */

img {
    width:auto\9; height:auto;
    vertical-align:middle;
    border:0;
    -ms-interpolation-mode:bicubic;
}

/*
 * SVG
 */

svg:not(:root) {
    overflow:hidden;
    pointer-events:none !important;
}
