.g-stats {

    &--item {
        @include relative;
        padding:48px 96px;

        @media screen and (max-width:1220px) and (min-width:769px) {
            padding:48px;
        }
        @media screen and (max-width:768px) {
            padding:24px;
        }

        &:nth-child(odd) {

            .g-stats--bg {

                @media screen and (min-width:769px) {
                    clip-path:polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%);
                }
            }
        }
        &:nth-child(even) {

            .g-stats--bg {

                @media screen and (min-width:769px) {
                    clip-path:polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%);
                }
            }
        }
    }

    &--content {
        @include flex-row;

        @media screen and (min-width:769px) {
            justify-content:space-between;
            align-items:center;
        }
        @media screen and (max-width:768px) {
            flex-direction:column;
            text-align:center;
        }
    }

    &--lhc,
    &--rhc {

        @media screen and (min-width:769px) {
            width:calc(50% - 96px);
        }
    }
    &--lhc {

        @media screen and (min-width:769px) {
            @include relative;
            padding-right:72px;
            box-sizing:border-box;
        }
        @media screen and (max-width:768px) {
            @include flex-col;
            align-items:center;
        }
    }
    &--rhc {

        @media screen and (max-width:768px) {
            @include relative;
            padding:24px 24px 0;
        }
    }

    &--heading {
        font-weight:700;
    }

    &--text {
        padding-top:6px;
    }

    &--chevron {
        width:48px; height:48px;

        @media screen and (min-width:769px) {
            @include absolute;
            top:50%; right:0;
            transform:translateY(-50%);
        }
        @media screen and (max-width:768px) {
            padding:12px 0 18px;
        }

        svg {
            display:block;
            width:48px; height:48px;

            @media screen and (max-width:768px) {
                transform:rotate(90deg);
            }
        }
    }

    &--val {
        @include fluid-type(312px, 1280px, 48px, 144px);
        line-height:1;
        color:#FFF;
        text-align:right;

        @media screen and (max-width:768px) {
            text-align:center;
        }
    }

    &--bg {
        @include absolute(-1);
        top:0; right:0; bottom:0; left:50%;

        @media screen and (max-width:768px) {
            left:-24px; right:-24px; bottom:-24px;
        }
    }
}