.card {
    @include relative;

    &--link {
        @include full-size(2);
    }

    &--thumb {
        @include relative;
    }

    &--pic {
        @include aspect-ratio-media(4, 3);
        overflow:hidden;
    }

    &--img {
        @include animate((transform));
    }

    &--status {
        @include absolute(2);
        top:12px; left:0;
        padding:6px 12px;
        line-height:1;
        color:#FFF;
        font-size:map-get($font-sizes, 'small');
        background:rgba(#000, .88);
        border-radius:0 3px 3px 0;
    }

    &--content {
        padding-top:18px;
        line-height:map-get($line-heights, 'small');

        @media screen and (max-width:768px) {
            padding-top:12px;
        }
    }

    &--date {
        padding-bottom:12px;
        font-size:map-get($font-sizes, 'medium');

        @media screen and (max-width:768px) {
            padding-bottom:6px;
            font-size:map-get($font-sizes, 'small');
        }
    }

    &--heading {
        line-height:map-get($line-heights, 'small');
    }

    &--excerpt {
        padding-top:12px;
        display:-webkit-box;
        -webkit-line-clamp:2;
                line-clamp:2;
        -webkit-box-orient:vertical;
        overflow:hidden;
    }

    &--view-more {
        padding-top:12px;
    }

    @include hocus {

        .card--img {
            transform:scale(1.1);
        }
    }
}

/*
 * Project
 */

.card-project {

    &--heading {
        padding-top:24px;
        text-align:center;

        @media screen and (max-width:768px) {
            padding-top:18px;
        }
    }
}

/*
 * Event (Full)
 */

.card-event-full {
    @include flex-row;
    justify-content:space-between;

    @media screen and (max-width:768px) {
        flex-direction:column-reverse;
    }

    &--content {
        flex:1 1 auto;
        align-self:center;
        padding:32px;
        max-width:map-get($widths, 'small');

        @media screen and (max-width:768px) {
            padding:24px;
        }
    }

    &--excerpt {
        padding-top:24px;

        @media screen and (max-width:768px) {
            padding-top:12px;
        }
    }

    &--thumb {
        @include flex-col;
        flex-shrink:0;

        @media screen and (min-width:769px) {
            width:25%; min-width:312px;
        }
    }

    &--pic {
        flex:1 1 auto;
    }
}