/*
 * Flash message
 */

.flash-messages {
    @include animate((opacity, visibility));
    @include fixed(999);
    left:12px; bottom:12px;

    @media screen and (max-width:768px) {
        right:12px;
    }

    &:has(.flash-message:not(.is-active)) {
        opacity:0;
        visibility:hidden;
    }

    // Polyfill :has
    &.polyfill-has {
        opacity:0;
        visibility:hidden;
    }
}

.flash-message {
    @include animate((opacity, visibility));
    @include flex-row;
    justify-content:space-between;

    &:not(.is-inline) {
        max-width:400px;
    }

    &:not(.is-active) {
        opacity:0;
        visibility:hidden;
    }

    &.is-success {
        background:map-get($colors, 'valid');
    }

    &.is-error {
        background:map-get($colors, 'error');
    }

    &--content {
        padding:24px 32px;
        line-height:map-get($line-heights, 'medium');
        color:#FFF;
        font-size:map-get($font-sizes, 'medium');
    }

    &--close {
        @include flex-col;
        justify-content:center;
        align-items:center;
        flex-shrink:0;
        width:48px;
        background:rgba(#000, .12) !important;

        svg {
            @include animate((opacity));
            display:block;
            width:14px; height:14px;
            fill:#FFF;
            opacity:.48;
        }

        @include hocus {

            svg {
                opacity:1;
            }
        }
    }
}
