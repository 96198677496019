.tpl-nss {

    .main {
        @include relative;
    }

    .main--arrows {
        position:absolute;
        mask:none !important;
        background-image:url('../images/main-nss-logos.svg');
        background-size:100%;
        background-repeat:repeat-y;
    }

    .pb-block--ph:has(.pb-block--bg.bg-03-01) h1,
    .pb-block--ph:has(.pb-block--bg.bg-03-03) h1 {
        color:map-get($colors, '03-02');
    }
    .pb-block--ph:has(.pb-block--bg.bg-03-02) h1 {
        color:map-get($colors, '03-01');
    }

    .testimonial--symbol-rhc {
        @include absolute(-1);
        mask-image:url('../images/nss-logo.svg');
        mask-size:122%;
        mask-position:center left;
        mask-repeat:no-repeat;
    }
}