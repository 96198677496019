.page-hero {
    @include aspect-ratio(1920, 320);
    justify-content:center;
    overflow:hidden;

    &--text {

        @media screen and (min-width:769px) {
            width:50%; max-width:map-get($widths, 'small');
        }

        &:not(.mobile-full) {

            @media screen and (max-width:768px) {
                width:65%;
            }
        }
    }

    &--thumb {
        @include aspect-ratio-media(16, 9);
        margin:-32px -24px 24px;
        width:calc(100% + 48px);
    }

    &--pic {
        @include absolute(2);
        top:0; left:50%; right:0; bottom:0;
        transform:skewX(45deg);
        transform-origin:top;
        overflow:hidden;

        @media screen and (max-width:768px) {
            left:60%;
            transform:skewX(-45deg);
            transform-origin:bottom;
        }
    }

    &--img {
        @include full-size(-1);
        width:100%; height:100% !important;
        object-fit:cover;
        transform:skewX(-45deg);
        transform-origin:top;

        @media screen and (max-width:768px) {
            transform:skewX(45deg);
            transform-origin:bottom;
        }
    }
}
