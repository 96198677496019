
.password-protected {

	&__form {
		.post-password-form {

			label {
				display: flex;
				flex-direction: column;
				margin-top: 20px;
				input {
					display: block;
					width: 100%;
					max-width: 400px;
					margin-top: 5px;
					height: 52px;
					padding: 0 20px;
					background: white;
					border: 2px solid #1C1C1C;
					font-size: 18px;
					color: #1C1C1C;
				}
			}
			input[type="submit"] {
				padding: 10px 20px;
				height: 52px;
				background: map-get($colors, '02-04-p');
				color: #1C1C1C;
				line-height: 1;
				width: 100%;
				max-width: 200px;
				margin-top: 20px;
				transition: 0.3s all;
				font-weight: 500;
				font-size: 18px;
				&:hover {
					background: map-get($colors, '02-04-a');
				}
			}
		}
	}
}
