@mixin typo(
    $-bullet-size: (
        'desktop': 7px,
        'mobile': 5px,
    ),
    $-spacings: (
        'default': (
            'h1': 1em,
            'h2': 1.1em,
            'h3': 1.2em,
            'h4': 1.3em,
            'h5': 1.4em,
            'body': 1.5em,
            'bullet': (
                'desktop': 10px,
                'mobile': 9px,
            ),
            'table': (
                'cellpadding': 9px 18px,
            ),
        ),
        'medium': (
            'h1': 1em,
            'h2': 1.1em,
            'h3': 1.2em,
            'h4': 1.3em,
            'h5': 1.4em,
            'body': 1em,
            'bullet': (
                'desktop': 10px,
                'mobile': 9px,
            ),
            'table': (
                'cellpadding': 9px 18px,
            ),
        ),
    ),
    $-colors: (
        'default': (
            'heading': map-get($colors, '01-01'),
            'body': map-get($colors, '01-01'),
            'anchor': (
                'default': map-get($colors, '01-01'),
                'hover': map-get($colors, '01-01'),
            ),
            'list': map-get($colors, '01-01'),
            'table': (
                'th': (
                    'color': map-get($colors, '01-01'),
                    'bg': map-get($colors, '01-03'),
                    'border': map-get($colors, '01-02'),
                ),
                'td': (
                    'color': map-get($colors, '01-01'),
                    'bg': none,
                    'border': map-get($colors, '01-02'),
                ),
                'odd': (
                    'color': map-get($colors, '01-01'),
                    'bg': none,
                    'border': map-get($colors, '01-02'),
                ),
            ),
        ),
        'light': (
            'heading': #FFF,
            'body': #FFF,
            'anchor': (
                'default': #FFF,
                'hover': #FFF,
            ),
            'list': #FFF,
            'table': (
                'th': (
                    'color': #FFF,
                    'bg': rgba(#FFF, .12),
                    'border': rgba(#FFF, .24),
                ),
                'td': (
                    'color': #FFF,
                    'bg': none,
                    'border': rgba(#FFF, .24),
                ),
                'odd': (
                    'color': #FFF,
                    'bg': rgba(#FFF, .06),
                    'border': rgba(#FFF, .24),
                ),
            ),
        ),
    ),
) {

    /*
     * Spacings
     */

    @each $key, $spacing in $-spacings {
        &.spacing-#{$key} {

            // Headings
            h1:not(:first-child) { padding-top:map-get($spacing, 'h1'); }
            h2:not(:first-child) { padding-top:map-get($spacing, 'h2'); }
            h3:not(:first-child) { padding-top:map-get($spacing, 'h3'); }
            h4:not(:first-child) { padding-top:map-get($spacing, 'h4'); }
            h5:not(:first-child) { padding-top:map-get($spacing, 'h5'); }

            // All the rest
            h6, p, ul, ol {

                &:not(:first-child) {
                    padding-top:map-get($spacing, 'body');
                }
            }

            // Unordered list bullet vertical position
            ul:not([class]) li:before {
                margin-top:map-get($spacing, 'bullet', 'desktop');
                width:map-get($-bullet-size, 'desktop'); height:map-get($-bullet-size, 'desktop');
            }

            // Button wrapper
            p:has(> .btn):not(:first-child) {
                padding-top:calc(map-get($spacing, 'body') * 2);
            }

            // Polyfill :has (button)
            p.polyfill-has--btn {
                padding-top:calc(map-get($spacing, 'body') * 2);
            }

            // Tables
            table {

                th,
                td {
                    padding:map-get($spacing, 'table', 'cellpadding');
                }

                ul:not([class]) li:before {
                    margin-top:map-get($spacing, 'bullet', 'mobile');
                    width:map-get($-bullet-size, 'mobile'); height:map-get($-bullet-size, 'mobile');
                }
            }

            // Mobile
            @media screen and (max-width:768px) {

                h1:not(:first-child) { padding-top:calc(map-get($spacing, 'h1') / 1.2); }
                h2:not(:first-child) { padding-top:calc(map-get($spacing, 'h2') / 1.2); }
                h3:not(:first-child) { padding-top:calc(map-get($spacing, 'h3') / 1.2); }
                h4:not(:first-child) { padding-top:calc(map-get($spacing, 'h4') / 1.2); }
                h5:not(:first-child) { padding-top:calc(map-get($spacing, 'h5') / 1.2); }

                h6, p, ul, ol {

                    &:not(:first-child) {
                        padding-top:calc(map-get($spacing, 'body') / 1.2);
                    }
                }

                ul:not([class]) li:before {
                    margin-top:map-get($spacing, 'bullet', 'mobile');
                    width:map-get($-bullet-size, 'mobile'); height:map-get($-bullet-size, 'mobile');
                }

                p:has(> .btn):not(:first-child) {
                    padding-top:calc((map-get($spacing, 'body') * 2) / 1.2);
                }

                // Polyfill :has (button)
                p.polyfill-has--btn {
                    padding-top:calc((map-get($spacing, 'body') * 2) / 1.2);
                }
            }
        }
    }

    /*
     * Colors
     */

    @each $key, $color in $-colors {
        &.color-#{$key} {
            color:map-get($color, 'body');

            // Heading
            h1, h2, h3, h4, h5, h6 {
                color:map-get($color, 'heading');
            }

            // Anchors
            a:not([class]) {
                color:map-get($color, 'anchor', 'default');

                @include hocus {
                    color:map-get($color, 'anchor', 'hover');
                }
            }

            // Lists
            ul:not([class]) li:before {
                background:map-get($color, 'list');
            }
            ol:not([class]) li:before {
                color:map-get($color, 'list');
            }

            // Table
            table {

                th {
                    color:map-get($color, 'table', 'th', 'color');
                    background:map-get($color, 'table', 'th', 'bg');
                    border:1px solid map-get($color, 'table', 'th', 'border');
                }
                td {
                    color:map-get($color, 'table', 'td', 'color');
                    background:map-get($color, 'table', 'td', 'bg');
                    border:1px solid map-get($color, 'table', 'td', 'border');
                }
                tr.odd td {
                    color:map-get($color, 'table', 'odd', 'color');
                    background:map-get($color, 'table', 'odd', 'bg');
                    border:1px solid map-get($color, 'table', 'odd', 'border');
                }
            }
        }
    }
}

.g-typo {

    // Anchors
    a:not(.btn):not(.link) {
        @include animate((color));
        text-decoration:underline;
    }

    // Unordered list
    ul:not([class]) {
        list-style:none;

        li {
            @include relative;
            padding-left:18px;

            &:not(:last-child) {
                padding-bottom:.5em;
            }

            &:before {
                @include absolute;
                content:'';
                top:0; left:0;
                border-radius:100%;
            }
        }
    }

    // Ordered list
    ol {
        list-style:none;
        counter-reset:ol-counter;

        li {
            @include flex-row;
            counter-increment:ol-counter;

            &:not(:last-child) {
                padding-bottom:.5em;
            }

            &:before {
                content:counter(ol-counter);
                flex-shrink:0;
                padding-right:24px;
                font-weight:500;
            }
        }
    }

    // Images
    p > img {
        max-width:100%;
    }

    p:has(> img) {
        @include flex-row;
        flex-wrap:wrap;
        column-gap:24px;
        row-gap:24px;

        &[style="text-align:center"] {
            justify-content:center;
        }
    }

    // Polyfill :has (image)
    p.polyfill-has--img {
        @include flex-row;
        flex-wrap:wrap;
        column-gap:24px;
        row-gap:24px;

        &[style="text-align:center"] {
            justify-content:center;
        }
    }

    // Button wrapper
    p:has(> .btn) {
        @include flex-row;

        @media screen and (min-width:769px) {
            column-gap:24px;
        }
        @media screen and (max-width:768px) {
            flex-wrap:wrap;
            row-gap:12px;
        }

        &[style="text-align:center"],
        &[style="text-align:center;"],
        &[style="text-align: center"],
        &[style="text-align: center;"] {
            justify-content:center;
        }
    }

    // Polyfill :has
    p.polyfill-has--btn {
        @include flex-row;

        @media screen and (min-width:769px) {
            column-gap:24px;
        }
        @media screen and (max-width:768px) {
            row-gap:12px;
        }

        &[style="text-align:center"],
        &[style="text-align:center;"],
        &[style="text-align: center"],
        &[style="text-align: center;"] {
            justify-content:center;
        }
    }

    // Tables
    table {
        margin:0; padding:0;
        line-height:map-get($line-heights, 'medium');
        font-size:map-get($font-sizes, 'medium');

        tr th:first-child[rowspan],
        tr td:first-child[rowspan] {
            background:none !important;
        }

        caption {
            display:none;
        }
    }

    // Default style
    @include typo;
}
