.tpl-project {

    .page-hero--text h1,
    .page-heading--text h1 {

        @media screen and (max-width:768px) {
            font-size:24px;
            line-height:map-get($line-heights, 'small');
        }
    }
}