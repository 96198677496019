.ays-survey-section-header {
    margin:0 !important; padding:0 !important; padding-bottom:48px !important;
    background:none !important;
    border:none !important; border-radius:0 !important;
    box-shadow:none !important;
}

.ays-survey-question {
    border:1px solid map-get($colors, '01-03') !important;
    border-radius:0 !important;
}

.ays-survey-section-button-container {
    box-shadow:none !important;

    &:last-child {
        margin-right:0 !important;
    }
}