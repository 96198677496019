.logo {
    display:block;

    svg {
        @include animate((fill));
        display:block;
        width:auto; height:64px;
        fill:map-get($colors, '01-01');
    }

    @include hocus {

        svg {
            fill:map-get($colors, '02-07-p');
        }
    }

    &--bg {
        @include absolute(-1);
        top:-24px; left:-50vw; right:-96px; bottom:-24px;
        transform:skewX(-45deg);
        pointer-events:none;

        @media screen and (max-width:1024px) {
            display:none;
        }

        &:before {
            @include absolute(2);
            content:"";
            top:0; left:0; right:0; bottom:0;
            background:#FFF;
        }
        &:after {
            @include absolute;
            content:"";
            top:70px; left:0; right:0; bottom:0;
            box-shadow:0 0 6px 0 rgba(#000, .24);
        }
    }
}
