/*
 * Top
 */

.nav-top {
    @include fixed(99);
    top:0; left:0; right:0;
    padding:12px 96px;
    line-height:map-get($line-heights, 'medium');
    font-size:map-get($font-sizes, 'medium');
    background:map-get($colors, '01-01');

    @media screen and (max-width:1220px) and (min-width:1025px) {
        padding:12px 48px;
    }
    @media screen and (max-width:1024px) {
        padding:12px 24px;
        line-height:map-get($line-heights, 'small');
        font-size:map-get($font-sizes, 'small');
    }

    &--list {
        @include flex-row;
        justify-content:flex-end;
        column-gap:24px;

        @media screen and (max-width:1024px) {
            column-gap:18px;
        }
    }

    &--link {
        @include animate((color));
        color:#FFF;

        @include hocus {
            color:map-get($colors, '02-04-p');
        }
    }
}

/*
 * Primary
 */

.nav-primary {
    @include flex-row;
    align-items:center;
    column-gap:32px;
    line-height:map-get($line-heights, 'small');

    @media screen and (max-width:1070px) and (min-width:1025px) {
        column-gap:24px;
    }
    @media screen and (max-width:1024px) {
        @include animate((top, opacity, visibility));
        @include flex-col;
        @include fixed(99);
        top:121px; left:0; right:0;
        padding:32px 24px;
        background:#FFF;

        &:before,
        &:after {
            @include absolute(-1);
            content:"";
            left:0; right:0;
            height:6px;
            background:linear-gradient(to bottom, rgba(#000, .12) 0%, rgba(#000, 0) 100%);
        }
        &:before {
            top:0;
        }
        &:after {
            bottom:-6px;
        }

        &:not(.is-open) {
            opacity:0;
            visibility:hidden;
        }
    }

    &--list {
        @include flex-row;
        column-gap:32px;

        @media screen and (max-width:1070px) and (min-width:1025px) {
            column-gap:24px;
        }
        @media screen and (max-width:1024px) {
            flex-direction:column;
            align-items:center;
            row-gap:18px;
            width:100%;
        }
    }

    &--item {
        @include flex-row;
        @include relative;
        align-items:center;
        flex-shrink:0;
        column-gap:9px;

        @media screen and (max-width:1024px) {
            flex-wrap:wrap;
            justify-content:center;
        }

        &.has-sublist {

            &:after {

                @media screen and (min-width:1025px) {
                    @include absolute;
                    content:"";
                    left:0; right:0; bottom:-24px;
                    height:24px;
                }
            }

            @include hocus {
                .nav-primary--sublist {
                    opacity:1;
                    visibility:visible;
                    pointer-events:auto;
                }

                .nav-primary--link:after {
                    opacity:1;
                }
            }
        }
    }

    &--link {
        @include animate((color));
        @include relative;
        color:map-get($colors, '01-01');
        font-weight:500;

        @media screen and (max-width:1024px) {
            text-align:center;
        }

        @include hocus {
            color:map-get($colors, '02-07-p');
        }

        &.is-current {
            color:map-get($colors, '02-07-p');

            @media screen and (max-width:1024px) {
                color:map-get($colors, '02-07-p');
            }
        }
    }

    &--chevron {
        @include relative;
        width:12px; height:12px;
        background:none;
        transform:translateY(-2px);

        @media screen and (max-width:1024px) {
            width:16px; height:16px;
        }

        &:before {
            @include animate((opacity));
            @include absolute;
            content:"";
            top:50%; left:50%;
            width:32px; height:32px;
            border-radius:100%;
            transform:translate(-50%, -50%);
        }

        svg {
            @include animate((fill));
            @include relative(2);
            display:block;
            width:12px; height:12px;
            transform:rotate(90deg);
            fill:map-get($colors, '01-01');

            @media screen and (max-width:1024px) {
                width:16px; height:16px;
            }
        }

        @include hocus {

            svg {
                fill:map-get($colors, '02-07-p');
            }
        }
    }

    &--sublist {

        @media screen and (min-width:1025px) {
            @include animate((opacity, visibility));
            @include absolute;
            top:46px; left:-32px; right:-32px;
            padding:32px;
            background:#FFF;
            box-shadow:0 3px 6px 0 rgba(#000, .12);
        }
        @media screen and (max-width:1024px) {
            @include flex-col;
            align-items:center;
            padding-top:12px;
            width:100%;
        }

        &:not(.is-open) {

            @media screen and (min-width:1025px) {
                opacity:0;
                visibility:hidden;
                pointer-events:none;
            }
            @media screen and (max-width:1024px) {
                display:none;
            }
        }
    }

    &--subitem {

        &:not(:first-child) {
            padding-top:12px;
        }
    }

    &--sublink {

        @media screen and (min-width:1025px) {
            @include animate((color));
            display:block;
            color:map-get($colors, '01-01');
            font-size:map-get($font-sizes, 'medium');
            font-style:italic;
            text-align:center;
            white-space:nowrap;

            @include hocus {
                color:map-get($colors, '02-07-p');
            }
        }
        @media screen and (max-width:1024px) {
            text-align:center;
        }

        &.is-current {
            color:map-get($colors, '02-07-p');

            @media screen and (max-width:1024px) {
                color:map-get($colors, '02-07-p');
            }
        }
    }

    // Trigger / Close
    &--trigger,
    &--close {

        @media screen and (min-width:1025px) {
            display:none;
        }
        @media screen and (max-width:1024px) {
            @include flex-col;
            justify-content:center;
            align-items:center;
            width:32px; height:32px;
            background:#FFF !important;

            svg {
                display:block;
                width:24px; height:24px;
                fill:map-get($colors, '01-01');
            }
        }
    }
    &--close {

        @media screen and (max-width:1024px) {
            @include animate((top));
            @include fixed;
            top:65px; right:24px;
        }
    }

    // CTAs
    &--ctas-wrapper {
        @include flex-row;
        column-gap:6px;

        @media screen and (min-width:1025px) {
            margin:-12px 0;
        }
        @media screen and (max-width:1024px) {
            margin-top:18px;
        }
    }
}

/*
 * Footer
 */

.nav-footer {

    &--item {

        &:not(:first-child) {
            padding-top:6px;
        }
    }

    &--link {
        @include animate((color));
        color:map-get($colors, '01-01');
        font-weight:500;

        @include hocus {
            color:map-get($colors, '02-07-p');
        }
    }
}

/*
 * Sticky Header Behaviour
 */

body.sticky-down {

    .nav-primary {

        @media screen and (max-width:1024px) {
            top:80px;
        }

        &--close {

            @media screen and (max-width:1024px) {
                top:24px;
            }
        }
    }
}