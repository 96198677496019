$-margin: (
    'default': 0,
    'medium': 0,
    'small': 0,
);

.footer {
    margin-top:map-get($-margin, 'default');

    &--top {
        padding:48px 96px;
        background:#FFF;

        @media screen and (max-width:1220px) {
            margin-top:map-get($-margin, 'medium');
            padding:48px;
        }

        .g-grid {

            @media screen and (max-width:768px) {
                row-gap:24px;
            }
        }

        .g-col {

            @media screen and (max-width:1024px) and (min-width:769px) {
                width:calc((4 / 12) * 100%);
            }
            @media screen and (max-width:768px) {
                align-items:center;
                text-align:center;
            }
        }

        .footer--link {

            @include hocus {
                color:map-get($colors, '02-07-p');
            }
        }
    }

    &--col-4 {

        @media screen and (max-width:1024px) and (min-width:769px) {
            flex-direction:row !important;
            justify-content:space-between;
            align-items:center;
            padding-top:48px;
            width:100% !important;
        }
    }

    &--bottom {
        padding:18px 96px;
        color:#FFF;
        line-height:map-get($line-heights, 'medium');
        font-size:map-get($font-sizes, 'medium');
        background:map-get($colors, '01-01');

        @media screen and (max-width:1220px) and (min-width:769px) {
            padding:18px 48px;
        }
        @media screen and (max-width:768px) {
            padding:24px;
        }

        .footer--content {
            @include flex-row;
            justify-content:space-between;
            column-gap:48px;

            @media screen and (max-width:768px) {
                flex-direction:column;
                row-gap:12px;
                line-height:map-get($line-heights, 'small');
                font-size:map-get($font-sizes, 'small');
                text-align:center;
            }
        }

        .footer--link {

            @include hocus {
                color:map-get($colors, '02-04-p');
            }
        }
    }

    &--link {
        @include animate((color));
    }

    // Logo
    .logo {

        svg {

            @media screen and (min-width:769px) {
                width:100%; max-width:240px; height:auto;
            }
        }
    }

    // Logos BID
    &--logos-bid {
        @include flex-col;
        row-gap:12px;
        padding-top:48px;

        @media screen and (max-width:768px) {
            flex-direction:row;
            justify-content:center;
            column-gap:24px;
            order:-1;
            margin:-48px -24px 24px; padding:16px 24px;
            width:calc(100% + 48px);
            background:map-get($colors, '01-03');
        }

        a {
            display:block;
        }

        picture {
            display:block;
            height:38px;

            @media screen and (max-width:768px) {
                height:32px;
            }

            img {
                width:auto; max-width:100%; height:38px;

                @media screen and (max-width:768px) {
                    height:32px;
                }
            }
        }
    }

    // Contacts
    &--contacts {
        font-weight:500;
    }

    // Socials
    &--socials {
        @include flex-row;
        justify-content:flex-end;
        column-gap:12px;
    }

    // Logos
    &--logos {
        @include flex-row;
        column-gap:24px;
        padding-top:24px;

        @media screen and (min-width:1025px) {
            justify-content:flex-end;
            align-items:flex-end;
            flex:1 1 auto;
            margin-top:auto;
        }

        a {
            display:block;
        }

        picture {
            display:block;
            height:36px;

            img {
                width:auto; height:36px;
            }
        }
    }
}
