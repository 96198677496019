$-gutters: (2, 12, 24, 48, 96);
$-widths: (5, 7, 12);

.g-grid {
    @include flex-row;
    flex-wrap:wrap;

    // Column
    .g-col {
        @include flex-col;
        width:100%;
        box-sizing:border-box;

        &.self-start {
            align-self:flex-start;
        }
    }

    // Widths
    @media screen and (min-width:769px) {
        @each $width in $-widths {
            @for $i from 1 through $width {
                .w-#{$i}-#{$width} {
                    width:calc((#{$i} / #{$width}) * 100%);
                }
            }
        }
    }

    // Gutters
    @each $gutter in $-gutters {
        &.gutter-#{$gutter} {
            margin-left:-#{calc($gutter / 2)}px;
            margin-right:-#{calc($gutter / 2)}px;

            & > .g-col {
                padding-left:#{calc($gutter / 2)}px;
                padding-right:#{calc($gutter / 2)}px;
            }

            @media screen and (max-width:1220px) and (min-width:769px) {

                @if $gutter > 48 {
                    margin-top:-48px;
                    margin-left:-24px;
                    margin-right:-24px;

                    & > .g-col {
                        padding-top:48px;
                        padding-left:24px;
                        padding-right:24px;
                    }
                }
            }
            @media screen and (max-width:768px) {

                @if $gutter > 48 {
                    margin-top:-48px;

                    & > .g-col {
                        padding-top:48px;
                    }
                }
            }
        }
    }

    // Reverse
    &.grid--reverse {
        flex-direction:row-reverse;

        @media screen and (max-width:768px) {
            flex-direction:column-reverse;
        }
    }

    // Alignments
    &.grid--valign-center {
        justify-content:center;
    }

    &.grid--valign-middle {
        align-items:center;
    }
    &.grid--valign-bottom {
        align-items:flex-end;
    }
}
