.two-cols-image {
    @include flex-row;
    @include relative;
    justify-content:space-between;

    &:after {
        @include absolute(2);
        content:"";
        top:0; left:calc(80% - 32px); bottom:0;
        width:64px;
        background:currentColor;
        transform:skewX(45deg);
        transform-origin:bottom;

        @media screen and (max-width:768px) {
            left:calc(80% - 16px);
            width:32px;
        }
    }

    &--lhc,
    &--rhc {
        @include flex-col;
        overflow:hidden;
    }

    &--lhc {
        @include relative(2);
        width:80%;
        transform:skewX(45deg);
        transform-origin:bottom;

        & > .two-cols-image--pic {
            transform:skewX(-45deg);
            transform-origin:bottom;
        }
    }

    &--rhc {
        @include absolute;
        top:0; left:40%; right:0; bottom:0;
    }

    &--pic {
        @include aspect-ratio-media(2, 1);
        flex:1 1 auto;
    }
}
