@mixin input-text(
    $-padding: (
        'default': 10px 24px,
        'focus': 4px 24px,
    ),
    $-font-size: map-get($font-sizes, 'default'),
    $-text-color: (
        'default': map-get($colors, '01-01'),
        'hover': map-get($colors, '01-01'),
        'disabled': rgba(map-get($colors, '01-01'), .24),
    ),
    $-bg-color: (
        'default': #FFF,
        'hover': #FFF,
        'disabled': #FFF,
    ),
    $-border: true,
    $-border-size: 2px,
    $-border-color: (
        'default': darken(map-get($colors, '01-01'), 6%),
        'hover': map-get($colors, '01-01'),
        'disabled': rgba(map-get($colors, '01-01'), .48),
    ),
    $-border-radius: 0,
) {

    /*
     * Input
     */

    input {
        padding:map-get($-padding, 'default');
        color:map-get($-text-color, 'default');
        font-size:100%; font-size:$-font-size;
        background:map-get($-bg-color, 'default');

        @if $-border {
            border:$-border-size solid map-get($-border-color, 'default');
            border-radius:$-border-radius;
        }

        // Hocus
        @include hocus {
            background:map-get($-bg-color, 'hover');

            @if $-border {
                border-color:map-get($-border-color, 'hover');
            }
        }

        // Disabled
        &:disabled {
            color:map-get($-text-color, 'disabled');
            background:map-get($-bg-color, 'disabled');

            @if $-border {
                border-color:map-get($-border-color, 'disabled');
            }
        }

        // In-field label
        &:not([value=""]) + label,
        &:focus + label {
            padding:map-get($-padding, 'focus');
        }
    }

    /*
     * Label
     */

    label {
        padding:map-get($-padding, 'default');
        color:map-get($-text-color, 'default');
        font-size:$-font-size;
    }
}

.g-input-text {
    @include relative;

    /*
     * Input
     */

    input {
        @include animate((background, border));
        display:block;
        margin:0;
        width:100%; height:auto;
        line-height:map-get($line-heights, 'default');
        font-family:map-get($font-families, '01');
        box-shadow:0 0 0 0 transparent inset !important;
        box-sizing:border-box;
        appearance:none;

        // Disabled
        &:disabled {
            cursor:default;
        }

        // In-field label
        &:not([value=""]) + label,
        &:focus + label {
            z-index:1;
            font-size:8px;
        }
    }

    /*
     * Label
     */

    label {
        @include animate((padding, color, font-size));
        @include full-size;
        line-height:map-get($line-heights, 'default');
        font-family:map-get($font-families, '01');
        box-sizing:border-box;
        cursor:pointer;
        pointer-events:none;
    }

    // Default style
    &.style-default {
        @include input-text;
    }

    // Light style
    &.style-light {
        @include input-text(
            $-text-color: (
                'default': map-get($colors, '01-01'),
                'hover': map-get($colors, '01-01'),
                'disabled': rgba(map-get($colors, '01-01'), .24),
            ),
            $-bg-color: (
                'default': #FFF,
                'hover': #FFF,
                'disabled': #FFF,
            ),
            $-border-color: (
                'default': #FFF,
                'hover': #FFF,
                'disabled': #FFF,
            ),
        );
    }
}
