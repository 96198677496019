.page-heading {

    &--text {
        width:50%; max-width:map-get($widths, 'small');

        @media screen and (max-width:768px) {
            width:65%;
        }
    }

    &--pic {
        @include absolute(2);
        top:0; left:50%; right:0; bottom:0;
        transform:skewX(-45deg);
        transform-origin:bottom;
        overflow:hidden;
    }

    &--img {
        @include full-size(-1);
        width:100%; height:100% !important;
        object-fit:cover;
        transform:skewX(45deg);
        transform-origin:bottom;
    }
}
