.grid-icons {
    margin-top:-48px;

    & > .g-grid > .g-col {

        @media screen and (max-width:768px) {
            width:50%;
        }
    }

    &--item {
        @include flex-col;
        @include relative;
        align-items:center;
        row-gap:24px;
        margin-top:48px;
        text-align:center;

        @media screen and (max-width:768px) {
            row-gap:18px;
            margin-top:32px;
        }

        @include hocus {

            .grid-icons--icon span:last-child {
                opacity:1;
            }
        }
    }

    &--link {
        @include full-size(2);
    }

    &--icon {
        @include relative;

        span {
            display:block;
            width:96px; height:96px;

            @media screen and (max-width:768px) {
                width:48px; height:48px;
            }

            &:last-child {
                @include animate((opacity));
                @include full-size;
                opacity:0;
            }

            svg {
                display:block;
                width:96px; height:96px;
                object-fit:contain;
                object-position:bottom;

                @media screen and (max-width:768px) {
                    width:48px; height:48px;
                }
            }
        }
    }

    &--text {
        line-height:map-get($line-heights, 'small');
        font-weight:500;
    }
}
