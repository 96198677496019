.testimonial {
    @include relative;
    padding:48px 120px;

    @media screen and (max-width:768px) {
        padding:68px 24px 32px;
    }

    &--text {
        @include relative;
    }

    &--meta {
        line-height:map-get($line-heights, 'small');
    }

    &--quote {
        @include absolute;
        width:48px; height:48px;

        @media screen and (max-width:768px) {
            width:32px; height:32px;
        }

        svg {
            display:block;
            width:48px; height:48px;

            @media screen and (max-width:768px) {
                width:32px; height:32px;
            }
        }

        &.pos-top {
            top:0; left:-72px;

            @media screen and (max-width:768px) {
                top:-44px; left:0;
            }
        }
        &.pos-bot {
            right:-72px; bottom:0;

            @media screen and (max-width:768px) {
                right:0; bottom:-32px;
            }

            svg {
                transform:scale(-1);
            }
        }
    }

    &--bg {
        @include full-size(-1);
        clip-path:polygon(60% 0%, 70% 0%, 100% 100%, 30% 100%);

        @media screen and (max-width:768px) {
            clip-path:polygon(0% 80%, 70% 15%, 100% 50%, 100% 100%, 0% 100%);
        }

        &.bg-03-01,
        &.bg-03-02 {
            background:#FFF !important;
            opacity:.12;
        }
        &.bg-03-03 {
            background:#000 !important;
            opacity:.12;
        }
    }

    &--symbol-rhc {
        @include absolute(-1);
        top:0; right:0; bottom:0;
        width:30%;
        mask-image:url('../images/main-arrows.svg');
        mask-size:20%;
        mask-position:center center;

        @media screen and (max-width:1024px) {
            width:60%;
            opacity:.12;
        }
    }
}