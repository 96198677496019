.tpl-event {

    .page-hero--text h1,
    .page-heading--text h1 {

        @media screen and (max-width:768px) {
            font-size:24px;
            line-height:map-get($line-heights, 'small');
        }
    }

    // Meta
    &--meta {

        .g-grid {

            @media screen and (max-width:768px) {
                row-gap:18px;
            }
        }

        &--col {
            @include flex-row;
            column-gap:24px;
            align-items:center;

            @media screen and (max-width:768px) {
                column-gap:12px;
            }

            svg {
                display:block;
                width:auto; height:48px;
                fill:map-get($colors, '01-01');

                @media screen and (max-width:768px) {
                    height:24px;
                }
            }
        }

        &--item {
            line-height:map-get($line-heights, 'medium');
            font-size:map-get($font-sizes, 'medium');

            @media screen and (max-width:768px) {
                font-size:map-get($font-sizes, 'small');
            }

            h3 {

                @media screen and (max-width:768px) {
                    line-height:1;
                    font-size:map-get($font-sizes, 'medium');
                }
            }
        }

        &--btn {

            @media screen and (max-width:768px) {
                width:100%;
                text-align:center;
            }
        }
    }

    // Footer
    &--footer {

        &--content {
            @include flex-row;
            justify-content:center;
            align-items:center;
            column-gap:24px;

            @media screen and (max-width:768px) {
                flex-direction:column;
                row-gap:12px;
            }
        }

        &--prev,
        &--next {

            @media screen and (max-width:768px) {
                width:100%;
            }
        }

        &--prev {

            @media screen and (max-width:768px) {
                padding-left:24px !important; padding-right:16px !important;
                mask-image:url('../images/triangle-mask-right.svg'), linear-gradient(#fff 0 0) !important;
                mask-position:calc(100% + 1px) !important;

                .btn--icon {
                    order:2 !important;
                    margin-right:0 !important; margin-left:9px !important;
                }

                .icon--chevron-bid {
                    transform:scale(1) !important;
                }
            }
        }

        &--next {

            &:only-child {
                margin-left:auto;
            }
        }
    }
}