.pb-block--table {

    @media screen and (min-width:769px) {
        padding-top:48px; padding-bottom:48px;
    }

    .g-typo {
        overflow-x:auto;
    }

    table {
        min-width:900px;
    }
}
