.pb-block--heading-text {

    .g-grid {

        .col-heading {

            @media screen and (max-width:768px) {
                margin-bottom:-24px;
            }

            svg {
                fill:currentColor;
                vertical-align:middle;
            }
        }

        &.grid--reverse {

            .col-heading {

                @media screen and (max-width:768px) {
                    order:1;
                }
            }
        }
    }

    &.v-large {

        .col-heading h2 {
            line-height:1;
            font-size:128px;

            svg {
                width:68px;
            }

            @media screen and (max-width:1024px) and (min-width:769px) {
                font-size:96px;

                svg {
                    width:52px;
                }
            }
            @media screen and (max-width:768px) {
                font-size:48px;

                br {
                    display:none;
                }

                svg {
                    width:26px;
                }
            }
        }

        .col-text {

            @media screen and (min-width:769px) {
                font-size:24px;
            }
        }
    }

    &:not(.v-large) {

        .col-heading h2 svg {
            width:28px;

            @media screen and (max-width:768px) {
                width:20px;
            }
        }
    }
}
