@mixin input-checkbox(
    $-size: 24px,
    $-font-size: map-get($font-sizes, 'default'),
    $-text-color: (
        'default': map-get($colors, '01-01'),
        'hover': map-get($colors, '01-01'),
        'checked': map-get($colors, '01-01'),
        'disabled': rgba(map-get($colors, '01-01'), .24),
    ),
    $-fill-color: (
        'default': map-get($colors, '01-01'),
        'hover': map-get($colors, '01-01'),
        'checked': map-get($colors, '01-01'),
        'disabled': rgba(map-get($colors, '01-01'), .24),
    ),
    $-bg-color: (
        'default': #FFF,
        'hover': #FFF,
        'checked': #FFF,
        'disabled': #FFF,
    ),
    $-border: true,
    $-border-size: 2px,
    $-border-color: (
        'default': darken(map-get($colors, '01-01'), 6%),
        'hover': map-get($colors, '01-01'),
        'checked': map-get($colors, '01-01'),
        'disabled': rgba(map-get($colors, '01-01'), .48),
    ),
    $-border-radius: 0,
) {

    /*
     * Input
     */

    input {
        margin:0; margin-top:calc(($-size / 2) * -1);
        width:$-size; height:$-size;

        // Checked
        &:checked:not(:disabled) + span {
            background:map-get($-bg-color, 'checked');

            @if $-border {
                border-color:map-get($-border-color, 'checked');
            }
        }

        // Disabled
        &:disabled {

            & + span {
                background:map-get($-bg-color, 'disabled');
            }

            & + span + label {
                color:map-get($-text-color, 'disabled');
            }
        }

        // Hocus
        @include hocus {
            & + span {
                background:map-get($-bg-color, 'hover');

                @if $-border {
                    border-color:map-get($-border-color, 'hover');
                }
            }

            & + span + label {
                color:map-get($-text-color, 'hover');
            }
        }
    }

    /*
     * Checkbox
     */

    span {
        width:$-size; height:$-size;
        background:map-get($-bg-color, 'default');

        @if $-border {
            border:$-border-size solid map-get($-border-color, 'default');
            border-radius:$-border-radius;
        }

        & > svg {
            width:calc($-size / 2); height:calc($-size / 2);
            fill:map-get($-fill-color, 'default');
        }
    }

    /*
     * Label
     */

    label {
        color:map-get($-text-color, 'default');
        font-size:$-font-size;
    }

    /*
     * Checkbox modifier
     */

    input[type=checkbox] {

        // Disabled
        &:disabled:checked + span > svg {
            fill:map-get($-fill-color, 'disabled');
        }

        // Hocus
        @include hocus {
            & + span > svg {
                fill:map-get($-fill-color, 'hover');
            }
        }
    }

    /*
     * Radio modifier
     */

    input[type=radio] {

        // Default
        & + span {

            &:before {
                width:calc($-size - 8px); height:calc($-size - 8px);
                background:map-get($-fill-color, 'default');
            }
        }

        // Disabled
        &:disabled:checked + span:before {
            background:map-get($-fill-color, 'disabled');
        }

        // Hocus
        @include hocus {
            & + span:before {
                background:map-get($-fill-color, 'hover');
            }
        }
    }
}

.g-input-checkbox {
    @include relative;
    @include flex-row;
    align-items:center;

    /*
     * Input
     */

    input {
        @include absolute;
        top:50%; left:0;
        padding:0;
        box-sizing:border-box;
        background:none;
        border:0 solid;
        border-radius:0;
        opacity:0;
        cursor:pointer;
        appearance:none;

        // Disabled
        &:disabled {
            cursor:default;

            & + span + label {
                cursor:default;
            }
        }
    }

    /*
     * Checkbox
     */

    span {
        @include animate((background, border));
        @include flex-col;
        justify-content:center;
        align-items:center;
        flex-shrink:0;
        box-sizing:border-box;

        & > svg {
            @include animate((opacity));
            display:block;
            opacity:0;
        }
    }

    /*
     * Label
     */

    label {
        @include animate((color));
        padding-left:12px;
        line-height:1.2;
        text-align:left;
        cursor:pointer;
    }

    /*
     * Checkbox modifier
     */

    input[type=checkbox] {

        // Checked
        &:checked:not(:disabled) + span > svg {
            opacity:1;
        }

        // Disabled
        &:disabled:checked + span > svg {
            opacity:1;
        }
    }

    /*
     * Radio modifier
     */

    input[type=radio] {

        // Default
        & + span {
            border-radius:100%;

            &:before {
                @include animate((opacity));
                content:"";
                display:block;
                flex-shrink:0;
                border-radius:100%;
                opacity:0;
            }
        }

        // Checked
        &:checked:not(:disabled) + span:before {
            opacity:1;
        }

        // Disabled
        &:disabled:checked + span:before {
            opacity:1;
        }
    }

    // Default style
    &.style-default {
        @include input-checkbox;
    }
}
