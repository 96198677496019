.event-feat {
    @include aspect-ratio(1920, 320);
    justify-content:center;
    overflow:hidden;

    &--text-wrapper {

        @media screen and (min-width:769px) {
            width:50%; max-width:map-get($widths, 'small');
        }
    }

    &--text {

        @media screen and (min-width:769px) {
            width:100%; max-width:map-get($widths, 'xsmall');
        }
    }

    &--btn {

        @media screen and (min-width:769px) {
            width:50%;
        }
    }

    &--thumb {
        @include aspect-ratio-media(4, 3);
    }

    &--pic {
        @include absolute;
        top:0; left:40%; right:0; bottom:0;
        transform:skewX(-45deg);
        transform-origin:bottom;
        overflow:hidden;

        &.reverse {
            left:50%;
            transform:skewX(45deg);
            transform-origin:top;

            & > .event-feat--img {
                transform:skewX(-45deg);
                transform-origin:top;
            }
        }
    }

    &--img {
        @include full-size(-1);
        width:100%; height:100% !important;
        object-fit:cover;
        transform:skewX(45deg);
        transform-origin:bottom;
    }
}
