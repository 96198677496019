.g-fastlinks {
    margin-top:-48px;

    @media screen and (max-width:768px) {
        margin-top:-24px;
    }

    &--item {
        @include relative;
        margin-top:48px;

        @media screen and (max-width:768px) {
            margin-top:24px;
        }

        &--link {
            @include full-size(2);
        }

        &--pic {
            @include aspect-ratio-media(4, 3);
            overflow:hidden;
        }

        &--img {
            @include animate((transform));
        }

        &--heading {
            @include flex-row;
            justify-content:center;
            align-items:center;
            column-gap:12px;
            padding-top:24px;
            text-align:center;

            @media screen and (max-width:768px) {
                padding-top:18px;
            }

            svg {
                @include animate((transform));
                display:block;
                width:32px; height:32px;
                transform:translateY(-2px);
            }
        }

        @include hocus {

            .g-fastlinks--item--img {
                transform:scale(1.1);
            }

            .g-fastlinks--item--heading svg {
                transform:translate(6px, -2px);
            }
        }
    }
}
