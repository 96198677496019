.hero {
    @include aspect-ratio(1920, 580);
    justify-content:center;
    z-index:2 !important;

    @media screen and (max-width:768px) {
        @include aspect-ratio(3, 4);
    }

    &--content {

        @media screen and (max-width:768px) {
            align-self:flex-end;
        }
    }

    h1 {
        font-size:64px;

        @media screen and (max-width:768px) {
            font-size:48px;
        }
    }

    &--chevron {
        @include absolute;
        right:96px; bottom:-48px;
        width:192px;

        svg {
            display:block;
            width:192px;
        }
    }

    &.has-overlay .pb-block--bg {

        &:before {
            @include full-size;
            content:"";
            background:#000;
            opacity:.64;
        }
    }
}
